import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';

import { Divider, ExpansionPanelActions } from '@material-ui/core';
import MuiSelect from '../MuiSelect';
import MuiTextField from '../MuiTextField';
import {
  onDeleteProject,
  onPatchProject,
  onPetitionCreateProject,
} from '../../../../redux/actions/petitionHandlerActions';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import MultipleSelect from '../MultipleSelect';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,.03)',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
}))(MuiExpansionPanelDetails);

class CustomizedExpansionPanel extends React.Component {
  constructor(props) {
    super(props);
    const { project } = props;
    this.state = {
      service: project.service ? project.service : 0,
      comment: project.comment || '',
      projectType: project.project_type ? project.project_type : null,
      products: project.products || [],
      saved: false,
      selectedProducts: project.products || [],
      expanded: 'panel1',
      deleteProjectOpen: false,
    };
    this.handleOpenDelete = this.handleOpenDelete.bind(this);
    this.handleCloseDelete = this.handleCloseDelete.bind(this);
  }

  onClickSaveProject = () => {
    const {
      project,
      onPetitionCreateProject,
      petition,
      onDelete,
      key,
    } = this.props;
    const { comment, service, projectType, selectedProducts } = this.state;
    onPetitionCreateProject({
      ...project,
      service: service,
      comment,
      project_type: projectType,
      products: selectedProducts,
      petition,
    });
    this.setState({
      saved: true,
    });
    onDelete(key);
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleServiceTypeSelect = event => {
    const id = event.target.value;
    this.setState({
      service: id,
      projectType: null,
    });
  };

  handleProjectTypeSelect = event => {
    const id = event.target.value;
    this.setState({
      projectType: id,
    });
  };

  handleTextFieldChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleProductsSelect = event => {
    this.setState({
      selectedProducts: event.target.value,
    });
  };

  onDelete = values => {
    const { project, onDeleteProject } = this.props;
    onDeleteProject({ id: project.id });
  };

  render() {
    const {
      expanded,
      comment,
      service,
      projectType,
      saved,
      selectedProducts,
    } = this.state;
    const {
      name,
      products: allProducts,
      services,
      editable,
      project,
      intl,
    } = this.props;
    console.log('service', service);
    if (saved) {
      return null;
    }
    return (
      <div>
        <ExpansionPanel
          square
          expanded={expanded === 'panel1'}
          onChange={this.handleChange('panel1')}
        >
          <ExpansionPanelSummary>
            <Typography>{name}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ overflowX: 'auto', width: '100%' }}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <MuiSelect
                  disabled={!editable}
                  label={intl.formatMessage({ id: 'select_service_type' })}
                  none={intl.formatMessage({ id: 'select_service_type' })}
                  data={services.map(service => ({
                    ...service,
                    disabled: false,
                  }))}
                  onChange={this.handleServiceTypeSelect}
                  value={service}
                  name={'service'}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <MuiSelect
                  disabled={!editable}
                  label={intl.formatMessage({ id: 'select_project_type' })}
                  none={intl.formatMessage({ id: 'select_project_type' })}
                  data={
                    service
                      ? services.find(s => s.id === service).project_types
                      : null
                  }
                  onChange={this.handleProjectTypeSelect}
                  name={'project'}
                  value={projectType}
                />
                <MuiTextField
                  disabled={!editable}
                  label={intl.formatMessage({ id: 'project_comment' })}
                  // width="400px"
                  value={comment}
                  name={'comment'}
                  handleChange={this.handleTextFieldChange}
                />
                <MultipleSelect
                  disabled={!editable}
                  data={allProducts}
                  label={intl.formatMessage({ id: 'petition_products' })}
                  selectedProducts={selectedProducts}
                  handleProductsSelect={this.handleProductsSelect}
                />
              </Grid>
            </Grid>
            {project && project.id && (
              <>
                <Dialog
                  open={this.state.deleteProjectOpen}
                  onClose={this.handleCloseDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <FormattedMessage id={'project_delete_title'} />
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <FormattedMessage id={'project_delete_desc'} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleCloseDelete} color="primary">
                      <FormattedMessage id={'disagree_btn'} />
                    </Button>
                    <Button
                      onClick={() => {
                        this.onDelete();
                        this.handleCloseDelete();
                      }}
                      color="primary"
                      autoFocus
                    >
                      <FormattedMessage id={'agree_btn'} />
                    </Button>
                  </DialogActions>
                </Dialog>
                <RemoveIcon
                  style={{
                    cursor: 'pointer',
                  }}
                  color="error"
                  onClick={this.handleOpenDelete}
                />
              </>
            )}
          </ExpansionPanelDetails>
          <Divider />
          <ExpansionPanelActions>
            {editable && (
              <Button
                disabled={
                  (service !== 5 &&
                    service !== 6 &&
                    (projectType < 1 || projectType === null)) ||
                  selectedProducts.length === 0
                }
                variant="contained"
                color="primary"
                onClick={this.onClickSaveProject}
              >
                <FormattedMessage id={'petition_save'} />
              </Button>
            )}
            {/*<button disabled={true} className="blue-save-btn" onClick={this.onClickSaveProject}>*/}
            {/*  Salvar*/}
            {/*</button>*/}
          </ExpansionPanelActions>
        </ExpansionPanel>
      </div>
    );
  }

  handleOpenDelete() {
    this.setState({ deleteProjectOpen: true });
  }

  handleCloseDelete() {
    this.setState({ deleteProjectOpen: false });
  }
}

CustomizedExpansionPanel.defaultProps = {};

export default connect(
  null,
  { onPetitionCreateProject, onDeleteProject },
)(injectIntl(CustomizedExpansionPanel));
