import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PageQuestion from '../../molecules/PageQuestion.js';

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: '1rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const QPage = props => {
  const { classes, page } = props;
  console.log('page', page);
  return (
    <div className={classes.root}>
      <ExpansionPanel expanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            <b>
              {page.id} {page.name}
            </b>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {Array.isArray(page.questions) &&
              page.questions.map(question => (
                <PageQuestion
                  key={`${question.name}${question.id}`}
                  question={question}
                />
              ))}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

QPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QPage);
