import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';

const styles = {
  root: {
    margin: '2rem 0',
  },
  summary: {
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: '20px',
    backgroundColor: '#f5f5f5'
  }
};

const ResumeProjectPanel = ({ head, children, classes }) => {
  return (
    <div className={classNames(classes.root)}>
      <ExpansionPanel square expanded={true}>
        <ExpansionPanelSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
          <Typography>{head}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

ResumeProjectPanel.propTypes = {};

export default withStyles(styles)(ResumeProjectPanel);
