import React, { Component } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import CategoryIcon from '@material-ui/icons/Category';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BallotIcon from '@material-ui/icons/Ballot';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import MessageIcon from '@material-ui/icons/Message';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArchiveIcon from '@material-ui/icons/Archive';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';

const data = [
  {
    url: '/',
    icon: <HomeIcon />,
    intl: 'navigation_home',
  },
  {
    icon: <CategoryIcon />,
    url: '/services',
    intl: 'navigation_services',
  },
  {
    url: '/petitions',
    intl: 'navigation_petitions',
    icon: <AssignmentIcon />,
  },
  {
    icon: <BallotIcon />,
    url: '/projects',
    intl: 'navigation_projects',
  },
  {
    url: '/products',
    icon: <ShoppingBasketIcon />,
    intl: 'navigation_products',
  },
  {
    url: '/invoices',
    icon: <EuroSymbolIcon />,
    intl: 'navigation_invoices',
    disabled: true,
  },
  {
    url: '/calendar',
    icon: <CalendarTodayIcon />,
    intl: 'navigation_calendar',
    disabled: true,
  },
  {
    url: '/reports',
    icon: <RssFeedIcon />,
    intl: 'navigation_reports',
    disabled: true,
  },
  {
    divider: true,
  },
  {
    url: '/messages',
    icon: <MessageIcon />,
    intl: 'navigation_messages',
  },
  {
    url: '/notifications',
    icon: <NotificationsIcon />,
    intl: 'navigation_notifications',
  },
  {
    url: '/archive',
    icon: <ArchiveIcon />,
    intl: 'navigation_archive',
    disabled: true,
  },
  {
    url: '/company',
    icon: <BusinessIcon />,
    intl: 'navigation_company_profile',
  },
  {
    divider: true,
  },
  {
    url: '/settings',
    icon: <SettingsIcon />,
    intl: 'navigation_settings',
    disabled: true,
  },
  {
    url: '/help',
    icon: <HelpIcon />,
    intl: 'navigation_help_center',
    disabled: true,
  },
];

export default data;
