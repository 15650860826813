import ax from 'axios';
import localforage from 'localforage';
import { put } from 'redux-saga/effects';
import { reset } from 'redux-form';
import _ from 'lodash';

// const lang = localStorage.getItem('lang');
const token = () => {
  localforage.getItem('auth').then(function(value) {
    console.log(`token: ${value.token}`);
    return value.token;
  });
};

export const axios = ax.create({
  baseURL: process.env.REACT_APP_API_URL,
  Accept: 'application/json',
  'Accept-Language': 'es',
});

export const getAxios = (token) => {
  return ax.create({
    baseURL: process.env.REACT_APP_API_URL,
    Accept: 'application/json',
    headers: { Authorization: token ? `Token ${token}` : undefined, 'Accept-Language': getLang() }
  })
};

export const getLang = () => {
  const isLang = localStorage.getItem('lang');
  if (typeof isLang === 'string') {
    return isLang;
  }
  return 'es';
};

export const resetForms = function*() {
  yield put(reset('login'));
  yield put(reset('sign_up'));
  yield put(reset('registration'));
};

export const setAuth = data => {
  const { token } = data;
  if (token !== undefined) {
    localforage.setItem('auth', data);
    ax.defaults.headers.common.Authorization = `Token ${token}`;
  } else {
    delete ax.defaults.headers.common.Authorization;
  }
};

export const prepareData = values => {
  const raw_services = _.pick(values, ['s1', 's2', 's3', 's4', 's5', 's6']); // values looks like s1 : true
  const services = [];
  const { email, password } = JSON.parse(localStorage.getItem('active_reg')); // get values from prev views
  const company_email = values.email;
  const {
    first_name,
    last_name,
    second_last_name,
    name,
    address,
    phone,
    code,
    url,
  } = values;
  // remove s from keys
  _.pickBy(raw_services, (value, key) => {
    const item = key.replace('s', '');
    services.push(+item);
  });

  return {
    email,
    password,
    first_name,
    last_name,
    second_last_name,
    account_type: 'cs_profile',
    cs_profile: {
      client: {
        status: 'pending',
        name,
        address,
        phone,
        email: company_email,
        code,
        logo: null,
        thumbnail: null,
        url,
        services,
      },
      account_type: 'admin',
    },
  };
};

export default {};
