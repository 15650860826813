import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Field, FieldArray, reduxForm } from 'redux-form';
import './styles.scss';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <input
        {...input}
        type={type}
        placeholder={label}
        className={
          error
            ? 'products-table-petition-input products-table-petition-input-error'
            : 'products-table-petition-input'
        }
      />
      {/*{touched && error && <span>{error}</span>}*/}
    </div>
  </div>
);

const renderCalendar = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <TextField
        id="date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={input.value}
        onChange={event => input.onChange(event.target.value)}
      />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

const renderText = ({ input: { value } }) => {
  return <div>{value}</div>;
};

class ProductsTablePetitionBody extends Component {
  constructor(props) {
    super(props);
    const { fields } = this.props;
    this.state = {
      edit: this.getNextEdit(fields),
    };
  }

  getNextEdit = fields => {
    let nextEdit = null;
    fields.forEach((field, index) => {
      if (fields.get(index).edit) {
        if (!nextEdit) {
          nextEdit = {};
        }
        nextEdit[index] = true;
      }
    });
    return nextEdit;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('fields', this.props.fields.length);
    if (this.props.fields.length !== prevProps.fields.length) {
      const { fields } = this.props;
      this.setState({
        edit: { ...this.getNextEdit(fields), ...this.state.edit },
      });
    }
  }

  render() {
    let {
      fields,
      meta: { error, submitFailed, invalid },
      nextProduct,
      meta,
    } = this.props;
    let { edit } = this.state;
    const switchMode = (index, value) => {
      this.setState(state => ({
        edit: {
          ...state.edit,
          [index]: value,
        },
      }));
      if (!value) {
        saveProduct(index);
      }
    };

    const saveProduct = index => {
      const { addProductToPetition, patchProduct } = this.props;
      const item = fields.get(index);
      item.id ? patchProduct(item) : addProductToPetition(item);
    };

    return (
      <>
        <TableBody>
          {fields.map((row, index) => {
            return (
              <TableRow key={`${fields.get(index).id}${index}`}>
                <TableCell component="th" scope="row">
                  {edit ? (
                    edit[index] ? (
                      <Fab
                        onClick={() => switchMode(index, false)}
                        disabled={
                          !fields.get(index).name ||
                          fields.get(index).name.length === 0 ||
                          !fields.get(index).name.replace(/\s/g, '').length
                        }
                        size="small"
                        color="primary"
                        aria-label="Save"
                      >
                        <SaveIcon />
                      </Fab>
                    ) : (
                      <Fab
                        onClick={() => switchMode(index, true)}
                        size="small"
                        color="secondary"
                        aria-label="Edit"
                      >
                        <EditIcon />
                      </Fab>
                    )
                  ) : (
                    <Fab
                      onClick={() => switchMode(index, true)}
                      size="small"
                      color="secondary"
                      aria-label="Edit"
                    >
                      <EditIcon />
                    </Fab>
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Field
                    name={`${row}.reference`}
                    type="text"
                    component={
                      edit
                        ? edit[index]
                          ? renderField
                          : renderText
                        : renderText
                    }
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Field
                    name={`${row}.name`}
                    type="text"
                    component={
                      edit
                        ? edit[index]
                          ? renderField
                          : renderText
                        : renderText
                    }
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Field
                    name={`${row}.product_type`}
                    type="text"
                    component={
                      edit
                        ? edit[index]
                          ? renderField
                          : renderText
                        : renderText
                    }
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Field
                    name={`${row}.lot`}
                    type="text"
                    component={
                      edit
                        ? edit[index]
                          ? renderField
                          : renderText
                        : renderText
                    }
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Field
                    name={`${row}.production_date`}
                    type="text"
                    component={
                      edit
                        ? edit[index]
                          ? renderCalendar
                          : renderText
                        : renderText
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </>
    );
  }
}

ProductsTablePetitionBody.propTypes = {};

export default ProductsTablePetitionBody;
