import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../organisms/DataTable/data-table';
import ProjectsTableBody from '../../molecules/ProjectsTableBody';
import ProjectsTableHead from '../../molecules/ProjectsTableHead';
import PageHeader from '../../../components/PageHeader';

const ProjectsTemplate = ({ projects }) => {
  return (
    <>
      <PageHeader title={'Proyectos'} />
      <DataTable
        head={<ProjectsTableHead />}
        body={<ProjectsTableBody data={projects.results} />}
        count={projects.count}
        name={'projects'}
      />
    </>
  );
};

ProjectsTemplate.propTypes = {};

export default ProjectsTemplate;
