import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatInput from '../../molecules/ChatInput';
import ChatMessages from '../../molecules/ChatMessages';
import { compose } from 'redux';
import {
  onAttachFileToChat,
  onSocketGetMessages,
  onSocketSendMessage,
} from '../../../redux/actions/socketActions';
import { connect } from 'react-redux';
import './styles.scss';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Loader from '../Loader';
import { submit } from 'redux-form';
import { FormattedMessage } from 'react-intl';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      fileError: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const { onSocketGetMessages, chatId } = this.props;
    onSocketGetMessages({ id: chatId });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.chatId !== prevProps.chatId) {
      this.props.onSocketGetMessages({ id: this.props.chatId });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSend = () => {
    const { onSocketSendMessage, chatId } = this.props;
    const { message } = this.state;
    if (message.length > 0 && message.replace(/\s/g, '').length > 0) {
      onSocketSendMessage({ chatId, data: { text: message } });
      this.setState({
        message: '',
      });
    }
  };

  attachFiles = e => {
    const files = e.target.files;
    let { chatId, onAttachFileToChat } = this.props;
    console.log('files', files);
    if (files.length > 0) {
      if (files[0].size > 104857600) {
        this.setState({
          fileError: (
            <div className={'chat-file-error'}>
              <FormattedMessage id={'file_size_is_to_large'} />
            </div>
          ),
        });
      } else {
        this.setState({
          fileError: null,
        });
        onAttachFileToChat({ file: files[0], id: chatId });
      }
    }
  };

  render() {
    let { messages, chatId, chats, inner } = this.props;
    let { message, fileError } = this.state;
    return (
      <Paper
        elevation={1}
        style={{ height: '70vh', display: 'flex', flexDirection: 'column' }}
      >
        <div className={'chat-root'}>
          {chats && chats[chatId] && (
            <ChatMessages messages={chats[chatId]} inner={inner} />
          )}
          {(!chats || !chats[chatId]) && <Loader />}
          <Divider />
          <ChatInput
            chat={chatId}
            message={message}
            handleSend={this.handleSend}
            handleInputChange={this.handleInputChange}
            attachFiles={this.attachFiles}
          />
          {fileError}
        </div>
      </Paper>
    );
  }
}

Chat.propTypes = {};

const mapStateToProps = state => ({
  chats: state.socket.chats,
});

const enhance = compose(
  connect(
    mapStateToProps,
    { onSocketSendMessage, onSocketGetMessages, onAttachFileToChat },
  ),
);

export default enhance(Chat);
