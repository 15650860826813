import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { injectIntl } from 'react-intl';

const renderTextFieldD = ({
  styles,
  placeholder,
  intl,
  input,
  label,
  meta: { touched, error, warning },
  children,
  ...custom
}) => {
  const place = intl.formatMessage({ id: placeholder });
  return (
    <FormControl fullWidth margin="dense">
      <TextField
        InputProps={{
          style: styles
        }}
        multiline={input.name === 'description'}
        placeholder={place}
        rows={5}
        label={label}
        error={touched && error}
        // helperText={touched && error && <FormHelperText error>{error}</FormHelperText>}
        {...input}
        {...custom}
      />
      {touched &&
        ((error && <FormHelperText error>{error}</FormHelperText>) ||
          (warning && <FormHelperText error>{error}</FormHelperText>))}
    </FormControl>
  );
};

export const renderTextField = injectIntl(renderTextFieldD);

export const renderCheckbox = ({
  style,
  input,
  label,
  meta: { touched, error },
  children,
  value,
  ...custom
}) => (
  <FormControl error={Boolean(touched && error)} fullWidth>
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          onChange={event => input.onChange(event.target.checked)}
          checked={input.value}
          {...input}
          {...custom}
          value={value}
        >
          {children}
        </Checkbox>
      }
    />
    {touched && error && <FormHelperText style={style}>{error}</FormHelperText>}
  </FormControl>
);

export default {};
