import React from 'react';
import { connect } from 'react-redux';
import ForgottenPassForm from './forms/ForgottenPassForm';
import {} from '../../../redux/actions/authActions';
import withAuthWrapper from '../common/withAuthWrapper';
import { recoverPassword } from '../../../redux/actions/authActions';

class ForgottenPassword extends React.PureComponent {
  handleSumbit = values => {
    let { recoverPassword } = this.props;
    console.log(values);
    recoverPassword(values);
  };

  render() {
    return (
      <section className="auth">
        <ForgottenPassForm onSubmit={this.handleSumbit} />
      </section>
    );
  }
}

export default withAuthWrapper(
  connect(
    null,
    { recoverPassword },
  )(ForgottenPassword),
);
