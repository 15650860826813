const styles = {
  infoBlockMargin: {
    margin: '2rem 0',
  },
  resumeHeader: {
    fontSize: '1.4rem',
    padding: '2rem 2rem 0 2rem',
  },
  resumeProjectHeader: {
    borderLeft: '5px solid #50E3C2',
    padding: '25px',
    fontSize: '1.6rem',
  },
  service: {
    textTransform: 'uppercase',
  },
  grayColor: {
    color: '#757575',
  },
  lang: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  doc: {
    margin: '10px',
    color: '#757575',
    fontSize: '.8rem'
  },
  tableOverflow: {
    overflowX: 'auto',
  },
  width: {
    width: '100%',
  },
  panelHeader: {
    fontWeight: "bold",
    textTransform: 'uppercase'
  },
  verticalFlex: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "64px"
  },
  volunteers: {
    fontWeight: "bold",
    margin: 0
  },
  volunteersNumber: {
    fontSize: "2rem",
    margin: 0
  },
  urgentStudy: {
    color: "red",
    marginLeft: "24px"
  },
  commentHeader: {
    display: "block",
    margin: "24px 0 12px 0"
  }
};

export default styles;
