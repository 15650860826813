import validator from 'validator';
import localization from '../../../../app/localization/valid_localization';

const validate = props => {
  const { username, email, password } = props;
  const lang = localStorage.getItem('lang');
  const errors = {};
  if (!username) errors.username = localization[lang].auth_is_username;
  else if (!validator.isLength(username, { min: 1, max: 128 }))
    errors.username = localization[lang].auth_username_length;

  if (!email) errors.email = localization[lang].auth_is_email;
  else if (!validator.isEmail(email))
    errors.email = localization[lang].auth_email;

  if (!password) errors.password = localization[lang].auth_is_password;
  else if (!validator.isLength(password, { min: 6, max: 30 }))
    errors.password = localization[lang].auth_password_length;
  return errors;
};

export default validate;
