import React from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from '@material-ui/core/Fade';
import support_ava from '../../../assets/images/svg/user-avatar-1.svg';
import { UniversalButton } from './Buttons';

const SupportBlock = () => (
  <Fade in timeout={2000}>
    <section className="auth-support">
      <div>
        <div className="auth-support__supporter">
          <div>
            <div className="auth-support__title">
              <FormattedMessage id="auth_support_title" />
            </div>
            <div className="auth-support__name">
              <FormattedMessage id="auth_support_name" />
            </div>
          </div>
          <div>
            <img src={support_ava} alt="Support" />
          </div>
        </div>
        <div className="auth-support__message">
          <FormattedMessage id="auth_support_message" />
        </div>

        <div className="auth-support__buttons">
          <UniversalButton
            id="auth_support_btn_faq"
            classname="auth-support__faq-btn"
          />
          <UniversalButton
            id="auth_support_btn_contact"
            classname="auth-support__contact-btn"
          />
        </div>
      </div>
    </section>
  </Fade>
);

export default SupportBlock;
