import React, { Component } from 'react';

class Addition extends Component {
  componentDidMount() {}

  render() {
    return <div>qwerryty</div>;
  }
}

export default Addition;

// class Addition extends Component{

//     render(){

//         const p = this.props;

//         return(
//             <div className='addition-block'
//                 style={{
//                     background: p.theme === 'blue' ? '#70BBFD' : '#fff',
//                     color: p.theme === 'blue' ? '#fff' : '#555555'
//                 }}
//             >
//                 <span>{p.title}</span>
//                 <p>{p.text}</p>
//                 <div className='addition-block-btns'>
//                     <button
//                         style={{
//                             background: p.theme === 'blue' ? '#fff' : '#F2F4F7',
//                             color: '#999999',
//                             borderColor: p.theme === 'blue' ? '#fff' : '#F2F4F7'
//                         }}
//                     >{p.btn1}</button>
//                     <button
//                         style={{
//                             background: p.theme === 'blue' ? 'transparent' : '#F6DA6E',
//                             color: '#fff',
//                             borderColor: p.theme === 'blue' ? '#fff' : '#F2F4F7'
//                         }}
//                     >{p.btn2}</button>
//                 </div>
//             </div>
//         )
//     }
// }

// export default Addition;
