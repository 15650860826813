import initialState from './initialState';
import injectReducer from './injectReducer';

import {
  PETITION_ONMOUNT_SUCCESS,
  PETITION_CREATE_SUCCESS,
  PETITION_ERROR,
  PETITION_PUSH_PROJECT,
  PETITION_SET_SERVICES,
} from '../types';

const handlers = {
  [PETITION_ONMOUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    petitions: payload,
  }),
  [PETITION_CREATE_SUCCESS]: (state, { payload }) => {
    console.log('petitions', state.petitions);
    return {
      ...state,
      // petitions: [payload],
    };
  },
  [PETITION_ERROR]: (state, { error, message }) => ({
    ...state,
    alert: {
      open: true,
      message,
      error,
    },
  }),
  [PETITION_SET_SERVICES]: (state, { payload }) => ({
    ...state,
    services: payload,
  }),
};

export default injectReducer(initialState.petitionReducer, handlers);
