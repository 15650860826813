import React from 'react';
import PropTypes from 'prop-types';
import MessagesTemplate from '../../templates/MessagesTemplate';
import withData from '../../HOC/WithData';
import Loader from '../../organisms/Loader';
import ProjectsTemplate from '../../templates/ProjectsTemplate';

const MessagesPage = props => {
  const { data } = props;

  return (
    <>
      {!data.chats && <Loader />}
      {data.chats && <MessagesTemplate chats={data.chats} />}
    </>
  );
};

MessagesPage.propTypes = {};

export default withData(MessagesPage, 'chats');
