import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import renderTextField from '../helpers/renderTextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const styles = {};

class ClaimsForm extends Component {
  constructor(props) {
    super(props);
    const { claims } = props;
    this.state = {
      length: claims ? claims.length : 0,
      nextClaims: 0,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.claims !== this.props.claims) {
      this.setState({
        length: this.props.claims.length,
      });
    }
  }

  render() {
    let { claims, change, form } = this.props;
    let { length } = this.state;
    return (
      <div>
        <div>
          <span>
            <FormattedMessage id={'claims_form'} />
          </span>
        </div>
        {claims.map((claim, index) => (
          <div>
            <Field name={`claims[${index}]`} component={renderTextField} />
            <RemoveIcon
              style={{ cursor: 'pointer' }}
              onClick={() =>
                change(form, `claims`, [
                  ...claims.slice(0, index),
                  ...claims.slice(index + 1),
                ])
              }
            />
          </div>
        ))}
        <AddIcon
          style={{ cursor: 'pointer' }}
          onClick={() => change(form, `claims[${length}]`, '')}
        />
      </div>
    );
  }
}

ClaimsForm.propTypes = {};

export default withStyles(styles)(ClaimsForm);
