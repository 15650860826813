import { all, take, put } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
// import { reset } from 'redux-form';
// import localforage from 'localforage';
// import { axios } from '../util';
import { DASHBOARD_ONMOUNT, DASHBOARD_ERROR } from '../types';
import errors from '../../app/localization/errors';

// ///////////
// actions //
// ///////////

export const onMountDashboard = values => ({
  type: DASHBOARD_ONMOUNT,
  payload: values,
});

// ///////////
// sagas ////
// ///////////

const onMountDashboardSaga = function*() {
  while (true) {
    try {
      // console.log('onMountDashboardSaga');
      // const action =
      yield take(DASHBOARD_ONMOUNT);
      // console.log('action=', action);
      // const data = yield prepareData(action.payload);
      // const result =
      // yield axios.post('petitions/');
      // console.log('result=', result.data);
      //   yield put({
      //     type: AUTH_REGISTER_SUCCESS,
      //     payload: result.data
      //   });
      //   yield setAuth(result.data);
      // yield put(reset('registration'));
      // yield put(push('/reg/final-registration'));
    } catch (error) {
      const lang = yield localStorage.getItem('lang');
      yield put({
        type: DASHBOARD_ERROR,
        message: errors[lang].onmount,
        error,
      });
    }
  }
};

// const loginSaga = function*() {
//   while (true) {
//     try {
//       const action = yield take(AUTH_LOGIN_REQUEST);
//       const result = yield axios.post('auth/login/', action.payload);
//       yield put({ type: AUTH_LOGIN_SUCCESS, payload: result.data });
//       yield localforage.setItem('auth', result.data);
//       yield put(reset('login'));
//       yield put(push('/'));
//     } catch (error) {
//       const lang = yield localStorage.getItem('lang');
//       yield put({
//         type: AUTH_GET_ERROR,
//         message: errors[lang].auth_login,
//         error
//       });
//       yield put(reset('login'));
//     }
//   }
// };

// const setCurrentUserSaga = function*() {
//   while (true) {
//     try {
//       yield take(AUTH_SET_CURRENT_USER_REQUEST);
//       const result = yield localforage.getItem('auth');
//       if (result !== null) {
//         yield put({ type: AUTH_LOGIN_SUCCESS, payload: result });
//         yield put(push('/'));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }
// };

// const logoutSaga = function*() {
//   while (true) {
//     try {
//       yield take(AUTH_LOGOUT_REQUEST);
//       yield localforage.removeItem('auth');
//       yield put({
//         type: AUTH_LOGOUT_SECCESS
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   }
// };

// const checkEmailSaga = function*() {
//   while (true) {
//     try {
//       const action = yield take(CHECK_EMAIL_REQUEST);
//       const { email, username, password } = action.payload;
//       yield axios.post('/auth/check_email/', { email });

//       yield put({
//         type: CHECK_EMAIL_SUCCESS,
//         payload: { email, username, password }
//       });
//       yield localStorage.setItem('active_reg', JSON.stringify(action.payload));
//       yield put(push('/reg/registration'));
//     } catch (error) {
//       const lang = yield localStorage.getItem('lang');
//       yield put({
//         type: AUTH_GET_ERROR,
//         message: errors[lang].auth_register,
//         error
//       });
//       yield put(reset('sign_up'));
//     }
//   }
// };

// const closeAuthAlertSaga = function*() {
//   while (true) {
//     try {
//       yield take(CLOSE_AUTH_ALERT_REQUEST);
//       yield put({ type: CLOSE_AUTH_ALERT_SUCCESS, payload: {} });
//     } catch (err) {
//       console.info(err);
//     }
//   }
// };

// const authGoToSaga = function*() {
//   while (true) {
//     try {
//       const action = yield take(AUTH_GO_TO);
//       yield put(push(action.payload));
//     } catch (error) {
//       yield put({ type: AUTH_GET_ERROR, error });
//     }
//   }
// };

export default function*() {
  yield all([onMountDashboardSaga()]);
}
