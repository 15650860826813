import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import ProfileCounter from '../../molecules/ProfileCounter';
import { FormattedMessage } from 'react-intl';

const Company = ({ client, counters }) => {
  return (
    <Paper>
      <div className={'profile-root'}>
        <div className={'profile-info-block'}>
          <Avatar className={'profile-avatar'}>
            {client.name && client.name[0]}
          </Avatar>
          <div>
            <b>{client.name && client.name.toUpperCase()} </b>
            <p>{client.email && client.email}</p>
            <p>{client.address && client.address}</p>
            <p>{client.phone && client.phone}</p>
          </div>
        </div>
        <Divider />
        <div className={'profile-counters'}>
          <ProfileCounter
            text={<FormattedMessage id={'home_projects_vc_finding'} />}
            count={counters.vc_finding}
          />
          <ProfileCounter
            text={<FormattedMessage id={'home_projects_in_progress'} />}
            count={counters.in_progress}
          />
          <ProfileCounter
            text={<FormattedMessage id={'home_projects_done'} />}
            count={counters.done}
          />
        </div>
      </div>
    </Paper>
  );
};

Company.propTypes = {};

export default Company;
