import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HomeTemplate from '../../templates/HomeTemplate';
import {
  clearDataError,
  getDataByName,
  getProjectCounters,
} from '../../../redux/actions/dataLoaderActions';
import { connect } from 'react-redux';
import Loader from '../../organisms/Loader';

class HomePage extends Component {
  componentDidMount() {
    const { getProjectCounters, getDataByName } = this.props;
    getProjectCounters();
    getDataByName({
      name: 'projects',
      filters:
        'progress=done&progress=vc_finding&progress=in_progress&progress=wait_vc_results',
    });
  }

  componentWillUnmount() {
    const { clearDataError } = this.props;
    clearDataError();
  }

  render() {
    const { counters, data } = this.props;
    return (
      <>
        {(!counters || !data.filteredprojects) && <Loader />}
        {counters && data.filteredprojects && (
          <HomeTemplate counters={counters} projects={data.filteredprojects} />
        )}
      </>
    );
  }
}

HomePage.propTypes = {};

const mapStateToProps = state => ({
  counters: state.dataLoader.projectCounters,
  data: state.dataLoader.data,
});

export default connect(
  mapStateToProps,
  { getProjectCounters, getDataByName, clearDataError },
)(HomePage);
