import React from 'react';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import LanguageSelect from './SelectLang';
import WelcomeBox from './WelcomeBox';
import Alert from '../../../components/Alert';

const AuthWrapper = ({ children }) => (
  <Fade in>
    <section className="auth-wrapper">
      <LanguageSelect />

      <Grid container style={{ flexGrow: 1 }} spacing={0}>
        <Grid item lg={8} md={7} xs={12}>
          <WelcomeBox />
        </Grid>

        <Grid item lg={4} md={5} xs={12}>
          <Grid item container justify="center">
            <div className="auth-wrapper__children">{children}</div>
          </Grid>
        </Grid>

        <Alert />
      </Grid>
    </section>
  </Fade>
);

export default AuthWrapper;
