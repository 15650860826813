import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Chat from '../../organisms/Chat';
import withResumeProjectPanel from '../../../containers/withResumeProjectPanel';
import { FormattedMessage } from 'react-intl';
import {
  ResumeDocuments,
  ResumeProducts,
  ResumeProjectProducts,
} from '../../../components/Resume';
import ResumeProjectType from '../../../components/Resume/ResumeProjectType';
import ResumeProjectPrice from '../../../components/Resume/ResumeProjectPrice';
import PageHeader from '../../../components/PageHeader';
import Table from '@material-ui/core/Table';
import ProjectInfoBlock from '../../organisms/ProjectInfoBlock';
import { connect } from 'react-redux';
import ResumeProjectHandler from '../../../containers/ResumeProjectHandler';

const ProjectTemplate = ({ project, services }) => {
  const projectType = project.project_type ? services
    .find(s => s.id === project.service)
    .project_types.find(p => p.id === project.project_type).name : "";
  const serviceName = services.find(s => s.id === project.service).name;
  return (
    <>
      <PageHeader title={`Proyecto / ${serviceName}`} />
      <Grid container>
        <Grid item xs={12} md={project.chat ? 8 : 12}>
          <ProjectInfoBlock project={project} projectType={projectType} />
          {/*{withResumeProjectPanel(*/}
          {/*  <FormattedMessage id={'petition_products'} />,*/}
          {/*)(<ResumeProducts products={project.products} />)}*/}
          {/*{withResumeProjectPanel(*/}
          {/*  <FormattedMessage id={'petition_documents'} />,*/}
          {/*)(*/}
          {/*  <ResumeDocuments*/}
          {/*    lang={project.language}*/}
          {/*    docs={project.attachments}*/}
          {/*  />,*/}
          {/*)}*/}
          {/*{withResumeProjectPanel(*/}
          {/*  <FormattedMessage id={'petition_project_type'} />,*/}
          {/*)(*/}
          {/*  <ResumeProjectType*/}
          {/*    projectType={projectType}*/}
          {/*    comment={project.comment}*/}
          {/*  />,*/}
          {/*)}*/}
          {/*{project.cost &&*/}
          {/*  withResumeProjectPanel(*/}
          {/*    <FormattedMessage id={'petition_project_price'} />,*/}
          {/*  )(<ResumeProjectPrice price={project.cost} />)}*/}
          <ResumeProjectHandler project={project}/>
        </Grid>
        {project.chat && (
          <Grid item xs={12} md={4}>
            <Chat chatId={project.chat} inner={true} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

ProjectTemplate.propTypes = {};

export default ProjectTemplate;
