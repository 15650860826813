import React from 'react';
import TextField from '@material-ui/core/TextField';

const renderNumericTextField = ({
  input,
  label,
  meta: { touched, error },
  width,
  ...custom
}) => <TextField style={{ width: width }} {...input} {...custom} type={"number"} min={0} />;
export default renderNumericTextField;
