import { all, take, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
// import { reset } from 'redux-form';
// import localforage from 'localforage';
// import axios from 'axios';
import { getAxios } from '../util';
import {
  PETITION_ONMOUNT,
  PETITION_ONMOUNT_SUCCESS,
  PETITION_CREATE,
  PETITION_CREATE_SUCCESS,
  PETITION_ERROR,
  PETITION_ADD_PRODUCT,
  PETITION_PUSH_PRODUCT,
  PETITION_CREATE_PROJECT,
  PETITION_PUSH_PROJECT,
  PETITION_GET_SERVICES,
  PETITION_SET_SERVICES,
  PETITION_DELETE,
  PETITION_CLEAR, PETITION_HANDLER_RESET,
} from '../types';
import errors from '../../app/localization/errors';
import localforage from 'localforage';
import { getProgressFromNumber } from '../../helpers/progress';

// ///////////
// actions //
// ///////////

export const onMountPetition = values => ({
  type: PETITION_ONMOUNT,
  payload: values,
});

export const onCreatePetition = values => ({
  type: PETITION_CREATE,
  payload: values,
});

export const onDeletePetition = values => ({
  type: PETITION_DELETE,
  payload: values,
});

// ///////////
// sagas ////
// ///////////
const lang = localStorage.getItem('lang');

// TODO move logger to separated file
function* watchAndLog() {
  while (true) {
    const action = yield take('*');
    const state = yield select();

    console.log('action', action);
    console.log('state after', state);
  }
}

const onMountPetitionSaga = function*() {
  while (true) {
    try {
      const action = yield take(PETITION_ONMOUNT);
      const page = action.payload.page + 1;
      const rowsPerPage = action.payload.rowsPerPage;
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const result = yield ax({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}petitions/?page=${page}&page_size=${rowsPerPage}`,
        // headers,
      });
      yield put({
        type: PETITION_ONMOUNT_SUCCESS,
        payload: result.data,
      });
    } catch (error) {
      yield put({
        type: PETITION_ERROR,
        message: errors[lang].onmount,
        error,
      });
    }
  }
};

// Get list of services from API
const onPetitionGetServicesSaga = function*() {
  while (true) {
    try {
      yield take(PETITION_GET_SERVICES);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const response = yield ax.get(
        `${process.env.REACT_APP_API_URL}services/`,
      );
      const services = response.data.results;
      yield put({
        type: PETITION_SET_SERVICES,
        payload: services,
      });
    } catch (error) {
      // TODO catch errors
    }
  }
};

const onCreatePetitionSaga = function*() {
  while (true) {
    try {
      yield take(PETITION_CREATE);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const result = yield ax({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}petitions/`,
        data: {
          progress: getProgressFromNumber(1),
        },
        // headers,
      });
      yield put({
        type: PETITION_CREATE_SUCCESS,
        payload: result.data,
      });
      yield put({
        type: PETITION_GET_SERVICES,
      });
      yield put(push(`/petition/${result.data.id}`));
    } catch (error) {
      yield put({
        type: PETITION_ERROR,
        message: errors[lang].petition_create,
        error,
      });
      yield put(push('/petition'));
    }
  }
};

const onDeletePetitionSaga = function*() {
  while(true) {
    try {
      const action = yield take(PETITION_DELETE);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { status } = yield ax.delete(`${process.env.REACT_APP_API_URL}petitions/${action.payload.id}/`);
      if (status === 204) {
        yield put({
          type: PETITION_HANDLER_RESET
        });
        yield put(push('/petitions'));
      }
    } catch (e) {

    }
  }
};

export default function*() {
  yield all([
    onMountPetitionSaga(),
    onCreatePetitionSaga(),
    watchAndLog(),
    onPetitionGetServicesSaga(),
    onDeletePetitionSaga(),
  ]);
}
