import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getNumberFromProgress } from '../../../helpers/progress';
import Arrow from '@material-ui/icons/KeyboardArrowRight';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import '../../helpers/see-petition.scss';
import { withStyles } from '@material-ui/core';
import { tableCell } from '../../helpers/styles';

const ProductsTableBody = ({ data, intl, classes }) => {
  return (
    <TableBody>
      {data.map(row => (
        <TableRow key={row.id}>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {row.id}
          </TableCell>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {row.name}
          </TableCell>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {row.reference}
          </TableCell>
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {row.petitions &&
              row.petitions.length > 0 &&
              row.petitions.map((petition, index) => (
                <Link
                  key={`petitionkey${index}`}
                  className={'see-petition-btn'}
                  to={`/petition/${petition}`}
                >
                  {intl.formatMessage({ id: 'show_petition' })} {petition}
                </Link>
              ))}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

ProductsTableBody.propTypes = {};

export default injectIntl(withStyles({ tableCell })(ProductsTableBody));
