import React from 'react';
import { connect } from 'react-redux';
import RegistrationForm from './forms/RegistrationForm';
import { register } from '../../../redux/actions/authActions';
import withRegWrapper from "../common/withRegWrapper";

class Registration extends React.PureComponent {
  handleSumbit = values => {
    const { register } = this.props;
    register(values);
  };

  render() {
    return (
      <section className="reg">
        <RegistrationForm onSubmit={this.handleSumbit} initialValues={{
          email: JSON.parse(localStorage.getItem('active_reg')).email,
        }} />
      </section>
    );
  }
}

export default withRegWrapper(
  connect(
    null,
    { register },
  )(Registration),
);
