import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { FormattedMessage } from 'react-intl';

const ProjectInfoRow = ({ text, value }) => {
  return (
    <div className={'project-info-block-row'}>
      <div className={'project-info-block-title'}>{text}</div>
      <div className={'project-info-block-value'}>{value}</div>
    </div>
  );
};

ProjectInfoRow.propTypes = {
  text: PropTypes.node,
  value: PropTypes.node,
};

export default ProjectInfoRow;
