import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import renderTextField from '../helpers/renderTextField';

const styles = {};

const ObservationsForm = props => {
  return (
    <div>
      <div>
        <span>
          <FormattedMessage id={'petition_observation_specification'} />
        </span>
      </div>
      <div>
        <Field name="refinements" component={renderTextField} />
      </div>
    </div>
  );
};

ObservationsForm.propTypes = {};

export default withStyles(styles)(ObservationsForm);
