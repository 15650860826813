import React from 'react';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Flag from 'react-world-flags';
import {
  setLanguage,
  getCurrentLanguage
} from '../../../redux/actions/localeActions';

class LanguageSelect extends React.PureComponent {
  state = { lang: 'es', open: false };

  componentWillMount() {
    const { getCurrentLanguage } = this.props;
    const isLang = localStorage.getItem('lang');
    if (typeof isLang === 'string') {
      getCurrentLanguage(isLang);
      this.setState({ lang: isLang });
    } else {
      const { lang } = this.state;
      getCurrentLanguage(lang);
    }
  }

  handleChange = event => {
    const { setLanguage } = this.props;
    this.setState({ [event.target.name]: event.target.value });
    setLanguage(event.target.value);
  };

  handleClose = () => this.setState({ open: false });

  handleOpen = () => this.setState({ open: true });

  render() {
    const { open, lang } = this.state;
    return (
      <form autoComplete="off">
        <FormControl>
          <Select
            className="language-select__select"
            displayEmpty={false}
            open={open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={lang}
            onChange={this.handleChange}
            inputProps={{
              name: 'lang',
              id: 'lang'
            }}
          >
            <MenuItem className="language-select__select-item" value="en">
              <IconButton>
                <Flag
                  className="language-select__select-flag"
                  code="USA"
                  height={16}
                />
                <span className="language-select__select-lang">EN</span>
              </IconButton>
            </MenuItem>
            <MenuItem className="language-select__select-item" value="es">
              <IconButton>
                <Flag
                  className="language-select__select-flag"
                  code="ES"
                  height={16}
                />
                <span className="language-select__select-lang">ES</span>
              </IconButton>
            </MenuItem>
          </Select>
        </FormControl>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.lang
});

export default connect(
  mapStateToProps,
  { setLanguage, getCurrentLanguage }
)(LanguageSelect);
