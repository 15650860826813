import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withProjectEditorBlock } from '../helpers';
import Header from '../components/Header';
import { DocumentationForm, ProductsForm } from '../forms';
import { reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import { attachFilesToProject } from '../../../redux/actions/petitionHandlerActions';
import { injectIntl } from 'react-intl';
import SamplesForm from '../forms/SamplesForm';
import InformationForm from '../forms/InformationForm';
import fileErrorView from './fileError';

const OtherServices = props => {
  const {
    handleSubmit,
    intl,
    submitProject,
    project,
    attachFilesToProject,
    docs,
    products,
  } = props;

  useEffect(() => {
    const { handleSubmit } = props;
    return () => {
      handleSubmit();
    };
  }, []);

  const [fileError, setFileError] = useState(null);

  const attachFiles = e => {
    const files = e.target.files;
    if (files.length > 0) {
      if (files[0].size > 104857600) {
        setFileError(fileErrorView);
      } else {
        setFileError(null);
        submit('OtherServices');
        attachFilesToProject({ file: files[0], project });
      }
    }
  };

  const productsView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'petition_products' })} />,
  )(<ProductsForm products={project.products} allProducts={products} />);

  const samplesView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'samples_form' })} />,
  )(<SamplesForm />);

  const informationView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'form_information' })} />,
  )(<InformationForm />);

  const documentationView = withProjectEditorBlock(
    <Header
      head={intl.formatMessage({ id: 'petition_documents' })}
      description={intl.formatMessage({
        id: 'petition_documents_descr',
      })}
    />,
  )(<DocumentationForm attachFiles={attachFiles} docs={docs} project={project} fileError={fileError} />);

  return (
    <form onSubmit={handleSubmit(submitProject)}>
      {productsView}
      {samplesView}
      {informationView}
      {documentationView}
    </form>
  );
};

OtherServices.propTypes = {};

export default reduxForm({
  form: 'OtherServices',
  enableReinitialize: true,
})(
  connect(
    null,
    { attachFilesToProject, submit },
  )(injectIntl(OtherServices)),
);
