import React from 'react';
import { connect } from 'react-redux';
import SignUpForm from './forms/SignUpForm';
import { checkEmail } from '../../../redux/actions/authActions';
import withAuthWrapper from '../common/withAuthWrapper';

class SignUp extends React.PureComponent {
  handleSumbit = values => {
    const { checkEmail } = this.props;
    checkEmail(values);
  };

  render() {
    return (
      <section className="auth">
        <SignUpForm onSubmit={this.handleSumbit} />
      </section>
    );
  }
}

export default withAuthWrapper(connect(
  null,
  { checkEmail },
)(SignUp));
