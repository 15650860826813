import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { renderTextField } from '../../../../components/form/renders';
import { BlueBigButton } from '../../common/Buttons';
import { styles } from './input_styles';
import validate from './validate';

class SignUpForm extends React.PureComponent {
  state = { show: false, save: false };

  handleShowPass = () => this.setState(prev => ({ show: !prev.show }));

  handleSavePass = () => this.setState(prev => ({ save: !prev.save }));

  render() {
    const { handleSubmit, lang } = this.props;
    const { show, save } = this.state;
    return (
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="auth-form__title-wrapper">
          <div className="auth-form__decor" />
          <div className="auth-form__parts">
            <div className="auth-form__litle-parts">
              <div className="auth-form__first-part">
                <FormattedMessage id="auth_first_part" />
              </div>

              <div className="auth-form__second-part">
                <FormattedMessage id="auth_second_part" />
              </div>
            </div>
            <div className="auth-form__sub-part">
              <FormattedMessage id="auth_sub_part_signup" />
            </div>
          </div>
        </div>

        <div className="auth-form__wrapper">
          {/*<div className="auth-form__user">*/}
          {/*  <FormattedMessage id="auth_user" />*/}
          {/*</div>*/}
          {/*<div className="auth-form__user-placeholder">*/}
          {/*  <Field*/}
          {/*    name="username"*/}
          {/*    component={renderTextField}*/}
          {/*    placeholder="auth_user_placeholer"*/}
          {/*    autoComplete={save ? 'on' : 'off'}*/}
          {/*    className="auth-form__placeholder"*/}
          {/*    styles={styles}*/}
          {/*    lang={lang}*/}
          {/*  />*/}
          {/*</div>*/}
          <div className="auth-form__user">
            <FormattedMessage id="auth_email" />
          </div>
          <div className="auth-form__user-placeholder">
            <Field
              name="email"
              component={renderTextField}
              placeholder="auth_email_placeholer"
              autoComplete={save ? 'on' : 'off'}
              className="auth-form__placeholder"
              styles={styles}
              lang={lang}
            />
          </div>
          <div className="auth-form__user">
            <FormattedMessage id="auth_pass" />
          </div>
          <div className="auth-form__pass-placeholder">
            <Field
              name="password"
              component={renderTextField}
              placeholder="auth_pass_placeholder"
              type={show ? 'text' : 'password'}
              autoComplete={save ? 'on' : 'off'}
              styles={styles}
              lang={lang}
            />
            {show ? (
              <Visibility onClick={this.handleShowPass} />
            ) : (
              <VisibilityOff onClick={this.handleShowPass} />
            )}
          </div>

          <div className="auth-form__register-btn-wrapper">
            <div className="auth-form__register-signup-btn">
              <BlueBigButton id="auth_sign_up" type="submit" />
            </div>
            <Link
              className="auth-form__have-account-questions"
              to="/auth/login"
              style={{ textDecoration: 'none' }}
            >
              <div className="auth-form__register-inner-wrapper">
                <div className="auth-form__have-account-question">
                  <FormattedMessage id="auth_have_account_question" />
                </div>
                <div className="auth-form__have-account-sub">
                  <FormattedMessage id="auth_have_account_sub" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.locale.lang
});

SignUpForm = reduxForm({
  form: 'sign_up',
  validate
})(SignUpForm);

SignUpForm = connect(mapStateToProps)(SignUpForm);

export default SignUpForm;
