import React, { Component } from 'react';
import BlueButton from '../../components/BlueButton';
import img from '../../assets/images/icons/document.png';

class ServicesPageItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const p = this.props;

    return (
      <div className="services-page-item">
        <img src={p.servPageIcon} alt="" />
        <span>{p.title}</span>
        <p className={'service-desc'}>{p.desc}</p>
        <BlueButton
          active
          styles={{
            width: '110px',
            height: '30px',
            margin: '30px',
            flex: '0 0 auto'
          }}
        >
          {p.btnText}
        </BlueButton>
      </div>
    );
  }
}

export default ServicesPageItem;
