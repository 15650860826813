import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableFooter from '../../molecules/TableFooter';
import { getDataByName } from '../../../redux/actions/dataLoaderActions';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import { FormattedMessage, injectIntl } from 'react-intl';

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      page: 0,
    };
  }
  handleChangePage = (event, page) => {
    const { getDataByName, name, count } = this.props;
    const { rowsPerPage } = this.state;
    if (rowsPerPage * page > count) {
      return;
    }
    getDataByName({ name, page, rowsPerPage });
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    const { getDataByName, name, count } = this.props;
    const { page } = this.state;
    if (page * event.target.value > count) {
      this.setState({ rowsPerPage: event.target.value, page: 0 });
      getDataByName({ name, page: 0, rowsPerPage: event.target.value });
    } else {
      this.setState({ rowsPerPage: event.target.value });
      getDataByName({ name, page, rowsPerPage: event.target.value });
    }
  };
  render() {
    const { head, body, count, name, intl } = this.props;
    const { rowsPerPage, page } = this.state;
    return (
      <Paper style={{ overflowX: 'auto', width: '100%' }}>
        <Table style={{ overflow: 'visible' }}>
          {head}
          {body}
          <tfoot>
            <tr>
              <TablePagination
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                labelRowsPerPage={intl.formatMessage({ id: 'rows_per_page' })}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${intl.formatMessage({
                    id: 'items_in_table_of',
                  })} ${count}`
                }
              />
            </tr>
          </tfoot>
        </Table>
      </Paper>
    );
  }
}

DataTable.propTypes = {};

export default connect(
  null,
  { getDataByName },
)(injectIntl(DataTable));
