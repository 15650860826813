import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

const PageQuestion = ({ question }) => {
  return (
    <div>
      <p>
        <b>{question.name}</b> [{question.type}]
      </p>
      <ul>
        {Array.isArray(question.answers) &&
          question.answers.map((answer, index) => (
            <li key={`${answer}${index}`}>{answer}</li>
          ))}
      </ul>
    </div>
  );
};

PageQuestion.propTypes = {};

export default PageQuestion;
