import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/Error';

const Error = ({ error }) => {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ height: '60vh' }}
    >
      <Grid item>
        <ErrorIcon />
        {error}
      </Grid>
    </Grid>
  );
};

Error.propTypes = {};

export default Error;
