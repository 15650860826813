import initialState from './initialState';
import injectReducer from './injectReducer';

import {
  AUTH_REGISTER_SUCCESS,
  AUTH_LOGIN_SUCCESS,
  // AUTH_SET_CURRENT_USER_REQUEST,
  // AUTH_SET_CURRENT_USER_SUCCESS,
  AUTH_GET_ERROR,
  CLOSE_AUTH_ALERT_SUCCESS,
  CHECK_EMAIL_SUCCESS,
  AUTH_LOGOUT_SECCESS,
  AUTH_NO_DATA,
  SET_RECOVERED_EMAIL,
} from '../types';

const handlers = {
  [AUTH_REGISTER_SUCCESS]: (state, { payload }) => ({
    ...state,
    auth: true,
    auth_data: payload,
  }),
  [AUTH_LOGIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    auth: true,
    auth_data: payload,
  }),
  [AUTH_LOGOUT_SECCESS]: state => ({
    ...initialState.authReducer,
    auth: false,
  }),
  [AUTH_NO_DATA]: state => ({
    ...state,
    auth: false,
  }),
  [CHECK_EMAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    active_stage: payload,
  }),
  [AUTH_GET_ERROR]: (state, { error, message }) => ({
    ...state,
    alert: {
      open: true,
      message,
      error,
    },
  }),
  [CLOSE_AUTH_ALERT_SUCCESS]: state => ({
    ...state,
    alert: {
      open: false,
      message: '',
    },
  }),
  [SET_RECOVERED_EMAIL]: (state, { payload }) => ({
    ...state,
    recoveredEmail: payload.email,
  }),
};

export default injectReducer(initialState.authReducer, handlers);
