import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import './styles.scss';
import CardHeader from '@material-ui/core/CardHeader';
import ProjectInfoRow from '../../molecules/ProjectInfoRow';

const ProjectInfoBlock = ({ project, projectType }) => {
  return (
    <Grid
      style={{ width: 'auto' }}
      container
      className={'project-info-block-root'}
    >
      <Grid item xs={12}>
        <CardHeader title={`TIPO DE PROYECTO: ${projectType}`} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProjectInfoRow
          text={<FormattedMessage id={'project_id'} />}
          value={project.id}
        />
        <ProjectInfoRow
          text={<FormattedMessage id={'petition_project_type'} />}
          value={projectType}
        />
        <ProjectInfoRow
          text={<FormattedMessage id={'project_state'} />}
          value={project.progress}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProjectInfoRow
          text={<FormattedMessage id={'petition_report_language'} />}
          value={project.language}
        />
        <ProjectInfoRow
          text={<FormattedMessage id={'project_start_date'} />}
          value={project.start_date}
        />
        <ProjectInfoRow
          text={<FormattedMessage id={'project_end_date'} />}
          value={project.end_date}
        />
        <ProjectInfoRow
          text={<FormattedMessage id={'project_report_submission_date'} />}
          value={project.report_date}
        />
      </Grid>
    </Grid>
  );
};

ProjectInfoBlock.propTypes = {};

export default ProjectInfoBlock;
