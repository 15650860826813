import React from 'react';
import PropTypes from 'prop-types';
import FileIcon from '@material-ui/icons/AttachFile';
import Fab from '@material-ui/core/Fab';

const ChatAttachFile = ({ attachFiles }) => {
  return (
    <>
      <label style={{ cursor: 'pointer' }} htmlFor="chatFiles">
        <FileIcon />
      </label>
      <input type="file" hidden id={'chatFiles'} onChange={attachFiles} />
    </>
  );
};

ChatAttachFile.propTypes = {};

export default ChatAttachFile;
