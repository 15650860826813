export const styles = {
  paddingLeft: '10px',
  paddingRight: '10px',
  color: '#999999',
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Roboto',
  background: '#f2f4f7'
};

export default {};
