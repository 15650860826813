import React, {Component} from 'react';
import './styles.scss';


class PageHeader extends Component{

    render(){

        const p = this.props;

        return(

            <div className={'section-header'}>
                {/*<PageHeader*/}
                    {/*title={'Nueva Petición'}*/}
                {/*>*/}
                    {/*Use this elements, if you want to show some hints or additional information, use*/}
                    {/*<span className={'red-mark'}> .default-tabs</span> class*/}
                {/*</PageHeader>*/}
                <div className={'left-side'}>
                    <h5 className={'page-title'}>{p.title}</h5>
                    <p className={'page-desc'}>
                        {p.desc}
                    </p>
                </div>
                {/*<div className={'right-side'}>*/}
                    {/*<p>asfdkiasodas</p>*/}
                {/*</div>*/}
                <div className={'right-side'}>
                    {p.children}

                </div>
            </div>

        )
    }
}

export default PageHeader;