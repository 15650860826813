import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import './styles.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AttachmentIcon from '@material-ui/icons/Attachment';

const ChatMessageInner = ({ message, whoami, intl }) => {
  const id = whoami ? whoami.id : -1;
  const { formatDate, formatTime } = intl;
  return (
    <>
      <Divider />
      <div className={'chat-message-inner-root'}>
        <div className={'chat-message-inner-created'}>
          <span>{formatDate(message.created)}</span>
          <span>{formatTime(message.created)}</span>
        </div>
        {id === message.sender ? (
          <div className={'chat-message-inner-user chat-message-inner-client'}>
            <span>CLIENT</span>
          </div>
        ) : (
          <div className={'chat-message-inner-user chat-message-inner-admin'}>
            ADMINS.
          </div>
        )}
        <div className={'chat-message-inner-text'}>
          {message.message_type === 'text' && <span>{message.text}</span>}
          {message.message_type === 'attachments' && (
            <span>
              {message.attachments.map(attachment => (
                <span key={attachment.id}>
                  <AttachmentIcon />
                  <a href={`${process.env.REACT_APP_API_URL}${attachment.url}`}>
                    {attachment.name}
                  </a>
                </span>
              ))}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

ChatMessageInner.propTypes = {};

const mapStateToProps = state => ({
  whoami: state.dataLoader.whoami,
});

export default connect(
  mapStateToProps,
  null,
)(injectIntl(ChatMessageInner));
