import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

const ResumeProjectProducts = ({
  products,
  comment,
  allProducts,
  classes,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} md={9}>
        {Array.isArray(products) && products.map((product, index) => (
          <div key={`${product.id}${index}`}>
            {product.name}
          </div>
        ))}
      </Grid>
      <Grid item xs={12} md={3}>
        <FormattedMessage id={'petition_observation'} />
        <div className={classes.grayColor}>{comment}</div>
      </Grid>
    </Grid>
  );
};

ResumeProjectProducts.propTypes = {};

const mapStateToProps = state => ({
  // allProducts: state.petitionHandler.petition.products,
});

export default connect(mapStateToProps)(
  withStyles(styles)(ResumeProjectProducts),
);
