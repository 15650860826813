import React from 'react';
import { connect } from 'react-redux';
import LoginForm from './forms/LoginForm';
import { login } from '../../../redux/actions/authActions';
import withAuthWrapper from '../common/withAuthWrapper';

class Login extends React.PureComponent {
  handleSumbit = values => {
    const { login } = this.props;
    login(values);
  };

  render() {
    return (
      <section className="auth">
        <LoginForm onSubmit={this.handleSumbit} />
      </section>
    );
  }
}

export default withAuthWrapper(
  connect(
    null,
    { login },
  )(Login),
);
