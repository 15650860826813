import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  root: {
    // backgroundColor: 'red',
  },
  header: {
    padding: '2rem 0 2rem 2rem',
    borderLeft: '5px solid #50E3C2',
  },
  head: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: '0',
  },
  description: {
    color: 'grey',
    margin: '.2rem 0',
    fontSize: '.9rem',
  },
};

const Header = ({ head, description, classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3 className={classes.head}>{head}</h3>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
};

Header.propTypes = {
  head: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default withStyles(styles)(Header);
