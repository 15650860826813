import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from "@material-ui/core/Grid";
import './styles.scss';

const ResumeProjectPrice = ({ price }) => {
  return (
      <Grid container>
          <Grid item md={9} xs={12}>
              <div><FormattedMessage id={'petition_project_amount'} /></div>
              <div className={'project-price'}>{price} €</div>
          </Grid>
      </Grid>
  );
};

ResumeProjectPrice.propTypes = {};

export default ResumeProjectPrice;
