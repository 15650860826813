import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import TableBody from '@material-ui/core/TableBody';
import { FormattedMessage, injectIntl } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import './styles.scss';
import '../../helpers/see-petition.scss';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { tableCell } from '../../helpers/styles';

const ProjectsTableBody = ({ data, intl, services, classes }) => {
  const getStudyChip = value => {
    switch (value) {
      case 'pending':
        return (
          <Chip
            style={{
              backgroundColor: '#ffcc80',
              width: '100%',
              color: 'white',
            }}
            label={<FormattedMessage id={'pending'} />}
          />
        );
      case 'in_progress':
        return (
          <Chip
            style={{
              backgroundColor: '#64b5f6',
              width: '100%',
              color: 'white',
            }}
            label={<FormattedMessage id={'in_progress'} />}
          />
        );
      case 'done':
        return (
          <Chip
            style={{
              backgroundColor: '#81c784',
              width: '100%',
              color: 'white',
            }}
            label={<FormattedMessage id={'done'} />}
          />
        );
      case 'vc_finding':
        return (
          <Chip
            style={{
              backgroundColor: '#64b5f6',
              width: '100%',
              color: 'white',
            }}
            label={<FormattedMessage id={'vc_finding'} />}
          />
        );
      case 'wait_vc_results':
        return (
          <Chip
            style={{
              backgroundColor: '#64b5f6',
              width: '100%',
              color: 'white',
            }}
            label={<FormattedMessage id={'wait_vc_results'} />}
          />
        );
      case 'validated':
        return (
          <Chip
            style={{
              backgroundColor: '#81c784',
              width: '100%',
              color: 'white',
            }}
            label={<FormattedMessage id={'validated'} />}
          />
        );
      default:
        return value;
    }
  };

  return (
    <TableBody>
      {services &&
        data.map((row, index) => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {row.id}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {row.service && services.find(s => s.id === row.service).name}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {row.project_type &&
                services
                  .find(s => s.id === row.service)
                  .project_types.find(p => p.id === row.project_type).name}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {row.validation_date || '-'}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {row.samples_express ? (
                <FormattedMessage id={'projects_samples_received'} />
              ) : (
                <FormattedMessage id={'projects_samples_not_received'} />
              )}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {row.questionnaire_validated ? (
                <FormattedMessage id={'projects_answers_validated'} />
              ) : (
                <FormattedMessage id={'projects_answers_not_validated'} />
              )}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {getStudyChip(row.progress)}
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              <Link
                className={'see-petition-btn'}
                to={`/petition/${row.petition}`}
              >
                {intl.formatMessage({ id: 'show_petition' })}
              </Link>
            </TableCell>
            <TableCell component="th" scope="row" className={classes.tableCell}>
              <Link className={'see-petition-btn'} to={`/projects/${row.id}`}>
                <FormattedMessage id={'show_project'} />
              </Link>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

ProjectsTableBody.propTypes = {};

const mapStateToProps = state => ({
  services: state.dataLoader.services,
});

export default connect(mapStateToProps)(
  injectIntl(withStyles({ tableCell })(ProjectsTableBody)),
);
