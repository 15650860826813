import React from 'react';
import PropTypes from 'prop-types';
import PetitionsTemplate from '../../templates/PetitionsTemplate';
import withData from '../../HOC/WithData';
import Loader from '../../organisms/Loader';

const PetitionsPage = props => {
  const { data } = props;
  return (
    <>
      {!data.petitions && <Loader />}
      {data.petitions && <PetitionsTemplate petitions={data.petitions} />}
    </>
  );
};

PetitionsPage.propTypes = {};

export default withData(PetitionsPage, 'petitions');
