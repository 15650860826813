import React from 'react';
import DocumentIcon from '@material-ui/icons/ListAlt';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/PersonOutline';
import StarIcon from '@material-ui/icons/Star';
import ProtectionIcon from '@material-ui/icons/VerifiedUser';
import ConsultIcon from '@material-ui/icons/QuestionAnswer';

import ServDocIcon from '../assets/images/icons/document.png';
import ServLockIcon from '../assets/images/icons/lock.png';
import ServCheckListIcon from '../assets/images/icons/check_list.png';
import ServStarIcon from '../assets/images/icons/star.png';
import ServProtectIcon from '../assets/images/icons/protection.png';
import ServQuestionIcon from '../assets/images/icons/question.png';
import {FormattedMessage} from "react-intl";

export const servicesListData = [
  {
    icon: <DocumentIcon />,
    servPageIcon: ServDocIcon,
    title: <FormattedMessage id={'service_regulatory'}/>,
    desc: <FormattedMessage id={'service_regulatory_desc'}/>,
  },
  {
    icon: <LockIcon />,
    servPageIcon: ServLockIcon,
    title: <FormattedMessage id={'service_eficacia'}/>,
    desc: <FormattedMessage id={'service_eficacia_desc'}/>,
  },
  {
    icon: <PersonIcon />,
    servPageIcon: ServCheckListIcon,
    title: <FormattedMessage id={'service_consumodores'}/>,
    desc: <FormattedMessage id={'service_consumodores_desc'}/>
  },
  {
    icon: <StarIcon />,
    servPageIcon: ServStarIcon,
    title: <FormattedMessage id={'service_otros'}/>,
    desc: <FormattedMessage id={'service_otros_desc'}/>,
  },
  {
    icon: <ProtectionIcon />,
    servPageIcon: ServProtectIcon,
    title: <FormattedMessage id={'service_seguridad'}/>,
    desc: <FormattedMessage id={'service_seguridad_desc'}/>,
  },
  {
    icon: <ConsultIcon />,
    servPageIcon: ServQuestionIcon,
    title: <FormattedMessage id={'service_consultas'}/>,
    desc: '',
  },
];

export const sliderData = [
  {
    title: 'Well Done!',
    desc: 'Hoy a las 09:00',
    text:
      'Expect warmly its tended garden him esteem had remove off. Effects dearest staying' +
      ' now sexteen nor improve',
  },
  {
    title: 'Hemos finalizado el estudio de CREMA EROSKI',
    desc: 'Ayer',
    text:
      'Expect warmly its tended garden him esteem had remove off. Effects dearest staying' +
      ' now sexteen nor improve',
  },
  {
    title: 'Well Done!',
    desc: 'Hoy a las 09:00',
    text:
      'Expect warmly its tended garden him esteem had remove off. Effects dearest staying' +
      ' now sexteen nor improve',
  },
];

export const leftMenuItems = [
  {
    title: 'Inicio',
    link: '/',
    lastInGroup: false,
  },
  {
    title: 'Servicios',
    link: '/services',
    lastInGroup: false,
  },
  {
    title: 'Peticiones',
    link: '/petitions',
    lastInGroup: false,
  },
  {
    title: 'Proyectos',
    link: '/projects',
    lastInGroup: false,
  },
  {
    title: 'Productos',
    link: '/products',
    lastInGroup: false,
  },
  {
    title: 'Facturas',
    link: '/invoices',
    lastInGroup: false,
    disabled: true,
  },
  {
    title: 'Calendario',
    link: '/calendar',
    lastInGroup: false,
    disabled: true,
  },
  {
    title: 'Informes',
    link: '/reports',
    lastInGroup: true,
    disabled: true,
  },
  {
    title: 'Mensajes',
    link: '/messages',
    lastInGroup: false,
    disabled: true,
  },
  {
    title: 'Notificaciones',
    link: '/notifications',
    lastInGroup: false,
    disabled: true,
  },
  {
    title: 'Archivos',
    link: '/archive',
    lastInGroup: false,
    disabled: true,
  },
  {
    title: 'Perfil Empresa',
    link: '/company_profiles',
    lastInGroup: true,
    disabled: true,
  },
  {
    title: 'Ajustes',
    link: '/settings',
    lastInGroup: false,
    disabled: true,
  },
  {
    title: 'Centro de Ayuda',
    link: '/support_center',
    lastInGroup: true,
    disabled: true,
  },
  {
    title: 'Cerrar Sesión',
    link: '/auth/logout',
    lastInGroup: true,
  },
];
