import React from 'react';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import './style.css'

const Renew = () => {
  return (
    <AutoRenewIcon className={'renew'} />
  );
};

export default Renew;