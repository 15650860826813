import { all, take, put, select, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  GET_DATA_BY_NAME,
  SET_DATA_BY_NAME,
  GET_PROJECT_COUNTERS,
  SET_PROJECT_COUNTERS,
  GET_WHOAMI,
  SET_WHOAMI,
  SET_DATA_ERROR,
  CLEAR_DATA_ERROR,
  PATCH_PROFILE,
  GET_CS_PROFILE,
  SET_CLIENT,
  PATCH_CLIENT,
  GET_PROJECT_BY_ID,
  SET_PROJECT_BY_ID,
  SET_SERVICES,
  GET_COMMON,
  SET_COMMON,
} from '../types';
import { getAxios } from '../util';
import localforage from 'localforage';
import { SET_QUESTIONNAIRE_BY_ID } from './questionnaireActions';

export const getDataByName = values => ({
  type: GET_DATA_BY_NAME,
  payload: values,
});

export const getProjectCounters = values => ({
  type: GET_PROJECT_COUNTERS,
  payload: values,
});

export const getWhoAmI = values => ({
  type: GET_WHOAMI,
  payload: values,
});

export const clearDataError = values => ({
  type: CLEAR_DATA_ERROR,
  payload: values,
});

export const onPatchProfile = values => ({
  type: PATCH_PROFILE,
  payload: values,
});

export const getCSProfile = values => ({
  type: GET_CS_PROFILE,
  payload: values,
});

export const patchClient = values => ({
  type: PATCH_CLIENT,
  payload: values,
});

export const getProjectById = values => ({
  type: GET_PROJECT_BY_ID,
  payload: values,
});

export const getCommon = values => ({
  type: GET_COMMON,
  payload: values,
});

const onGetCommonSaga = function*() {
  while (true) {
    try {
      yield take(GET_COMMON);
      const ax = yield getAxios();
      const { data } = yield ax.get(`${process.env.REACT_APP_API_URL}common/`);
      yield put({
        type: SET_COMMON,
        payload: { common: data },
      });
    } catch (e) {
      // TODO catch error
    }
  }
};

const onGetProjectByIdSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(GET_PROJECT_BY_ID);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.get(
        `${process.env.REACT_APP_API_URL}projects/${payload.id}/`,
      );
      const products = Array.isArray(data.products)
        ? yield all(
            data.products.map(product =>
              ax.get(`${process.env.REACT_APP_API_URL}products/${product}/`),
            ),
          )
        : [];
      let questionnaires = Array.isArray(data.questionnaires)
        ? yield all(
            data.questionnaires.map(questionnaire =>
              ax.get(
                `${
                  process.env.REACT_APP_API_URL
                }questionnaires/${questionnaire}/`,
              ),
            ),
          )
        : [];
      questionnaires = questionnaires.map(q => q.data);
      yield all(
        questionnaires.map(q =>
          put({ type: SET_QUESTIONNAIRE_BY_ID, payload: q }),
        ),
      );
      yield put({
        type: SET_PROJECT_BY_ID,
        payload: {
          ...data,
          products: Array.isArray(products)
            ? products.map(product => product.data)
            : [],
          questionnaires: questionnaires,
        },
      });
    } catch (e) {
      yield put({
        type: SET_DATA_ERROR,
        payload: { error: e.toString() },
      });
    }
  }
};

const onPatchClientSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(PATCH_CLIENT);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.patch(
        `${process.env.REACT_APP_API_URL}csprofiles/${payload.cs_profile_id}/`,
        {
          client: {
            ...payload,
            cs_profile_id: undefined,
          },
        },
      );
      yield put({
        type: SET_CLIENT,
        payload: data.client,
      });
    } catch (e) {
      yield put({
        type: SET_DATA_ERROR,
        payload: { error: e.toString() },
      });
    }
  }
};

const onGetCSProfile = function*() {
  while (true) {
    try {
      const { payload } = yield take(GET_CS_PROFILE);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.get(
        `${process.env.REACT_APP_API_URL}csprofiles/${payload.cs_profile_id}/`,
      );
      yield put({
        type: SET_CLIENT,
        payload: data.client,
      });
    } catch (e) {
      yield put({
        type: SET_DATA_ERROR,
        payload: { error: e.toString() },
      });
    }
  }
};

const onPatchProfileSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(PATCH_PROFILE);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.patch(
        `${process.env.REACT_APP_API_URL}csprofiles/${payload.cs_profile_id}/`,
        {
          user: {
            ...payload,
            cs_profile_id: undefined,
            account_type: undefined,
          },
        },
      );
      yield put({
        type: SET_WHOAMI,
        payload: { ...data.user, cs_profile_id: payload.cs_profile_id },
      });
    } catch (e) {
      yield put({
        type: SET_DATA_ERROR,
        payload: { error: e.toString() },
      });
    }
  }
};

const onGetWhoAmI = function*() {
  while (true) {
    try {
      const action = yield take(GET_WHOAMI);
      const state = yield select();
      const token = state.auth.auth_data.token;
      if (token) {
        const ax = yield getAxios(token);
        const { data } = yield ax.get(
          `${process.env.REACT_APP_API_URL}profile`,
        );
        yield put({
          type: SET_WHOAMI,
          payload: data,
        });
      }
    } catch (e) {
      yield put({
        type: SET_DATA_ERROR,
        payload: { error: e.toString() },
      });
    }
  }
};

const forkGetDataByName = function*(name, page, rowsPerPage, filters) {
  try {
    const state = yield select();
    const token = state.auth.auth_data.token;
    if (token) {
      const ax = yield getAxios(token);
      const [{ data }, { data: services }] = yield all([
        ax.get(
          `${
            process.env.REACT_APP_API_URL
          }${name}/?page=${page}&page_size=${rowsPerPage}&${filters}`,
        ),
        ax.get(`${process.env.REACT_APP_API_URL}services`),
      ]);
      yield put({
        type: SET_SERVICES,
        payload: {
          services: services.results,
        },
      });
      yield put({
        type: SET_DATA_BY_NAME,
        payload: {
          name: filters ? `filtered${name}` : name,
          data,
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_DATA_ERROR,
      payload: { error: e.toString() },
    });
  }
};

const onGetDataByNameSaga = function*() {
  while (true) {
    try {
      const action = yield take(GET_DATA_BY_NAME);
      const { name, rowsPerPage = 10, filters = '' } = action.payload;
      const page = action.payload.page + 1 || 1;
      yield fork(forkGetDataByName, name, page, rowsPerPage, filters);
    } catch (e) {
      yield put({
        type: SET_DATA_ERROR,
        payload: { error: e.toString() },
      });
    }
  }
};

const onGetProjectCountersSaga = function*() {
  while (true) {
    try {
      const action = yield take(GET_PROJECT_COUNTERS);
      const state = yield select();
      const token = state.auth.auth_data.token;
      const ax = yield getAxios(token);
      const [vc_finding, in_progress, done] = yield all([
        ax.get(
          `${
            process.env.REACT_APP_API_URL
          }projects/count/?field=progress&value=vc_finding`,
        ),
        ax.get(
          `${
            process.env.REACT_APP_API_URL
          }projects/count/?field=progress&value=in_progress&value=wait_vc_results`,
        ),
        ax.get(
          `${
            process.env.REACT_APP_API_URL
          }projects/count/?field=progress&value=done`,
        ),
      ]);
      yield put({
        type: SET_PROJECT_COUNTERS,
        payload: {
          vc_finding: vc_finding.data.count,
          in_progress: in_progress.data.count,
          done: done.data.count,
        },
      });
    } catch (e) {
      yield put({
        type: SET_DATA_ERROR,
        payload: { error: e.toString() },
      });
    }
  }
};

export default function*() {
  yield all([
    onGetDataByNameSaga(),
    onGetProjectCountersSaga(),
    onGetWhoAmI(),
    onPatchProfileSaga(),
    onGetCSProfile(),
    onPatchClientSaga(),
    onGetProjectByIdSaga(),
    onGetCommonSaga(),
  ]);
}
