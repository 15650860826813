import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../redux';
import Login from '../pages/auth/log_in/Login';
import SIgnUp from '../pages/auth/sign_up/SignUp';
import Registration from '../pages/auth/registration/Registration';
import FinalRegistration from '../pages/auth/final_registration/FInalRegistration';
import ForgottenPassword from '../pages/auth/forgotten_password/ForgottenPassword';
import { NewPetitionPage, ServicesPage } from '../pages';
import PetitionLoader from '../views/pages/PetitionLoader';
import ResumeWait from '../containers/ResumeWait';
import HomePage from '../views/pages/HomePage';
import PetitionsPage from '../views/pages/PetitionsPage';
import ProductsPage from '../views/pages/ProductsPage';
import ProjectsPage from '../views/pages/ProjectsPage';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import AppDrawer from '../views/templates/AppDrawer/app-drawer';
import AuthLoader from '../views/templates/AuthLoader';
import {
  onSocketMessage,
  onSocketSendToken,
} from '../redux/actions/socketActions';
import MessagesPage from '../views/pages/MessagesPage';
import NotificationsPage from '../views/pages/NotificationsPage';
import ProfilePage from '../views/pages/ProfilePage';
import CompanyPage from '../views/pages/CompanyPage';
import ProjectPage from '../views/pages/ProjectPage';

import { Layout, MainWrapper } from 'cosmocare-common';

import sidebarContent from './sidebarContent';
import profileContent from './profileContent';
import { getWhoAmI } from '../redux/actions/dataLoaderActions';
import { logout } from '../redux/actions/authActions';
import NotificationHandler from '../views/molecules/NotificationHandler';
import QuestionnairePage from '../views/pages/QuestionnairePage';
import PasswordRecovered from '../pages/auth/forgotten_password/PasswordRecovered';

import LanguageSelect from "../pages/DashboardPage/common/SelectLang";

const Router = ({
  auth,
  onSocketMessage,
  ws,
  onSocketSendToken,
  whoami,
  getWhoAmI,
  logout,
}) => {
  // simple loader
  const noDataView = <AuthLoader />;
  // auth views
  const authView = (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/auth/login" component={Login} />
        <Route exact path="/auth/logout" component={Login} />
        <Route exact path="/auth/signup" component={SIgnUp} />
        <Route
          exact
          path="/auth/forgotten-password"
          component={ForgottenPassword}
        />
        <Route
          exact
          path="/auth/password-recovered"
          component={PasswordRecovered}
        />
        <Route exact path="/reg/registration" component={Registration} />
        <Route
          exact
          path="/reg/final-registration"
          component={FinalRegistration}
        />
        <Redirect from={'/'} to={'/auth/login'} />
      </Switch>
    </ConnectedRouter>
  );
  // user views
  const userView = (
    <ConnectedRouter history={history}>
      <Switch>
        <MainWrapper>
          <Layout
            sidebarContent={sidebarContent}
            profileContent={profileContent}
            logout={logout}
            username={
              whoami
                ? `${whoami.first_name} ${whoami.last_name} ${
                    whoami.second_last_name
                  }`
                : ''
            }
            lang={<LanguageSelect/>}
          />
          <div className="container__wrap">
            <NotificationHandler />
            <Route exact path="/" component={HomePage} />
            <Route path="/petitions" component={PetitionsPage} />
            <Route path="/petition" exact component={NewPetitionPage} />
            <Route path="/petition/:id" exact component={PetitionLoader} />
            <Route path="/petition/:id/wait" exact component={ResumeWait} />
            <Route path="/projects" exact component={ProjectsPage} />
            <Route path="/projects/:id" exact component={ProjectPage} />
            <Route path="/products" component={ProductsPage} />
            <Route path="/services" component={ServicesPage} />
            <Route exact path="/messages" component={MessagesPage} />
            <Route exact path="/notifications" component={NotificationsPage} />
            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/company" component={CompanyPage} />
            <Route
              exact
              path="/project/:project/questionnaire/:questionnaire"
              component={QuestionnairePage}
            />
          </div>
        </MainWrapper>
      </Switch>
    </ConnectedRouter>
  );
  switch (auth.auth) {
    // no data about login
    case undefined:
      return noDataView;
    // not logged in
    case false:
      return authView;
    // logged in
    case true:
      !whoami && getWhoAmI();
      if (auth.auth_data && auth.auth_data.token) {
        // websocket connected
        if (ws.readyState === 1) {
          // authorize user token
          onSocketSendToken();
          // take messages from websocket
          ws.onmessage = function(event) {
            onSocketMessage(JSON.parse(event.data));
          };
        }
        return userView;
      } else {
        return null;
      }
    default:
      return noDataView;
  }
};

const mapStateToProps = state => ({
  auth: state.auth,
  ws: state.socket.ws,
  whoami: state.dataLoader.whoami,
});

export default connect(
  mapStateToProps,
  { onSocketMessage, onSocketSendToken, getWhoAmI, logout },
)(Router);
