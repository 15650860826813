import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    style={{ width: '100%', margin: '0 0 20px 0'}}
    {...input}
    {...custom}
    label={label}
  />
);

export default renderTextField;
