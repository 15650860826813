import React, { useEffect, useState } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { attachFilesToProject } from '../../../redux/actions/petitionHandlerActions';
import Header from '../components/Header';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DocumentationForm, ObservationsForm, ProductsForm } from '../forms';
import { withProjectEditorBlock } from '../helpers';
import { submit } from 'redux-form';
import fileErrorView from './fileError';

function RegulatoryService(props) {
  const {
    handleSubmit,
    intl,
    submitProject,
    project,
    attachFilesToProject,
    docs,
    products,
  } = props;

  useEffect(() => {
    const { handleSubmit } = props;
    return () => {
      handleSubmit();
    };
  }, []);

  const [fileError, setFileError] = useState(null);

  const attachFiles = e => {
    const files = e.target.files;
    console.log('files', files);
    if (files.length > 0) {
      if (files[0].size > 104857600) {
        setFileError(fileErrorView);
      } else {
        setFileError(null);
        submit('RegulatoryService');
        attachFilesToProject({ file: files[0], project });
      }
    }
  };

  const productsView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'petition_products' })} />,
  )(<ProductsForm products={project.products} allProducts={products} />);

  const documentationView = withProjectEditorBlock(
    <Header
      head={intl.formatMessage({ id: 'petition_documents' })}
      description={intl.formatMessage({
        id: 'petition_documents_descr',
      })}
    />,
  )(
    <DocumentationForm
      attachFiles={attachFiles}
      docs={docs}
      project={project}
      fileError={fileError}
    />,
  );

  const observationView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'petition_observation' })} />,
  )(<ObservationsForm />);

  return (
    <form onSubmit={handleSubmit(submitProject)}>
      {productsView}
      {documentationView}
      {observationView}
    </form>
  );
}

export default reduxForm({
  form: 'RegulatoryService',
  enableReinitialize: true,
})(
  connect(
    null,
    { attachFilesToProject, submit },
  )(injectIntl(RegulatoryService)),
);
