import React, {Component} from 'react';
import './styles.scss';
import ReactDataGrid from 'react-data-grid';
import SearchIcon from '@material-ui/icons/Search';
import {withStyles} from "@material-ui/core";
import BlueButton from '../../components/BlueButton';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const styles = {
    searchIcon: {
        fill: '#555555',
        fontWeight: 100,
        fontSize: '18px',
        position: 'absolute',
        left: '105px'
    }
};

class ProductsTable extends Component {

    render(){

        const {classes} = this.props;

        const columns = [
            { key: 'id', name: 'Nº petición' },
            { key: 'project_type', name: 'Tipo de Proyecto' },
            { key: 'product_name', name: 'Producto' },
            { key: 'product_code', name: 'Ref. producto' },
            { key: 'petition_date', name: 'Fecha petition' },
            { key: 'project_status', name: 'Estado de proyecto' },
            { key: 'check_studio', name: 'Estado del estudio' }

        ];

        const rows = [];

        for (let i = 0; i < 23; i++) {
            rows.push(
                {
                    id: i,
                    project_type: 'Patch Test 48H',
                    product_name: 'Mousse intima eroski',
                    product_code: 'RC13002PENTA',
                    petition_date: '12.03.2018',
                    project_status: 'Cancelado',
                    check_studio: 'ver peticion',
                }
            );
        }


        return(
            <div className={'projects-table products'}>
                <div className={'projects-table-options'}>
                    <div className={'items-per-page'}>
                        <span>Mostrar</span>
                        <input type={'number'} name={'items_per_page'} id={'items_per_page'} placeholder={'10'} />
                        <span>entradas</span>
                        <BlueButton active={false}>Editar</BlueButton>
                        <BlueButton active={false}>Eliminar</BlueButton>
                    </div>
                    <div className={'table-search'}>
                        <input type={'text'} name={'search_field'} placeholder={'Buscar...'} />
                        <SearchIcon className={classes.searchIcon} />
                        <Typography component={Link}
                                    to={`/product`}
                                    style={{

                                        textDecoration: 'none'
                                    }}>
                            <BlueButton active={true}>Anadir nuevo producto</BlueButton>
                        </Typography>
                    </div>
                </div>
                <ReactDataGrid
                    pagination={true}
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={470}

                />
            </div>
        )
    }
}


export default withStyles(styles)(ProductsTable);
