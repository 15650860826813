import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Renew from '../../../views/atoms/Renew';

import DeleteIcon from '@material-ui/icons/DeleteForever';
import { deleteFileFromProject } from '../../../redux/actions/petitionHandlerActions';

const styles = theme => ({
  bold: {
    fontWeight: 'bold',
  },
  langMarginRight: {
    marginRight: '6rem',
  },
  lang: {
    margin: '0 4px',
  },
  blockMargin: {
    marginBottom: '2rem',
  },
  files: {
    backgroundColor: '#2196f3',
    padding: '4px 20px',
    borderRadius: '5px',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  doc: {
    color: '#757575',
    fontSize: '.8rem',
  },
  mobileFlexColumn: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
});

const DocumentationForm = props => {
  const {
    classes,
    attachFiles,
    docs,
    networkProject,
    project,
    deleteFileFromProject,
    fileError
  } = props;
  return (
    <div>
      <div
        className={classNames(classes.blockMargin, classes.mobileFlexColumn)}
      >
        <span className={classNames(classes.bold, classes.langMarginRight)}>
          <FormattedMessage id={'petition_report_language'} />
        </span>
        <label className={classes.langMarginRight}>
          <Field
            name="language"
            component="input"
            type="radio"
            value="Español"
          />
          <span className={classes.lang}>Español</span>
        </label>
        <label className={classes.langMarginRight}>
          <Field
            name="language"
            component="input"
            type="radio"
            value="English"
          />
          <span className={classes.lang}>English</span>
        </label>
        <label className={classes.langMarginRight}>
          <Field
            name="language"
            component="input"
            type="radio"
            value="Français"
          />
          <span className={classes.lang}>Français</span>
        </label>
      </div>
      <div
        className={classNames(classes.blockMargin, classes.mobileFlexColumn)}
      >
        <label htmlFor="projectFiles" className={classes.files}>
          <FormattedMessage id={'petition_project_download_documents'} />{' '}
          {networkProject[project.id] &&
            networkProject[project.id].documentLoading && <Renew />}
        </label>
        <input type="file" hidden id={'projectFiles'} onChange={attachFiles} />
        <span>
          <FormattedMessage id={'petition_study_files_descr'} />
        </span>
        {fileError}
      </div>
      {docs &&
        docs.length > 0 &&
        docs.map(doc => (
          <div key={doc.id} className={classes.doc}>
            {doc.name}{' '}
            <DeleteIcon
              onClick={() => deleteFileFromProject({ id: doc.id, project })}
              style={{ color: 'red', cursor: 'pointer' }}
            />
          </div>
        ))}
    </div>
  );
};

DocumentationForm.propTypes = {};

const mapStateToProps = state => ({
  networkProject: state.network.project,
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { deleteFileFromProject },
  )(DocumentationForm),
);
