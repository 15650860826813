import React, {Component} from 'react';
import './styles.scss';


class CardHeader extends Component{

    render(){

        const p = this.props;

        return(
          <div className='card-header'>
            <h5 className='card-title'>{p.title}</h5>
            <p className='card-desc'>
              {p.desc || p.children}
            </p>
          </div>
        )
    }
}

export default CardHeader;