import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Avatar from '@material-ui/core/Avatar';

const ChatHeader = ({ name, onClick, selected, message }) => {
  const classes = selected
    ? 'chat-header-selected chat-header-root'
    : 'chat-header-not-selected chat-header-root';
  return (
    <div onClick={onClick} className={classes}>
      <div className={'chat-header-avatar'}>
        <Avatar>{name[0]}</Avatar>
      </div>
      <div>
        <div className={'chat-header-name'}>{name}</div>
        <div className={'chat-header-message'}>{message}</div>
      </div>
    </div>
  );
};

ChatHeader.propTypes = {};

export default ChatHeader;
