import React from 'react';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import LanguageSelect from './SelectLang';
import RegHeader from './RegHeader';
import Alert from '../../../components/Alert';
import SupportBlock from './SupportBlock';

const RegWrapper = ({ children }) => (
  <Fade in>
    <section className="reg-wrapper">
      <LanguageSelect />
      <Grid container style={{ flexGrow: 1 }} spacing={0}>
        <Grid item xs={12}>
          <RegHeader />
        </Grid>

        <Grid item lg={8} md={7} xs={12}>
          <Grid item container justify="center">
            <div className="reg-wrapper__children">{children}</div>
          </Grid>
        </Grid>

        <Grid item lg={4} md={5} xs={12}>
          <Grid item container justify="center">
            <SupportBlock />
          </Grid>
        </Grid>

        <Alert />
      </Grid>
    </section>
  </Fade>
);

export default RegWrapper;
