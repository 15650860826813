import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const ResumeModeOfUse = ({ project, common }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div>
          <b>
            <FormattedMessage id={'modeofuse_application_area'} />:{' '}
          </b>
          {Array.isArray(project.application_area) &&
            Array.isArray(common.preferred_application_zones) &&
            project.application_area.map(item => (
              <>
                {
                  common.preferred_application_zones.find(
                    zone => zone.id === item,
                  ).value
                }{' '}
                /{' '}
              </>
            ))}
        </div>
        <div>
          <b>
            <FormattedMessage id={'modeofuse_frequency_of_use'} />:{' '}
          </b>
          {project.frequency_of_use}
        </div>
        <div>
          <b>
            <FormattedMessage id={'modeofuse_amount_of_use'} />:{' '}
          </b>
          {project.amount_of_use}
        </div>
        <div>
          <b>
            <FormattedMessage id={'modeofuse_refinements_method'} />:{' '}
          </b>
          {project.refinements_method}
        </div>
      </Grid>
    </Grid>
  );
};

ResumeModeOfUse.propTypes = {};

const mapStateToProps = state => ({
  common: state.dataLoader.common,
});

export default connect(mapStateToProps)(ResumeModeOfUse);
