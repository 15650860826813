import { all, take, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  ATTACH_FILES_TO_PROJECT,
  BIND_FILE_TO_PROJECT,
  PETITION_ADD_PRODUCT,
  PETITION_CREATE_PROJECT,
  PETITION_GET_SERVICES,
  PETITION_HANDLER_ERROR,
  PETITION_HANDLER_GET_BY_ID,
  PETITION_HANDLER_PATCH_PROJECT,
  PETITION_HANDLER_PATCH_PROJECT_IN_STATE,
  PETITION_HANDLER_RESET,
  PETITION_HANDLER_SET_CURRENT_PETITION,
  PETITION_PUSH_PRODUCT,
  PETITION_PUSH_PROJECT,
  PETITION_VALIDATE,
  PETITION_PATCH_PRODUCT,
  PETITION_STATE_PATCH_PRODUCT,
  PETITION_HANDLER_DELETE_PROJECT,
  PETITION_HANDLER_DELETE_PROJECT_FROM_STATE,
  PETITION_HANDLER_DELETE_FILE_FROM_PROJECT,
  PETITION_CREATE_DRAFT,
} from '../types';
import { getAxios } from '../util';
import localforage from 'localforage';
import { getNumberFromProgress } from '../../helpers/progress';
import { SET_FILE_UPLOADER, SET_FILE_UPLOADER_VALUE } from './networkActions';

export const getPetitionById = values => ({
  type: PETITION_HANDLER_GET_BY_ID,
  payload: values,
});

export const onPetitionAddProduct = values => ({
  type: PETITION_ADD_PRODUCT,
  payload: values,
});

export const onPetitionCreateProject = values => ({
  type: PETITION_CREATE_PROJECT,
  payload: values,
});

export const onPatchProject = (values, withApi = false) => ({
  type: PETITION_HANDLER_PATCH_PROJECT,
  payload: values,
  withApi,
});

export const attachFilesToProject = values => ({
  type: ATTACH_FILES_TO_PROJECT,
  payload: values,
});

export const onPetitionValidate = values => ({
  type: PETITION_VALIDATE,
  payload: values,
});

export const onPetitionHandlerReset = values => ({
  type: PETITION_HANDLER_RESET,
  payload: values,
});

export const onPatchProduct = values => ({
  type: PETITION_PATCH_PRODUCT,
  payload: values,
});

export const onDeleteProject = values => ({
  type: PETITION_HANDLER_DELETE_PROJECT,
  payload: values,
});

export const getServices = values => ({
  type: PETITION_GET_SERVICES,
  payload: values,
});

export const deleteFileFromProject = values => ({
  type: PETITION_HANDLER_DELETE_FILE_FROM_PROJECT,
  payload: values,
});

export const petitionCreateDraft = values => ({
  type: PETITION_CREATE_DRAFT,
  payload: values,
});

export const onDeleteProjectSaga = function*() {
  while (true) {
    try {
      const action = yield take(PETITION_HANDLER_DELETE_PROJECT);
      console.log('onDeleteProjectSaga', action);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { status } = yield ax.delete(
        `${process.env.REACT_APP_API_URL}projects/${action.payload.id}/`,
      );
      if (status === 204) {
        yield put({
          type: PETITION_HANDLER_DELETE_PROJECT_FROM_STATE,
          payload: { id: action.payload.id },
        });
      }
    } catch (e) {
      yield put({
        type: PETITION_HANDLER_ERROR,
        payload: e.toString(),
      });
    }
  }
};

const onPatchProductSaga = function*() {
  while (true) {
    try {
      const action = yield take(PETITION_PATCH_PRODUCT);
      const product = action.payload;
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.patch(
        `${process.env.REACT_APP_API_URL}products/${product.id}/`,
        product,
      );
      yield put({
        type: PETITION_STATE_PATCH_PRODUCT,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: PETITION_HANDLER_ERROR,
        payload: error.toString(),
      });
    }
  }
};

const patchProjectWithAPI = function*({ project }) {
  const { token } = yield localforage.getItem('auth');
  const ax = yield getAxios(token);
  const { data } = yield ax.patch(
    `${process.env.REACT_APP_API_URL}projects/${project.id}/`,
    { ...project, products: project.products.map(product => product.id) },
  );
  yield put({
    type: PETITION_HANDLER_PATCH_PROJECT_IN_STATE,
    payload: data,
  });
};

const onPetitionValidateSaga = function*() {
  while (true) {
    try {
      const action = yield take(PETITION_VALIDATE);
      console.log('onPetitionValidateSaga');
      const state = yield select();
      console.log('state onPetitionValidateSaga', state);
      if (!state.petitionHandler.petition) {
        return;
      }
      const id = action.payload.id;
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { projects: updProjects } = action.payload;
      yield all(
        updProjects.map(project => patchProjectWithAPI({ project, action })),
      );
      const { data } = yield ax.patch(
        `${process.env.REACT_APP_API_URL}petitions/${id}/`,
        {
          ...action.payload,
          projects: action.payload.projects.map(project => ({
            ...project,
            products: project.products.map(product => product.id),
            service: project.service.id,
            project_type: project.project_type
              ? project.project_type.id
              : undefined,
          })),
        },
      );
      let projects = yield all(
        data.projects.map(project =>
          ax.get(`${process.env.REACT_APP_API_URL}projects/${project.id}/`),
        ),
      );
      let products = yield all(
        data.products.map(product =>
          ax.get(`${process.env.REACT_APP_API_URL}products/${product.id}`),
        ),
      );
      products = products.map(product => product.data);
      yield put({
        type: PETITION_HANDLER_SET_CURRENT_PETITION,
        payload: {
          ...data,
          projects: projects
            .map(p => p.data)
            .reverse()
            .map(project => ({
              ...project,
              products: project.products.map(product =>
                products.find(p => p.id === product),
              ),
            })),
          products: products,
        },
      });
      if (getNumberFromProgress(data.progress) > 2 && !action.payload.restrictPush) {
        yield put(push(`/petition/${data.id}/wait`));
      }
    } catch (error) {
      yield put({
        type: PETITION_HANDLER_ERROR,
        payload: error.toString(),
      });
    }
  }
};

const onPetitionGetByIdSaga = function*() {
  while (true) {
    try {
      const action = yield take(PETITION_HANDLER_GET_BY_ID);
      yield put({
        type: PETITION_GET_SERVICES,
      });
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const petition = yield ax.get(
        `${process.env.REACT_APP_API_URL}petitions/${action.payload}`,
      );
      const products = yield all(
        petition.data.products.map(product =>
          ax.get(`${process.env.REACT_APP_API_URL}products/${product.id}`),
        ),
      );
      let projects = yield all(
        petition.data.projects.map(project =>
          ax.get(`${process.env.REACT_APP_API_URL}projects/${project.id}`),
        ),
      );

      projects = yield all(
        projects.map(project => getProjectQuestionnaire(project.data)),
      );

      const nextProducts = products.map(product => product.data);

      yield put({
        type: PETITION_HANDLER_SET_CURRENT_PETITION,
        payload: {
          ...petition.data,
          products: nextProducts,
          projects: projects
            // .map(project => project.data)
            .reverse()
            .map(project => ({
              ...project,
              products: project.products.map(product =>
                nextProducts.find(p => p.id === product),
              ),
            })),
        },
      });
    } catch (error) {
      yield put({
        type: PETITION_HANDLER_ERROR,
        payload: error.toString(),
      });
    }
  }
};

const getProjectQuestionnaire = function*(project) {
  try {
    const { token } = yield localforage.getItem('auth');
    const ax = yield getAxios(token);
    project.questionnaires = yield all(
      project.questionnaires.map(questionnaire =>
        ax.get(
          `${process.env.REACT_APP_API_URL}questionnaires/${questionnaire}/`,
        ),
      ),
    );
    project.questionnaires = project.questionnaires.map(
      questionnaire => questionnaire.data,
    );
    return project;
  } catch (e) {}
};

// Create project in store
const onPetitionCreateProjectSaga = function*() {
  while (true) {
    try {
      const action = yield take(PETITION_CREATE_PROJECT);
      const state = yield select();
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.post(
        `${process.env.REACT_APP_API_URL}projects/`,
        action.payload,
      );
      const products = yield all(
        data.products.map(product =>
          ax.get(`${process.env.REACT_APP_API_URL}products/${product}`),
        ),
      );
      yield put({
        type: PETITION_PUSH_PROJECT,
        payload: { ...data, products: products.map(product => product.data) },
      });
    } catch (error) {
      yield put({
        type: PETITION_HANDLER_ERROR,
        payload: error.toString(),
      });
    }
  }
};

// Add product in new petition
const onAddProductSaga = function*() {
  while (true) {
    try {
      const action = yield take(PETITION_ADD_PRODUCT);
      console.log('onAddProductSaga', action.payload);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.post(
        `${process.env.REACT_APP_API_URL}products/`,
        action.payload,
      );
      yield put({
        type: PETITION_PUSH_PRODUCT,
        payload: data,
      });
    } catch (error) {
      yield put({
        type: PETITION_HANDLER_ERROR,
        payload: error.toString(),
      });
    }
  }
};

// Patch petition project
const onPetitionPatchProjectSaga = function*() {
  while (true) {
    try {
      const action = yield take(PETITION_HANDLER_PATCH_PROJECT);
      console.log('patch with api', action.withApi);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      if (action.withApi) {
        const { data } = yield ax.patch(
          `${process.env.REACT_APP_API_URL}projects/${action.payload.id}/`,
          {
            ...action.payload,
            products: action.payload.products.map(product => product.id),
          },
        );
        const products = yield all(
          data.products.map(product =>
            ax.get(`${process.env.REACT_APP_API_URL}products/${product}/`),
          ),
        );
        yield put({
          type: PETITION_HANDLER_PATCH_PROJECT_IN_STATE,
          payload: { ...data, products: products.map(product => product.data) },
        });
      } else {
        console.log('action.payload.products', action.payload.products);
        const products = yield all(
          action.payload.products.map(product =>
            ax.get(
              `${process.env.REACT_APP_API_URL}products/${
                Number.isInteger(product) ? product : product.id
              }/`,
            ),
          ),
        );
        yield put({
          type: PETITION_HANDLER_PATCH_PROJECT_IN_STATE,
          payload: {
            ...action.payload,
            products: products.map(product => product.data),
          },
        });
      }
    } catch (error) {
      yield put({
        type: PETITION_HANDLER_ERROR,
        payload: error.toString(),
      });
    }
  }
};

const onAttachFilesToProjectSaga = function*() {
  while (true) {
    try {
      const action = yield take(ATTACH_FILES_TO_PROJECT);
      yield put({
        type: SET_FILE_UPLOADER_VALUE,
        payload: {
          project: action.payload.project.id,
          documentLoading: true,
        },
      });
      const formData = new FormData();
      formData.append('file', action.payload.file);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.post(
        `${process.env.REACT_APP_API_URL}attachments/`,
        formData,
      );
      yield put({
        type: BIND_FILE_TO_PROJECT,
        payload: {
          fileId: data.id,
          project: action.payload.project,
        },
      });
      yield put({
        type: SET_FILE_UPLOADER_VALUE,
        payload: {
          project: action.payload.project.id,
          documentLoading: false,
        },
      });
    } catch (error) {
      yield put({
        type: PETITION_HANDLER_ERROR,
        payload: error.toString(),
      });
    }
  }
};

const deleteFileFromProjectSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(PETITION_HANDLER_DELETE_FILE_FROM_PROJECT);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { status } = yield ax.delete(
        `${process.env.REACT_APP_API_URL}attachments/${payload.id}`,
      );
      if (status === 204) {
        const state = yield select();
        let values = {};
        switch (payload.project.service) {
          case 1:
            values = {
              ...state.form.RegulatoryService.values,
            };
            break;
          case 2:
          case 3:
            values = {
              ...state.form.SafetyAndEfficacyService.values,
            };
            break;
          case 4:
            values = {
              ...state.form.OtherServices.values,
            };
            break;
          case 5:
            values = {
              ...state.form.ConsultationService.values,
            };
            break;
          case 6:
            values = {
              ...state.form.SecurityPackService.values,
            };
            break;
        }
        yield put({
          type: PETITION_HANDLER_PATCH_PROJECT_IN_STATE,
          payload: {
            ...values,
            attachments: values.attachments.filter(a => a.id !== payload.id),
          },
        });
      }
    } catch (e) {}
  }
};

const onBindFileToProjectSaga = function*() {
  while (true) {
    try {
      const action = yield take(BIND_FILE_TO_PROJECT);
      const { token } = yield localforage.getItem('auth');
      const state = yield select();
      const ax = yield getAxios(token);
      const { project } = action.payload;
      console.log('project', project);
      let values = {};
      switch (project.service) {
        case 1:
          values = {
            ...state.form.RegulatoryService.values,
          };
          break;
        case 2:
        case 3:
          values = {
            ...state.form.SafetyAndEfficacyService.values,
          };
          break;
        case 4:
          values = {
            ...state.form.OtherServices.values,
          };
          break;
        case 5:
          values = {
            ...state.form.ConsultationService.values,
          };
          break;
        case 6:
          values = {
            ...state.form.SecurityPackService.values,
          };
          break;
      }
      const { data } = yield ax.patch(
        `${process.env.REACT_APP_API_URL}projects/${project.id}/`,
        {
          // ...state.form.RegulatoryService.values,
          ...values,
          products: values.products.map(product => product.id),
          attachments: [...project.attachments, { id: action.payload.fileId }],
        },
      );
      const products = yield all(
        data.products.map(product =>
          ax.get(`${process.env.REACT_APP_API_URL}products/${product}`),
        ),
      );
      yield put({
        type: PETITION_HANDLER_PATCH_PROJECT_IN_STATE,
        payload: { ...data, products: products.map(product => product.data) },
      });
    } catch (error) {
      yield put({
        type: PETITION_HANDLER_ERROR,
        payload: error.toString(),
      });
    }
  }
};

const petitionCreateDraftSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(PETITION_CREATE_DRAFT);
      console.log('petitionCreateDraftSaga', payload.petition);
    } catch (e) {}
  }
};

export default function*() {
  yield all([
    onPetitionGetByIdSaga(),
    onAddProductSaga(),
    onPetitionCreateProjectSaga(),
    onPetitionPatchProjectSaga(),
    onAttachFilesToProjectSaga(),
    onBindFileToProjectSaga(),
    onPetitionValidateSaga(),
    onPatchProductSaga(),
    onDeleteProjectSaga(),
    deleteFileFromProjectSaga(),
    petitionCreateDraftSaga(),
  ]);
}
