import {
  SOCKET_CLEAR_ERROR,
  SOCKET_CLEAR_NOTIFICATION,
  SOCKET_ERROR,
  SOCKET_GET_MESSAGES,
  SOCKET_NEW_MESSAGE,
  SOCKET_ON_MESSAGE,
  SOCKET_PUSH_MESSAGE,
  SOCKET_SEND_MESSAGE,
  SOCKET_SEND_TOKEN,
  SOCKET_SET_CHAT_BY_ID,
  SOCKET_ATTACH_FILE_TO_CHAT,
} from '../types';
import { all, take, put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import localforage from 'localforage';
import { getAxios } from '../util';
import { SET_FILE_UPLOADER_VALUE } from './networkActions';

export const onSocketMessage = values => ({
  type: SOCKET_ON_MESSAGE,
  payload: values,
});

export const onSocketSendToken = values => ({
  type: SOCKET_SEND_TOKEN,
  payload: values,
});

export const onSocketSendMessage = values => ({
  type: SOCKET_SEND_MESSAGE,
  payload: values,
});

export const onSocketGetMessages = values => ({
  type: SOCKET_GET_MESSAGES,
  payload: values,
});

export const onSocketClearNotification = values => ({
  type: SOCKET_CLEAR_NOTIFICATION,
  payload: values,
});

export const onSocketClearError = values => ({
  type: SOCKET_CLEAR_ERROR,
  payload: values,
});

export const onAttachFileToChat = values => ({
  type: SOCKET_ATTACH_FILE_TO_CHAT,
  payload: values,
});

export const onAttachFileToChatSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(SOCKET_ATTACH_FILE_TO_CHAT);
      yield put({
        type: SET_FILE_UPLOADER_VALUE,
        payload: {
          chat: payload.id,
          documentLoading: true,
        },
      });
      const formData = new FormData();
      formData.append('file', payload.file);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.post(
        `${process.env.REACT_APP_API_URL}attachments/`,
        formData,
      );
      yield put({
        type: SOCKET_SEND_MESSAGE,
        payload: {
          chatId: payload.id,
          data: {
            attachments: [data.id],
            message_type: "attachments"
          },
        },
      });
      yield put({
        type: SET_FILE_UPLOADER_VALUE,
        payload: {
          chat: payload.id,
          documentLoading: false,
        },
      });
    } catch (e) {
      yield put({
        type: SOCKET_ERROR,
        payload: e.toString(),
      });
    }
  }
};

export const onSocketGetMessagesSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(SOCKET_GET_MESSAGES);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.get(
        `${process.env.REACT_APP_API_URL}chats/${payload.id}/messages/`,
      );
      yield put({
        type: SOCKET_SET_CHAT_BY_ID,
        payload: {
          id: payload.id,
          results: data.results,
        },
      });
    } catch (e) {
      yield put({
        type: SOCKET_ERROR,
        payload: e.toString(),
      });
    }
  }
};

export const onSocketSendMessageSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(SOCKET_SEND_MESSAGE);
      const state = yield select();
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.post(
        `${process.env.REACT_APP_API_URL}chats/${payload.chatId}/messages/`,
        payload.data,
      );
      yield put({
        type: SOCKET_PUSH_MESSAGE,
        payload: {
          id: payload.chatId,
          message: data,
        },
      });
    } catch (e) {
      yield put({
        type: SOCKET_ERROR,
        payload: e.toString(),
      });
    }
  }
};

export const onSocketMessageSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(SOCKET_ON_MESSAGE);
      console.log('onSocketMessageSaga', payload);
      if (payload.type && payload.type === 'new_message') {
        yield put({
          type: SOCKET_NEW_MESSAGE,
          payload: payload.content,
        });
        yield put({
          type: SOCKET_GET_MESSAGES,
          payload: { id: payload.content.chat },
        });
      }
      // yield put({
      //   type: SOCKET_PUSH_MESSAGE,
      //   payload: action.payload,
      // });
    } catch (e) {
      yield put({
        type: SOCKET_ERROR,
        payload: e.toString(),
      });
    }
  }
};

export const onSocketSendTokenSaga = function*() {
  while (true) {
    try {
      const action = yield take(SOCKET_SEND_TOKEN);
      const state = yield select();
      const token = state.auth.auth_data.token;
      const ws = state.socket.ws;
      // TODO change token
      ws.send(
        JSON.stringify({
          command: 'authorization',
          token: token,
        }),
      );
    } catch (e) {
      // TODO catch e
    }
  }
};

export default function*() {
  yield all([
    onSocketMessageSaga(),
    onSocketSendTokenSaga(),
    onSocketSendMessageSaga(),
    onSocketGetMessagesSaga(),
    onAttachFileToChatSaga(),
  ]);
}
