import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ResumeQuestionnaires = ({ questionnaires, project }) => {
  return (
    <div>
      {questionnaires.map(q => (
        <div key={`questionnairelink${q.id}${q.name}`}>
          <Link to={`/project/${project}/questionnaire/${q.id}`}>{q.id} {q.name}</Link>{' '}
          {q.status ? `[${q.status}]` : '[not validated]'}
        </div>
      ))}
    </div>
  );
};

ResumeQuestionnaires.propTypes = {
  questionnaires: PropTypes.array.isRequired,
  project: PropTypes.number.isRequired,
};

export default ResumeQuestionnaires;
