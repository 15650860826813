import React, {Component} from 'react';
import CircularProgressBar from '../CircularProgressBar';
import './styles.scss';


class PercentageBlock extends Component{


    constructor(){
        super();
        this.state = {
            value: 0
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                value: this.props.value
            })
        }, 300)
    }

    render(){

        return(

            <div className={'percentage-block'}>
                <p>{this.props.text}</p>
                <CircularProgressBar
                    strokeWidth="7"
                    sqSize="85"
                    percentage={this.state.value}
                    color={this.props.color}
                    counter={this.props.counter}
                />
            </div>

        )
    }
}

export default PercentageBlock;