const validate = values => {
    const errors = {};
    const dataArrayErrors = [];
    values.data.forEach((product, productIndex) => {
        const productErrors = {};
        if (!product || !product.name || !product.name.replace(/\s/g, '').length) {
            productErrors.name = 'Required';
            dataArrayErrors[productIndex] = productErrors;
        }
    });
    if (dataArrayErrors.length) {
        errors.data = dataArrayErrors;
    }
    return errors;
};

export default validate;