import React from 'react';
import TextField from '@material-ui/core/TextField';

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    style={{ width: '70%' }}
    {...input}
    {...custom}
  />
);
export default renderTextField;
