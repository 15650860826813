import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WaitImage from './src/wait.jpg';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { getWhoAmI } from '../redux/actions/dataLoaderActions';
import { connect } from 'react-redux';

const styles = {
  root: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10vh 10vw',
  },
  wImage: {
    height: '40vh',
  },
  greetingWithName: {
    fontSize: '2.4rem',
  },
  sendMessage: {
    margin: '2vh 2vw',
  },
  days: {
    fontSize: '.8rem',
  },
  btn: {
    margin: '8vh 8vw',
  },
};

class ResumeWait extends Component {
    componentDidMount() {
        const { getWhoAmI } = this.props;
        getWhoAmI();
    }
    render() {
        let {whoami, classes, match: {params}} = this.props;
        return (
            <div className={classes.root}>
                <span
                    className={classes.greetingWithName}>¡Perfecto {whoami.first_name} {whoami.last_name} {whoami.second_last_name}!</span>
                <FormattedMessage id={'petition_request_send'}/>
                <img className={classes.wImage} src={WaitImage} alt=""/>
                <span className={classes.sendMessage}>
        <FormattedMessage id={'petition_request_send_message'}/>
      </span>
                <span className={classes.days}>
        <FormattedMessage id={'petition_request_days'}/>
      </span>
                <Link
                    to={`/petition/${params.id}`}
                    style={{textDecoration: 'none'}}
                >
                    <Button className={classes.btn} variant="contained" color="primary">
                        <FormattedMessage id={'show_petition'}/>
                    </Button>
                </Link>
            </div>
        );
    }
}

ResumeWait.propTypes = {};

const mapStateToProps = state => ({
    whoami: state.dataLoader.whoami,
});

export default withStyles(styles)(
  connect(
      mapStateToProps,
    { getWhoAmI },
  )(ResumeWait),
);
