import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import authReducer from './authReducer';
import localeReducer from './localeReducer';
import dashboardReducer from './dashboardReducer';
import petitionReducer from './petitionReducer';
import petitionHandlerReducer from "./petitionHandlerReducer";
import dataLoaderReducer from "./dataLoaderReducer";
import socketReducer from "./socketReducer";

import themeReducer from "cosmocare-common/dist/redux/reducers/themeReducer";
import sidebarReducer from "cosmocare-common/dist/redux/reducers/sidebarReducer";
import customizerReducer from "cosmocare-common/dist/redux/reducers/customizerReducer";
import questionnaireReducer from './questionnaireReducer';
import networkReducer from './networkReducer';
import { AUTH_LOGOUT_SECCESS } from '../types';
import initialState from './initialState';

const reducers = combineReducers({
  auth: authReducer,
  locale: localeReducer,
  dashboard: dashboardReducer,
  petition: petitionReducer,
  petitionHandler: petitionHandlerReducer,
  dataLoader: dataLoaderReducer,
  socket: socketReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  customizer: customizerReducer,
  questionnaires: questionnaireReducer,
  network: networkReducer,
  form,
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT_SECCESS) {
    return new reducers({
      auth: initialState.authReducer,
      petition: initialState.petitionReducer,
      petitionHandler: initialState.petitionHandlerReducer,
      questionnaires: initialState.questionnaireReducer,
      socket: initialState.socketReducer,
      network: { project: {}, chat: {} },
      dataLoader: initialState.dataLoaderReducer
    }, action);
  }
  return reducers(state, action);
};

export default rootReducer;
