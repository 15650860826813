import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import PlanningDate from '../../views/molecules/PlanningDate';
import { FormattedMessage } from 'react-intl';

const ResumeProjectPlanning = ({
  startDate,
  middleDate,
  endDate,
  reportDate,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <PlanningDate
          date={startDate}
          text={<FormattedMessage id={'planning_start_date'} />}
        />
        <PlanningDate
          date={middleDate}
          text={<FormattedMessage id={'planning_middle_date'} />}
        />
        <PlanningDate
          date={endDate}
          text={<FormattedMessage id={'planning_end_date'} />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <PlanningDate
          date={reportDate}
          text={<FormattedMessage id={'planning_report_date'} />}
        />
      </Grid>
    </Grid>
  );
};

ResumeProjectPlanning.propTypes = {};

export default ResumeProjectPlanning;
