import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getNumberFromProgress } from '../../../helpers/progress';
import Arrow from '@material-ui/icons/KeyboardArrowRight';
import TableBody from '@material-ui/core/TableBody';
import '../../helpers/see-petition.scss';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { tableCell } from '../../helpers/styles';

const PetitionsTableBody = ({ data, intl, services, classes }) => {
  return (
    <TableBody>
      {services &&
        data.map((row, index) => (
          <TableRow
            style={{
              backgroundColor: index % 2 === 1 ? '#fafafa' : null,
            }}
            key={row.id}
          >
            <TableCell component="th" scope="row" className={classes.tableCell}>
              {row.id}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {row.projects &&
                row.projects.length > 0 &&
                row.projects[0].project_type &&
                services
                  .find(s => s.id === row.projects[0].service)
                  .project_types.find(
                    p => p.id === row.projects[0].project_type,
                  ).name}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {row.products && row.products.length > 0 && row.products[0].name}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {row.products &&
                row.products.length > 0 &&
                row.products[0].reference}
            </TableCell>
            <TableCell
              style={{ whiteSpace: 'nowrap' }}
              className={classes.tableCell}
            >
              {row.creation_date}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {row.projects && row.projects.length > 0 && (
                <FormattedMessage id={row.projects[0].progress} />
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Link className={'see-petition-btn'} to={`/petition/${row.id}`}>
                {intl.formatMessage({ id: 'show_petition' })}
              </Link>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

PetitionsTableBody.propTypes = {};

const mapStateToProps = state => ({
  services: state.dataLoader.services,
});

export default connect(
  mapStateToProps,
  null,
)(injectIntl(withStyles({ tableCell })(PetitionsTableBody)));
