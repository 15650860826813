export default {
  blockMargin: {
    marginBottom: '24px',
    marginTop: '24px',
  },
  bold: {
    fontWeight: 'bold',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}