import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProjectById } from '../../../redux/actions/dataLoaderActions';
import Loader from '../../organisms/Loader';
import ProjectTemplate from '../../templates/ProjectTemplate';
import { getServices } from '../../../redux/actions/petitionHandlerActions';

class ProjectPage extends Component {
  componentDidMount() {
    const {
      getProjectById,
      getServices,
      match: { params },
    } = this.props;
    getProjectById({ id: params.id });
    getServices();
  }

  render() {
    const {
      projects,
      services,
      match: {
        params: { id },
      },
    } = this.props;
    return (
      <>
        {(!projects[id] || !services) && <Loader />}
        {projects[id] && services && (
          <ProjectTemplate project={projects[id]} services={services} />
        )}
      </>
    );
  }
}

ProjectPage.propTypes = {};

const mapStateToProps = state => ({
  projects: state.dataLoader.projects,
  services: state.petition.services,
});

export default connect(
  mapStateToProps,
  { getProjectById, getServices },
)(ProjectPage);
