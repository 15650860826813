import React, { Component } from 'react';
import RegWrapper from './RegWrapper';

const withRegWrapper = WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <RegWrapper>
          <WrappedComponent />
        </RegWrapper>
      );
    }
  };
};

export default withRegWrapper;