import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const HomeGreeting = ({ whoami }) => {
  return (
    <>
      {!whoami && null}
      {whoami && (
        <div className={'home-greeting-root'}>
          <h5 className={'home-greeting-company-name'}>
            cosmocare<span>pro</span>
          </h5>
          <h5 className={'home-greeting-welcome'}>
            <FormattedMessage id={'home_page_greeting'} />, {whoami.first_name}{' '}
            {whoami.last_name} {whoami.second_last_name}!
          </h5>
          <div className={'home-page-greeting-message'}>
            <FormattedMessage id={'home_page_greeting_message'} />
          </div>
        </div>
      )}
    </>
  );
};

HomeGreeting.propTypes = {};

const mapStateToProps = state => ({
  whoami: state.dataLoader.whoami,
});

export default connect(
  mapStateToProps,
  null,
)(HomeGreeting);
