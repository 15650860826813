import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../organisms/DataTable';
import ProductsTableHead from '../../molecules/ProductsTableHead';
import ProductsTableBody from '../../molecules/ProductsTableBody/';
import TableFooter from '../../molecules/TableFooter';
import PageHeader from '../../../components/PageHeader';

const ProductsTemplate = ({ products }) => {
  return (
    <>
      <PageHeader title={'Productos'} />
      <DataTable
        head={<ProductsTableHead />}
        body={<ProductsTableBody data={products.results} />}
        count={products.count}
        name={'products'}
      />
    </>
  );
};

ProductsTemplate.propTypes = {};

export default ProductsTemplate;
