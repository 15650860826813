import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../components/PageHeader';
import { FormattedMessage } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import ChatHeader from '../../molecules/ChatHeader';
import Chat from '../../organisms/Chat/chat';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Fab from '@material-ui/core/Fab';
import './styles.scss';
import { NavLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

class MessagesTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChat: -1,
    };
    this.handleChatClick = this.handleChatClick.bind(this);
  }

  handleChatClick = chatId => {
    this.setState({
      selectedChat: chatId,
    });
  };

  render() {
    const { selectedChat } = this.state;
    const { chats } = this.props;
    const selectedChatData = chats.results.find(c => c.id === selectedChat);
    return (
      <>
        <PageHeader title={<FormattedMessage id={'navigation_messages'} />} />
        {chats.results.length > 0 && (
          <Grid container>
            <Grid item xs={12} md={2}>
              <Paper
                elevation={1}
                style={{ height: '70vh', overflowY: 'scroll' }}
              >
                {chats.results &&
                  chats.results.map(chat => (
                    <ChatHeader
                      selected={selectedChat === chat.id}
                      onClick={() => this.handleChatClick(chat.id)}
                      key={chat.id}
                      name={`${chat.chat_type} ${chat[chat.chat_type]}`}
                      message={chat.last_message ? chat.last_message.text : ''}
                    />
                  ))}
              </Paper>
            </Grid>
            <Grid item xs={12} md={10}>
              {selectedChat > -1 && (
                <>
                  <Chat chatId={selectedChat} />
                  {selectedChatData.chat_type === 'petition' && (
                    <div className={'messages-template-button'}>
                      <NavLink
                        style={{ textDecoration: 'none' }}
                        to={`/petition/${selectedChatData.petition}`}
                      >
                        <Fab
                          variant="extended"
                          size="small"
                          color="primary"
                          aria-label="Add"
                        >
                          <AssignmentIcon />
                          <FormattedMessage id={'show_petition'} />
                        </Fab>
                      </NavLink>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

MessagesTemplate.propTypes = {};

export default MessagesTemplate;
