import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import logoSvg from '../../../assets/images/svg/prof-admin-logo.svg';
import loginBgSvg from '../../../assets/images/svg/login-bg.svg';

const WelcomeBox = () => (
  <section className="welcome-box">
    <Link className="welcome-box__admin-logo" to="/">
      <img className="welcome-box__img" src={logoSvg} alt="CosmoPicture" />
    </Link>
    <div className="welcome-box__bg-img">
      <img className="welcome-box__img" src={loginBgSvg} alt="CosmoPicture" />
      <FormattedMessage className="welcome-box__title" id="welcome_title" />
    </div>
  </section>
);

export default WelcomeBox;
