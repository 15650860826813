import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import './styles.scss';
import ChatAttachFile from '../ChatAttachFile';
import { connect } from 'react-redux';
import Renew from '../../atoms/Renew';

const ChatInput = ({
  message,
  handleSend,
  handleInputChange,
  attachFiles,
  chatNetwork,
  chat,
}) => {
  const keyPressed = e => {
    if (
      e.key === 'Enter' &&
      message.length > 0 &&
      message.replace(/\s/g, '').length > 0
    ) {
      handleSend();
    }
  };
  return (
    <div className={'chat-input-root'} onKeyPress={keyPressed}>
      <TextField
        style={{ flexGrow: 1 }}
        id="message"
        name={'message'}
        margin="normal"
        size="small"
        value={message}
        onChange={handleInputChange}
        inputProps={{
          maxLength: 500,
        }}
      />
      <div className={'chat-input-icons'}>
        <SendIcon style={{ cursor: 'pointer' }} onClick={handleSend} />
        {chatNetwork[chat] && chatNetwork[chat].documentLoading ? (
          <Renew />
        ) : (
          <ChatAttachFile attachFiles={attachFiles} />
        )}
      </div>
    </div>
  );
};

ChatInput.propTypes = {};

const mapStateToProps = state => ({
  chatNetwork: state.network.chat,
});

export default connect(mapStateToProps)(ChatInput);
