import React, { Component } from 'react';
// import LeftMenuAppBar from '../../containers/LeftMenuAppBar';
import PageHeader from '../../components/PageHeader';
import BlueButton from '../../components/BlueButton';
import ProjectsTable from './ProjectsTable';

class ProjectsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="projects-section">
        <PageHeader
          title="Mis Proyectos"
          desc="Use this elements, if you want to show some hints or additional information, use .default-tabs class"
        >
          <BlueButton
            active
            styles={{
              width: '135px',
              height: '44px',
              fontSize: '14px',
              fontWeight: 'medium'
            }}
          >
            Nuevo Estudio
          </BlueButton>
        </PageHeader>

        <ProjectsTable />
      </section>
    );
  }
}

export default ProjectsListPage;
