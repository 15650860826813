import React, {Component} from 'react';


class NotSlideItem extends Component{

    render(){

        const p = this.props;

        return(
            <div className={'slide-item'}>
                <div className={'slide-item-wrapper'}>
                    <h5>{p.title}</h5>
                    <span>{p.desc}</span>
                    <p>{p.text}</p>
                </div>
            </div>
        )
    }
}

export default NotSlideItem;