import React, { Component } from 'react';
// import BlueButton from '../../components/BlueButton';
import PageHeader from '../../components/PageHeader';
import ProductsTable from './ProductsTable';
// import LeftMenuAppBar from '../../containers/LeftMenuAppBar';
import './styles.scss';

class ProductsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className="products-section">
        <PageHeader
          title="Listado de Productos"
          desc="Use this elements, if you want to show some hints or additional information, use .default-tabs class"
        />

        <ProductsTable />
      </section>
    );
  }
}

export default ProductsPage;
