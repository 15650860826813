import React, { Component } from 'react';
import AuthWrapper from './AuthWrapper';

const withAuthWrapper = WrappedComponent => {
  return class extends Component {
    render() {
      return (
        <AuthWrapper>
          <WrappedComponent />
        </AuthWrapper>
      );
    }
  };
};

export default withAuthWrapper;
