import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getCSProfile,
  getProjectCounters,
  getWhoAmI,
} from '../../../redux/actions/dataLoaderActions';
import Loader from '../../organisms/Loader';
import CompanyTemplate from '../../templates/CompanyTemplate/company-template';

class CompanyPage extends Component {
  constructor(props) {
    super(props);
    this.checkCSProfile = this.checkCSProfile.bind(this);
  }

  componentDidMount() {
    this.props.getProjectCounters();
    this.checkCSProfile();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkCSProfile();
  }

  checkCSProfile = () => {
    const { getCSProfile, getWhoAmI, whoami, client } = this.props;
    if (whoami && !client) {
      getCSProfile({ cs_profile_id: whoami.cs_profile_id });
    } else if (!whoami) {
      getWhoAmI();
    }
  };

  render() {
    const { client, counters, whoami } = this.props;
    return (
      <>
        {(!client || !counters) && <Loader />}
        {client && counters && (
          <CompanyTemplate
            client={{ ...client, cs_profile_id: whoami.cs_profile_id }}
            counters={counters}
          />
        )}
      </>
    );
  }
}

CompanyPage.propTypes = {};

const mapStateToProps = state => ({
  whoami: state.dataLoader.whoami,
  counters: state.dataLoader.projectCounters,
  client: state.dataLoader.client,
});

export default connect(
  mapStateToProps,
  { getWhoAmI, getCSProfile, getProjectCounters },
)(CompanyPage);
