import React from 'react';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Input from '@material-ui/core/Input';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import FilledInput from '@material-ui/core/FilledInput';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  menuItem: {
    whiteSpace: 'normal',
    height: 'auto',
  }
});

const MenuItems = [
  {
    id: 1,
    name: 'Regulatory',
  },
  {
    id: 2,
    name: 'Safety and efficacy',
  },
  {
    id: 3,
    name: 'Regulatory',
  },
  {
    id: 4,
    name: 'Regulatory',
  },
  {
    id: 5,
    name: 'Regulatory',
  },
  {
    id: 6,
    name: 'Regulatory',
  },
];

class MuiSelect extends React.Component {
  state = {
    name: '',
    // labelWidth: 200,
  };

  //   componentDidMount() {
  //     // this.setState({
  //     //   labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
  //     // });
  //   }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      classes,
      label,
      none,
      widthFull,
      data,
      value,
      name,
      disabled,
    } = this.props;
    // const { name } = this.state;
    return (
      //   <form className={classes.root} autoComplete="off">
      <FormControl
        className={classes.formControl}
        style={widthFull ? { width: '100%' } : {}}
      >
        <label htmlFor="name-simple">{label}</label>
        {/* <InputLabel htmlFor="age-simple">{name}</InputLabel> */}
        <Select
          disabled={disabled}
          autoWidth
          onChange={this.props.onChange}
          value={value}
          displayEmpty
          inputProps={{
            name: name,
            id: `${name}-id`,
          }}
          classes={{select: classes.menuItem}}
        >
          <MenuItem value={0} disabled>
            <em>{none}</em>
          </MenuItem>
          {data &&
            data.map(item => (
              <MenuItem
                key={item.id}
                value={item.id}
                disabled={item.disabled}
                classes={{root: classes.menuItem}}
              >
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      //   </form>
    );
  }
}

MuiSelect.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(MuiSelect);
