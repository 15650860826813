import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';
import { renderTextField } from '../../../../components/form/renders';
import { BlueSmallButton, BlueSmallButtonOutlined } from '../../common/Buttons';
import { styles } from './input_styles';
import validate from './validate';

class LoginForm extends React.PureComponent {
  state = { show: false, save: false };

  handleShowPass = () => this.setState(prev => ({ show: !prev.show }));

  handleSavePass = () => this.setState(prev => ({ save: !prev.save }));

  render() {
    const { handleSubmit, lang } = this.props;
    const { show, save } = this.state;
    return (
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="auth-form__title-wrapper">
          <div className="auth-form__decor" />
          <div className="auth-form__parts">
            <div className="auth-form__litle-parts">
              <div className="auth-form__first-part">
                <FormattedMessage id="auth_first_part" />
              </div>

              <div className="auth-form__second-part">
                <FormattedMessage id="auth_second_part" />
              </div>
            </div>
            <div className="auth-form__sub-part">
              <FormattedMessage id="auth_sub_part" />
            </div>
          </div>
        </div>

        <div className="auth-form__wrapper">
          <div className="auth-form__user">
            <FormattedMessage id="auth_email" />
          </div>
          <div className="auth-form__user-placeholder">
            <Field
              name="email"
              component={renderTextField}
              placeholder="auth_email_placeholer"
              autoComplete={save ? 'on' : 'off'}
              className="auth-form__placeholder"
              styles={styles}
              lang={lang}
            />
          </div>
          <div className="auth-form__user">
            <FormattedMessage id="auth_pass" />
          </div>
          <div className="auth-form__pass-placeholder">
            <Field
              name="password"
              component={renderTextField}
              placeholder="auth_pass_placeholder"
              type={show ? 'text' : 'password'}
              autoComplete={save ? 'on' : 'off'}
              styles={styles}
              lang={lang}
            />
            {show ? (
              <Visibility onClick={this.handleShowPass} />
            ) : (
              <VisibilityOff onClick={this.handleShowPass} />
            )}
          </div>
          <Link
            className="auth-form__forget-pass"
            to="/auth/forgotten-password"
          >
            <FormattedMessage id="auth_forget_pass" />
          </Link>
          <div className="auth-form__remember-pass">
            <Checkbox
              className="auth-form__remember-checkbox"
              checked={save}
              onChange={this.handleSavePass}
              color="secondary"
            />
            <FormattedMessage id="auth_remember_pass" />
          </div>

          <div className="auth-form__btn-wrapper">
            <div className="auth-form__btn-inner-wrapper">
              <BlueSmallButton id="auth_log_in" type="submit" />
            </div>
            <Link className="auth-form__forget-pass" to="/auth/signup">
              <BlueSmallButtonOutlined id="auth_create_account" />
            </Link>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.locale.lang
});

LoginForm = reduxForm({
  form: 'login',
  validate
})(LoginForm);

LoginForm = connect(mapStateToProps)(LoginForm);

export default LoginForm;
