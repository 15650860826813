import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProfileTemplate from '../../templates/ProfileTemplate';
import { connect } from 'react-redux';
import Loader from '../../organisms/Loader';
import { getProjectCounters } from '../../../redux/actions/dataLoaderActions';

class ProfilePage extends Component {
  componentDidMount() {
    const { getProjectCounters } = this.props;
    getProjectCounters();
  }

  render() {
    let { whoami, counters } = this.props;
    return (
      <>
        {!whoami && !counters && <Loader />}
        {whoami && counters && (
          <ProfileTemplate whoami={whoami} counters={counters} />
        )}
      </>
    );
  }
}

ProfilePage.propTypes = {};

const mapStateToProps = state => ({
  whoami: state.dataLoader.whoami,
  counters: state.dataLoader.projectCounters,
});

export default connect(
  mapStateToProps,
  { getProjectCounters },
)(ProfilePage);
