import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Profile from '../../organisms/Profile';
import ProfileEditor from '../../organisms/ProfileEditor';

const ProfileTemplate = ({ whoami, counters }) => {
  return (
    <Grid container spacing={40}>
      <Grid item xs={12} md={4}>
        <Profile whoami={whoami} counters={counters} />
      </Grid>
      <Grid item xs={12} md={8}>
        <ProfileEditor initialValues={whoami} />
      </Grid>
    </Grid>
  );
};

ProfileTemplate.propTypes = {};

export default ProfileTemplate;
