import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles';

const ResumeHeader = ({text, classes}) => {
    return (
        <div className={classNames(classes.resumeHeader)}>
            {text}
        </div>
    );
};

ResumeHeader.propTypes = {
    text: PropTypes.node.isRequired
};

export default withStyles(styles)(ResumeHeader);