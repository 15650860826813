import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles';
import { FormattedMessage } from 'react-intl';

const ResumeProjectHeader = ({ index, serviceName, classes }) => {
  return (
    <div className={classNames(classes.resumeProjectHeader)}>
      <span>
        <FormattedMessage id={'petition_project'} /> {index}:
      </span>{' '}
      <span className={classNames(classes.service)}>{serviceName}</span>
    </div>
  );
};

ResumeProjectHeader.propTypes = {};

export default withStyles(styles)(ResumeProjectHeader);
