import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import AssignmentIcon from '@material-ui/icons/AssignmentLate';
import WarningIcon from '@material-ui/icons/Warning';
import './styles.scss';
import { Link } from 'react-router-dom';

const Notification = ({ notification }) => {
  let nextStr = '';
  let nextView = null;
  let link = null;
  switch (notification.notification_type) {
    case 'project_validated':
      link = (
        <Link to={`/projects/${notification.projects}`}>
          {notification.project}
        </Link>
      );
      nextStr = `Project ${notification.project} validated`;
      break;
    case 'project_canceled':
      link = (
        <Link to={`/projects/${notification.project}`}>
          {notification.project}
        </Link>
      );
      nextStr = (
        <>
          {`Project `}
          {link}
          {` canceled`}
        </>
      );
      break;
    case 'new_vc_profile':
      link = (
        <Link to={`/user/${notification.vc_profile}/detail`}>
          {notification.vc_profile}
        </Link>
      );
      nextStr = (
        <>
          {`New vc_profile `}
          {link}
          {` created`}
        </>
      );
      break;
    case 'new_client':
      nextStr = (
        <>
          {`New client `}
          {` registered`}
        </>
      );
      break;
    case 'new_petition':
      link = (
        <Link to={`/petition/${notification.petition}`}>
          {notification.petition}
        </Link>
      );
      nextStr = (
        <>
          {`New petition `}
          {link}
          {` created`}
        </>
      );
      break;
    case 'new_message':
      nextStr = `You've received a new message`;
      break;
    case 'new_invitation':
      link = (
        <Link to={`/convocatorias/${notification.invitation}/edit`}>
          {notification.invitation}
        </Link>
      );
      nextStr = (
        <>
          {`New invitation `}
          {link}
          {` created`}
        </>
      );
      break;
    case 'new_questionnaire':
      link = (
        <Link to={`/questionnaires/${notification.questionnaire}/edit`}>
          {notification.questionnaire}
        </Link>
      );
      nextStr = (
        <>
          {`New questionnaire `}
          {link}
          {` created`}
        </>
      );
      break;
    case 'accepted_in_test':
      link = (
        <Link to={`/projects/${notification.project}`}>
          {notification.project}
        </Link>
      );
      nextStr = (
        <>
          {`You were accepted in `}
          {link}
          {` project`}
        </>
      );
      break;
    case 'rejected_in_test':
      link = (
        <Link to={`/projects/${notification.project}`}>
          {notification.project}
        </Link>
      );
      nextStr = (
        <>
          {`You were rejected in `}
          {link}
          {` project`}
        </>
      );
      break;
    case 'bad_product':
      nextStr = <>{`We received bad product`}</>;
      break;
    case 'product_not_received':
      nextStr = <>{`We did not received the product`}</>;
      break;
    case 'questionnaire_not_validated':
      link = (
        <Link to={`/questionnaire/${notification.questionnaire}/edit`}>
          {notification.questionnaire}
        </Link>
      );
      nextStr = (
        <>
          {`Questionnaire `}
          {link}
          {` was not validated`}
        </>
      );
      break;
    case 'project_modified':
      link = (
        <Link to={`/projects/${notification.project}`}>
          {notification.project}
        </Link>
      );
      nextStr = (
        <>
          {`Project `}
          {link}
          {` was modified`}
        </>
      );
      break;

    default:
      nextStr = '';
      break;
  }
  switch (notification.info_type) {
    case 'info':
      nextView = (
        <div className={'notification-root'}>
          <InfoIcon color={'secondary'} /> {nextStr}
        </div>
      );
      break;
    case 'success':
      nextView = (
        <div className={'notification-root'}>
          <ThumbUpIcon color={'primary'} /> {nextStr}
        </div>
      );
      break;
    case 'attention':
      nextView = (
        <div className={'notification-root'}>
          <AssignmentIcon color={'action'} /> {nextStr}
        </div>
      );
      break;
    case 'warning':
      nextView = (
        <div className={'notification-root'}>
          <WarningIcon color={'error'} /> {nextStr}
        </div>
      );
      break;
    default:
      nextView = null;
      break;
  }
  return <>{nextView}</>;
};

Notification.propTypes = {};

export default Notification;
