import initialState from './initialState';
import injectReducer from './injectReducer';
import {
  CLEAR_DATA_ERROR,
  SET_CLIENT, SET_COMMON,
  SET_DATA_BY_NAME,
  SET_DATA_ERROR,
  SET_PROJECT_BY_ID,
  SET_PROJECT_COUNTERS,
  SET_SERVICES,
  SET_WHOAMI,
} from '../types';

const handlers = {
  [SET_DATA_BY_NAME]: (state, { payload: { name, data } }) => ({
    ...state,
    data: {
      ...state.data,
      [name]: data,
    },
  }),
  [SET_PROJECT_COUNTERS]: (state, { payload }) => ({
    ...state,
    projectCounters: payload,
  }),
  [SET_WHOAMI]: (state, { payload }) => ({
    ...state,
    whoami: payload,
  }),
  [SET_DATA_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),
  [CLEAR_DATA_ERROR]: (state, { payload }) => ({
    ...state,
    error: null,
  }),
  [SET_CLIENT]: (state, { payload }) => ({
    ...state,
    client: payload,
  }),
  [SET_PROJECT_BY_ID]: (state, { payload }) => ({
    ...state,
    projects: { ...state.projects, [payload.id]: payload },
  }),
  [SET_SERVICES]: (state, { payload }) => ({
    ...state,
    services: payload.services,
  }),
  [SET_COMMON]: (state, { payload }) => ({
    ...state,
    common: payload.common
  })
};

export default injectReducer(initialState.dataLoaderReducer, handlers);
