import React from 'react';
import PropTypes from 'prop-types';
import Warning from '@material-ui/icons/Warning';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core';

const styles = {
  root: {
      margin: '4vh 10vw'
  }
};

const ErrorViewer = ({ error, classes }) => {
  return (
    <div>
        <Paper elevation={1}>
            <Typography variant="h5" component="h3">
                <Warning color={'red'} /> Oops! Something went wrong.
            </Typography>
            <Typography component="p">
                {error}
            </Typography>
        </Paper>
    </div>
  );
};

ErrorViewer.propTypes = {
  error: PropTypes.string.isRequired,
};

export default withStyles(styles)(ErrorViewer);
