import React, { PureComponent } from 'react';
import ReactDataGrid from 'react-data-grid';
import { withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import SaveIcon from '@material-ui/icons/Save';

import '../../styles.scss';
import { injectIntl } from 'react-intl';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';

const styles = {
  searchIcon: {
    fill: '#555555',
    fontWeight: 100,
    fontSize: '18px',
    position: 'absolute',
    right: '5px',
  },
  addIcon: {
    color: '#70BBFD',
  },
};

class ProductsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      count: props.items.length,
      rows: [...props.items],
    };
    this.onGridRowsUpdated = this.onGridRowsUpdated.bind(this);
    this.onClickAddProduct = this.onClickAddProduct.bind(this);
    this.onClickSaveProducts = this.onClickSaveProducts.bind(this);
  }

  onGridRowsUpdated(object) {
    this.setState(state => {
      const rows = state.rows.slice();

      for (let i = object.fromRow; i <= object.toRow; i += 1) {
        rows[i] = { ...rows[i], ...object.updated };
      }
      return { rows };
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.state.rows) {
      this.setState({
        rows: [...nextProps.items],
        count: nextProps.items.length,
      });
    }
  }

  onClickAddProduct() {
    const { count, rows } = this.state;
    const rowsNew = rows;

    rowsNew.push({
      product_ID: rowsNew.length + 1,
      product_ref: '',
      product_name: '',
      product_type: '',
      product_number_series: '',
      production_date: null,
    });
    this.setState({ count: count + 1, rows: rowsNew });
    // this.addProduct({ name: 'aaaa' });
  }

  onClickSaveProducts() {
    const products = this.state.rows.filter(r => r.name && r.name.length > 0);
    console.log('products', products);
    products.forEach(product => {
      if (product.id) {
        console.log('patch');
        this.patchProduct(product);
      } else {
        console.log('add');
        this.addProduct(product);
      }
    });
  }

  addProduct = product => {
    this.props.addProductToPetition(product);
  };

  patchProduct = product => {
    this.props.patchProduct(product);
  };

  saveProduct = product => {
    // product.id ? this.patchProduct(product) : this.addProduct(product);
    console.log('product', product);
    if (product.id) {
      this.patchProduct(product);
    } else {
      this.addProduct(product);
    }
  };

  handleDateChange = nextDate => (this.setState({date: nextDate}));

  getColumns = () => {
    const { intl } = this.props;
    return [
      { key: 'reference', name: 'Referencia', editable: true },
      {
        key: 'name',
        name: intl.formatMessage({ id: 'petition_product_name' }),
        editable: true,
      },
      { key: 'product_type', name: 'Tipo de Producto', editable: true },
      { key: 'lot', name: '№ de Lote', editable: true },
      {
        key: 'production_date',
        name: 'Fecha de Fabricación',
        editable: true,
        formatter: ({row}) => (
          <TextField
            id="date"
            type="date"
            defaultValue={row.production_date || null}
            onChange={e => {
              row.production_date = e.target.value;
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
      },
      {
        key: '$delete',
        name: 'Acción',
        getRowMetaData: row => row,
        formatter: ({ dependentValues }) => (
          <>
            {/*<span>*/}
            {/*  <RemoveIcon*/}
            {/*    color="error"*/}
            {/*    onClick={() => this.deleteRow(dependentValues.product_ID)}*/}
            {/*  />*/}
            {/*</span>*/}
            <Fab
              size={'small'}
              color="secondary"
              disabled={
                !dependentValues.name || dependentValues.name.length === 0
              }
              aria-label="Save"
              onClick={() => this.saveProduct(dependentValues)}
            >
              <SaveIcon />
            </Fab>
          </>
        ),
      },
    ];
  };

  deleteRow(id) {
    const { count, rows } = this.state;
    // console.log('deleteRow=', id);
    let rowsNew = rows.slice();
    rowsNew = rowsNew.filter(row => row.product_ID !== id);
    this.setState({ rows: rowsNew, count: count - 1 });
  }

  render() {
    const { classes } = this.props;
    const { count, rows } = this.state;

    return (
      <div className="projects-table">
        <ReactDataGrid
          pagination
          columns={this.getColumns()}
          rowGetter={i => rows[i]}
          rowsCount={count}
          minHeight={rows.length * 42 + 50}
          onGridRowsUpdated={this.onGridRowsUpdated}
          enableCellSelect
        />
        {rows.findIndex(r => !r.id) === -1 && (
          <span className="empty-table">
            <AddIcon
              color="primary"
              className={classes.addIcon}
              onClick={this.onClickAddProduct}
            />
            Añadir Producto
          </span>
        )}
        {/*<div className="btn-right">*/}
        {/*  <button className="blue-save-btn" onClick={this.onClickSaveProducts}>*/}
        {/*    Salvar*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default withStyles(styles)(injectIntl(ProductsTable));
