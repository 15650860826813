import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withProjectEditorBlock } from '../helpers';
import Header from '../components/Header';
import { DocumentationForm, ObservationsForm, ProductsForm } from '../forms';
import { getFormValues, reduxForm, submit, change } from 'redux-form';
import { injectIntl } from 'react-intl';
import PanelForm from '../forms/PanelForm';
import ModeOfUseForm from '../forms/ModeOfUseForm';
import SamplesForm from '../forms/SamplesForm';
import ClaimsForm from '../forms/ClaimsForm';
import { connect } from 'react-redux';
import { attachFilesToProject } from '../../../redux/actions/petitionHandlerActions';
import fileErrorView from './fileError';

const SafetyAndEfficacyService = props => {
  const {
    handleSubmit,
    intl,
    submitProject,
    project,
    attachFilesToProject,
    docs,
    products,
    formValues,
    change
  } = props;

  const [fileError, setFileError] = useState(null);

  useEffect(() => {
    const { handleSubmit } = props;
    return () => {
      handleSubmit();
    };
  }, []);

  const attachFiles = e => {
    const files = e.target.files;
    if (files.length > 0) {
      if (files[0].size > 104857600) {
        setFileError(fileErrorView);
      } else {
        setFileError(null);
        submit('SafetyAndEfficacyService');
        attachFilesToProject({ file: files[0], project });
      }
    }
  };

  const productsView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'petition_products' })} />,
  )(<ProductsForm products={project.products} allProducts={products} />);

  const panelView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'panel' })} />,
  )(<PanelForm change={change} form={'SafetyAndEfficacyService'} />);

  const modeOfUseView = withProjectEditorBlock(
    <Header
      head={intl.formatMessage({ id: 'modeofuse' })}
      description={intl.formatMessage({ id: 'modeofuse_description' })}
    />,
  )(<ModeOfUseForm />);

  const documentationView = withProjectEditorBlock(
    <Header
      head={intl.formatMessage({ id: 'petition_documents' })}
      description={intl.formatMessage({
        id: 'petition_documents_descr',
      })}
    />,
  )(<DocumentationForm attachFiles={attachFiles} docs={docs} project={project} fileError={fileError} />);

  const samplesView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'samples_form' })} />,
  )(<SamplesForm />);

  const observationView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'petition_observation' })} />,
  )(<ObservationsForm />);

  const claimsView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'claims' })} />,
  )(<ClaimsForm claims={formValues.claims} change={change} form={'SafetyAndEfficacyService'} />);

  return (
    <form onSubmit={handleSubmit(submitProject)}>
      {productsView}
      {panelView}
      {modeOfUseView}
      {samplesView}
      {documentationView}
      {claimsView}
      {observationView}
    </form>
  );
};

SafetyAndEfficacyService.propTypes = {};

const mapStateToProps = state => ({
  formValues: getFormValues('SafetyAndEfficacyService')(state),
});

export default reduxForm({
  form: 'SafetyAndEfficacyService',
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    { attachFilesToProject, submit, change },
  )(injectIntl(SafetyAndEfficacyService)),
);
