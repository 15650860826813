import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { renderTextField } from '../../../../components/form/renders';
import { UniversalButton } from '../../common/Buttons';
import { styles } from './input_styles';
import validate from './validate';

class ForgottenPassword extends React.PureComponent {
  render() {
    const { handleSubmit, lang } = this.props;

    return (
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="auth-form__title-wrapper">
          <div className="auth-form__decor" />
          <div className="auth-form__parts">
            <div className="auth-form__litle-parts">
              <div className="auth-form__second-part">
                <FormattedMessage id="auth_forget_pass_title" />
              </div>
            </div>
            <div className="auth-form__sub-part">
              <FormattedMessage id="auth_forget_pass_sub" />
            </div>
          </div>
        </div>

        <div className="auth-form__wrapper">
          <div className="auth-form__user">
            <FormattedMessage id="auth_email" />
          </div>
          <div className="auth-form__user-placeholder">
            <Field
              name="email"
              component={renderTextField}
              placeholder="auth_email_placeholer"
              className="auth-form__placeholder"
              styles={styles}
              lang={lang}
            />
          </div>

          <div
            className="auth-form__btn-wrapper"
            style={{ marginBottom: '18px' }}
          >
            <div className="auth-form__btn-inner-wrapper">
              <UniversalButton
                id="auth_reg_btn_submit"
                type="submit"
                classname="auth-form__blue-big-btn"
              />
            </div>
          </div>
          <div className="auth-form__message-wrapper">
            <FormattedMessage id="auth_forget_pass_message" />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.locale.lang
});

ForgottenPassword = reduxForm({
  form: 'forgotten',
  validate
})(ForgottenPassword);

ForgottenPassword = connect(mapStateToProps)(ForgottenPassword);

export default ForgottenPassword;
