import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getPetitionById,
  onPetitionHandlerReset,
} from '../../redux/actions/petitionHandlerActions';
import { NewPetitionPage } from '../../pages';
import ErrorViewer from '../../components/ErrorViewer';
import Loader from "../organisms/Loader";

class PetitionLoader extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getPetitionById(id);
  }

  componentWillUnmount() {
    const { onPetitionHandlerReset } = this.props;
    onPetitionHandlerReset();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  render() {
    const { loading, petition } = this.props;
    const { hasError, error } = this.state;
    return (
      <>
        {hasError && <ErrorViewer error={error.toString()} />}
        {!petition && !hasError && <Loader />}
        {petition && !hasError && <NewPetitionPage petition={petition} />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state.petitionHandler,
});

export default connect(
  mapStateToProps,
  { getPetitionById, onPetitionHandlerReset },
)(PetitionLoader);
