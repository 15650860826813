import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = {
  root: {
    backgroundColor: 'white',
    paddingBottom: '2rem',
    margin: '2rem 0',
  },
};

const ResumeBlockContainer = props => {
  const { classes } = props;
  return <div className={classes.root}>{props.children}</div>;
};

ResumeBlockContainer.propTypes = {};

export default withStyles(styles)(ResumeBlockContainer);
