import React from 'react';
import Table from '@material-ui/core/Table';
import ProductsTablePetitionHead from '../../molecules/ProductsTablePetitionHead';
import ProductsTablePetitionBody from '../../molecules/ProductsTablePetitionBody';
import { FieldArray, reduxForm } from 'redux-form';
import validate from './validate';
import Paper from "@material-ui/core/Paper";

function ProductsTablePetition(props) {
  const { addProductToPetition, patchProduct, invalid } = props;
  console.log('props', props.initialValues.length);
  return (
    <form style={{ overflowX: 'auto', width: '100%' }}>
      <Table style={{ overflow: 'visible' }}>
        <ProductsTablePetitionHead />
        <FieldArray
          component={ProductsTablePetitionBody}
          props={{ addProductToPetition, patchProduct }}
          name={'data'}
        />
      </Table>
    </form>
  );
}

export default reduxForm({
  form: 'products-petition',
  enableReinitialize: true,
  keepDirtyOnReinitialize: false,
  destroyOnUnmount: false,
  validate
})(ProductsTablePetition);
