import { getLang } from '../util';

export default {
  authReducer: {
    isLoading: false,
    auth: undefined,
    auth_data: null,
    error: null,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    active_stage: {
      username: null,
      email: null,
      password: null,
    },
    recoveredEmail: "",
  },
  localeReducer: {
    lang: getLang(),
    error: null,
  },
  dashboardReducer: {
    name: 'dashboard',
    error: null,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
  },
  petitionReducer: {
    name: 'petition',
    error: null,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    petitions: {
      count: 0
    }
  },
  petitionHandlerReducer: {
    name: 'petitionHandler',
    error: null,
    alert: {
      open: false,
      type: 'error',
      message: '',
    },
    isLoading: false,
    petition: undefined
  },
  dataLoaderReducer: {
    isLoading: false,
    error: null,
    data: {},
    projectCounters: null,
    whoami: null,
    client: null,
    projects: {},
  },
  socketReducer: {
    chats: null,
    error: null,
    petitions: [],
    ws: new WebSocket(`${process.env.REACT_APP_WS_URL}`),
    notification: null
  },
  questionnaireReducer: {

  }
};
