import React from 'react';
import { FormattedMessage } from 'react-intl';

export const BlueSmallButton = ({ id, type }) => (
  <button className="blue-small-btn" type={type}>
    <FormattedMessage id={id} />
  </button>
);

export const BlueBigButton = ({ id, type }) => (
  <button className="blue-big-btn" type={type}>
    <FormattedMessage id={id} />
  </button>
);

export const BlueSmallButtonOutlined = ({ id, type }) => (
  <button className="blue-small-btn-outlined" type={type}>
    <FormattedMessage id={id} />
  </button>
);

export const BlueButton = ({ id, type }) => (
  <button className="blue-btn" type={type}>
    <FormattedMessage id={id} />
  </button>
);

export const CancelButton = ({ id, type }) => (
  <button className="cancel-btn" type={type}>
    <FormattedMessage id={id} />
  </button>
);

export const UniversalButton = ({ id, type, classname }) => (
  <button className={classname} type={type}>
    <FormattedMessage id={id} />
  </button>
);

export default {};
