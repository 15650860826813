import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Company from '../../organisms/Company';
import CompanyEditor from '../../organisms/CompanyEditor';

const CompanyTemplate = ({ client, counters }) => {
  return (
    <Grid container spacing={40}>
      <Grid item xs={12} md={4}>
        <Company client={client} counters={counters} />
      </Grid>
      <Grid item xs={12} md={8}>
        <CompanyEditor initialValues={client} />
      </Grid>
    </Grid>
  );
};

CompanyTemplate.propTypes = {};

export default CompanyTemplate;
