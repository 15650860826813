import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import renderTextField from '../helpers/renderTextField';
import renderCalendar from '../helpers/renderCalendar';
import renderCheckbox from '../helpers/renderCheckbox';

const SamplesForm = ({ classes }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.blockMargin}>
          <div>
            <span className={classes.bold}>
              <FormattedMessage id={'samples_date'} />
            </span>
            <div>
              <Field name={'samples_date'} component={renderCalendar} />
            </div>
          </div>
        </div>
        <div className={classes.blockMargin}>
          <div className={classes.flexCenter}>
            <Field name={'samples_express'} component={renderCheckbox} />
            <FormattedMessage id={'samples_urgent'} />
          </div>
        </div>
        <div className={classes.blockMargin}>
          <div>
              <span className={classes.bold}>
                <FormattedMessage id={'samples_comment'} />
              </span>
            <div>
              <Field
                name={'samples_comment'}
                component={renderTextField}
              />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

SamplesForm.propTypes = {};

export default withStyles(styles)(SamplesForm);
