import TextField from '@material-ui/core/TextField';
import React from 'react';

const renderCalendar = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <TextField
        id="date"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        value={input.value}
        onChange={event => input.onChange(event.target.value)}
      />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export default renderCalendar;