import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const ProfileCounter = ({count, text}) => {
    return (
        <div className={'profile-counter-root'}>
            <span className={'profile-counter-num'}>{count}</span>
            <span className={'profile-counter-text'}>{text}</span>
        </div>
    );
};

ProfileCounter.propTypes = {

};

export default ProfileCounter;