import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import PercentageBlock from '../../../components/PercentageBlock';
import NotificationsSlider from '../../../components/NotificationsSlider';
import ServicesList from '../../../components/ServicesList';
import ProjectsTable from '../../../components/ProjectsTable';
import PageHeader from '../../../components/PageHeader';
import BlueButton from '../../../components/BlueButton';
import Addition from '../components/Addition';
import Calendar from '../components/Calendar';
import { onMountDashboard } from '../../../redux/actions/dashboardActions';

import '../styles.scss';

class DashboardPage extends PureComponent {
  // constructor(props) {
  //   super(props);
  //   this.state = {};
  // }
  componentDidMount() {
    const { onMountDashboard } = this.props;
    onMountDashboard();
    // console.log('this=', this.props);
  }

  render() {
    return (
      <section className="section dashboard-section">
        <PageHeader title="Inicio">
          <BlueButton
            active
            styles={{
              width: '135px',
              height: '45px'
            }}
          >
            Nuevo Estudio
          </BlueButton>
        </PageHeader>
        <div className="dashboard-section-wrapper-table">
          <div className="percentage-info">
            <PercentageBlock
              value={45}
              color="#F66C7D"
              text="№ estudios pendiedntis de inicio"
            />
            <PercentageBlock
              value={32}
              color="#F6DA6E"
              text="№ estudios en curso"
            />
            <PercentageBlock
              value={67}
              color="#4CE1B6"
              text="№ total de estudios realizados"
            />
          </div>
          <NotificationsSlider title="dsfjldksf" desc="fgkljdfklg" />
          <ServicesList />
          <ProjectsTable />
        </div>
        <div className="dashboard-section-wrapper-calendar">
          <Calendar />
          <Addition
            title="CosmoCare"
            text="Expect warmly its tended garden him esteem had remove off. Effects dearest staying now sixteen nor improve."
            btn1="Cancelar"
            btn2="De acuerdo"
            theme="blue"
          />
          <Addition
            title="Consultanos"
            text="Expect warmly its tended garden him esteem had remove off. Effects dearest staying now sixteen nor improve."
            btn1="FaQ"
            btn2="Contractanos"
            theme="white"
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  ...state.dashboard
});

export default connect(
  mapStateToProps,
  { onMountDashboard }
)(DashboardPage);
