import React from 'react';
import PropTypes from 'prop-types';
import ResumeProjectPanel from './ResumeProjectPanel';
import { ResumeProjectProducts } from '../components/Resume/';
import { FormattedMessage } from 'react-intl';
import ResumeProjectType from '../components/Resume/ResumeProjectType';
import { connect } from 'react-redux';
import withResumeProjectPanel from './withResumeProjectPanel';
import { ResumeProjectHeader } from '../components/Resume/';
import { ResumeDocuments } from '../components/Resume/';
import ResumeProjectPrice from '../components/Resume/ResumeProjectPrice';
import Chat from '../views/organisms/Chat';
import ResumePanel from '../components/Resume/ResumePanel';
import ResumeModeOfUse from '../components/Resume/ResumeModeOfUse';
import ResumeClaims from '../components/Resume/ResumeClaims';
import ResumeObservations from '../components/Resume/ResumeObservations';
import ResumeSamples from '../components/Resume/ResumeSamples';
import ResumeInformation from '../components/Resume/ResumeInformation';
import ResumeQuestionnaires from '../components/Resume/ResumeQuestionnaires';
import ResumeProjectPlanning from '../components/Resume/ResumeProjectPlanning';

const ResumeProjectHandler = ({ project, services, index, header }) => {
  console.log('project', project);
  const getElem = () => {
    const projectType = project.project_type
      ? services
          .find(s => s.id === project.service)
          .project_types.find(p => p.id === project.project_type).name
      : '';
    const serviceName = services.find(s => s.id === project.service).name;
    switch (project.service) {
      // Regulatory
      case 1:
        return (
          <>
            {header}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_products'} />,
            )(
              <ResumeProjectProducts
                products={project.products}
                comment={project.comment}
              />,
            )}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_project_type'} />,
            )(
              <ResumeProjectType
                projectType={projectType}
                comment={project.comment}
              />,
            )}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_documents'} />,
            )(
              <ResumeDocuments
                lang={project.language}
                docs={project.attachments}
              />,
            )}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_observation'} />,
            )(<ResumeObservations observations={project.refinements} />)}
            {project.cost &&
              withResumeProjectPanel(
                <FormattedMessage id={'petition_project_price'} />,
              )(<ResumeProjectPrice price={project.cost} />)}
          </>
        );
      // SafetyAndEfficacy
      case 2:
      // Consumers
      case 3:
        return (
          <>
            {header}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_products'} />,
            )(
              <ResumeProjectProducts
                products={project.products}
                comment={project.comment}
              />,
            )}
            {withResumeProjectPanel(<FormattedMessage id={'panel'} />)(
              <ResumePanel project={project} />,
            )}
            {withResumeProjectPanel(<FormattedMessage id={'modeofuse'} />)(
              <ResumeModeOfUse project={project} />,
            )}
            {withResumeProjectPanel(<FormattedMessage id={'samples_form'} />)(
              <ResumeSamples project={project} />,
            )}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_documents'} />,
            )(
              <ResumeDocuments
                lang={project.language}
                docs={project.attachments}
              />,
            )}
            {withResumeProjectPanel(<FormattedMessage id={'claims'} />)(
              <ResumeClaims claims={project.claims} />,
            )}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_observation'} />,
            )(<ResumeObservations observations={project.refinements} />)}
            {Array.isArray(project.questionnaires) &&
              project.questionnaires.length > 0 &&
              withResumeProjectPanel(
                <FormattedMessage id={'petition_project_questionnaire'} />,
              )(
                <ResumeQuestionnaires
                  questionnaires={project.questionnaires}
                  project={project.id}
                />,
              )}
            {project.cost &&
              withResumeProjectPanel(
                <FormattedMessage id={'petition_project_price'} />,
              )(<ResumeProjectPrice price={project.cost} />)}
            {project.report_date &&
              withResumeProjectPanel(
                <FormattedMessage id={'petition_project_planning'} />,
              )(
                <ResumeProjectPlanning
                  startDate={project.start_date}
                  middleDate={project.middle_date}
                  endDate={project.end_date}
                  reportDate={project.report_date}
                />,
              )}
          </>
        );
      // Other services
      case 4:
        return (
          <>
            {header}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_products'} />,
            )(
              <ResumeProjectProducts
                products={project.products}
                comment={project.comment}
              />,
            )}
            {withResumeProjectPanel(<FormattedMessage id={'samples_form'} />)(
              <ResumeSamples project={project} />,
            )}
            {withResumeProjectPanel(
              <FormattedMessage id={'form_information'} />,
            )(<ResumeInformation comment={project.additional_comment} />)}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_documents'} />,
            )(
              <ResumeDocuments
                lang={project.language}
                docs={project.attachments}
              />,
            )}
            {project.cost &&
              withResumeProjectPanel(
                <FormattedMessage id={'petition_project_price'} />,
              )(<ResumeProjectPrice price={project.cost} />)}
          </>
        );
      // Consultation
      case 5:
        return (
          <>
            {header}
            {withResumeProjectPanel(
              <FormattedMessage id={'form_consultation'} />,
            )(<ResumeInformation comment={project.additional_comment} />)}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_products'} />,
            )(
              <ResumeProjectProducts
                products={project.products}
                comment={project.comment}
              />,
            )}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_documents'} />,
            )(
              <ResumeDocuments
                lang={project.language}
                docs={project.attachments}
              />,
            )}
            {project.cost &&
              withResumeProjectPanel(
                <FormattedMessage id={'petition_project_price'} />,
              )(<ResumeProjectPrice price={project.cost} />)}
          </>
        );
      // Security pack
      case 6:
        return (
          <>
            {header}
            {withResumeProjectPanel(
              <FormattedMessage id={'petition_comment'} />,
            )(<ResumeInformation comment={project.additional_comment} />)}
            {project.cost &&
              withResumeProjectPanel(
                <FormattedMessage id={'petition_project_price'} />,
              )(<ResumeProjectPrice price={project.cost} />)}
          </>
        );
    }
  };
  return <div>{getElem()}</div>;
};

ResumeProjectHandler.propTypes = {};

const mapStateToProps = state => ({
  services: state.petition.services,
});

export default connect(mapStateToProps)(ResumeProjectHandler);
