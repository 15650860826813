import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage } from 'react-intl';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core';
import { tableCell } from '../../helpers/styles';

const ProductsTableHead = ({ classes }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'products_id'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'products_product'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'products_ref'} />
        </TableCell>
        {/*<TableCell>*/}
        {/*  <FormattedMessage id={'products_request_date'} />*/}
        {/*</TableCell>*/}
        {/*<TableCell>*/}
        {/*  <FormattedMessage id={'petition_project_status'} />*/}
        {/*</TableCell>*/}
        <TableCell className={classes.tableCell} />
      </TableRow>
    </TableHead>
  );
};

ProductsTableHead.propTypes = {};

export default withStyles({ tableCell })(ProductsTableHead);
