import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import renderTextField from '../helpers/renderTextField';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import RenderCheckboxGroup from '../helpers/renderCheckboxGroup';
import { connect } from 'react-redux';

const ModeOfUseForm = ({ classes, common }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.blockMargin}>
          <div>
            <span className={classes.bold}>
              <FormattedMessage id={'modeofuse_application_area'} />
            </span>
            <div>
              <Field
                name={'application_area'}
                component={RenderCheckboxGroup}
                options={common.preferred_application_zones}
              />
            </div>
          </div>
        </div>
        <div className={classes.blockMargin}>
          <div>
            <span className={classes.bold}>
              <FormattedMessage id={'modeofuse_frequency_of_use'} />
            </span>
            <div>
              <Field name={'frequency_of_use'} component={renderTextField} />
            </div>
          </div>
        </div>
        <div className={classes.blockMargin}>
          <div>
            <span className={classes.bold}>
              <FormattedMessage id={'modeofuse_amount_of_use'} />
            </span>
            <div>
              <Field name={'amount_of_use'} component={renderTextField} />
            </div>
          </div>
        </div>
        <div className={classes.blockMargin}>
          <div>
            <span className={classes.bold}>
              <FormattedMessage id={'modeofuse_refinements_method'} />
            </span>
            <div>
              <Field name={'refinements_method'} component={renderTextField} />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

ModeOfUseForm.propTypes = {};

const mapStateToProps = state => ({
  common: state.dataLoader.common,
});

export default withStyles(styles)(connect(mapStateToProps)(ModeOfUseForm));
