import validator from 'validator';
import _ from 'lodash';
import localization from '../../../../app/localization/valid_localization';

const validate = props => {
  const {
    first_name,
    last_name,
    name,
    address,
    phone,
    email,
    code,
  } = props;

  const services = _.pick(props, ['s1', 's2', 's3', 's4', 's5', 's6']);

  const lang = localStorage.getItem('lang');
  const errors = {};

  if (_.isEmpty(services)) errors.s6 = localization[lang].auth_services;

  if (!name) errors.name = localization[lang].auth_name;
  else if (!validator.isLength(name, { min: 1, max: 30 }))
    errors.name = localization[lang].auth_name;

  if (!address) errors.address = localization[lang].auth_address;
  else if (!validator.isLength(address, { min: 1, max: 30 }))
    errors.address = localization[lang].auth_address;

  if (!code) errors.code = localization[lang].auth_code;
  else if (!validator.isLength(code, { min: 6, max: 30 }))
    errors.code = localization[lang].auth_code;

  if (!first_name) errors.first_name = localization[lang].auth_first_name;
  else if (!validator.isLength(first_name, { min: 1, max: 30 }))
    errors.first_name = localization[lang].auth_first_name;

  if (!last_name) errors.last_name = localization[lang].auth_last_name;
  else if (!validator.isLength(last_name, { min: 1, max: 30 }))
    errors.last_name = localization[lang].auth_last_name;

  if (!phone) errors.phone = localization[lang].auth_phone;
  else if (!validator.isLength(phone, { min: 1, max: 30 }))
    errors.phone = localization[lang].auth_phone;

  if (!email) errors.email = localization[lang].auth_is_email;
  else if (!validator.isEmail(email))
    errors.email = localization[lang].auth_email;

  return errors;
};

export default validate;
