import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '90%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

// const currencies = [
//   {
//     value: 'USD',
//     label: '$',
//   },
//   {
//     value: 'EUR',
//     label: '€',
//   },
//   {
//     value: 'BTC',
//     label: '฿',
//   },
//   {
//     value: 'JPY',
//     label: '¥',
//   },
// ];

class MuiTextField extends React.Component {
  state = {
    name: '',
    // age: '',
    // multiline: 'Controlled',
    // currency: 'EUR',
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes, label, widthFull, width, value, handleChange, name, disabled } = this.props;
    // const { name } = this.state;

    return (
      //   <form className={classes.container} noValidate autoComplete="off">
      <FormControl
        className={classes.formControl}
        style={widthFull ? { width: '100%' } : { width }}
      >
        <label htmlFor="standard-name">{label}</label>
        <TextField disabled={disabled}
          id="standard-name"
          // label={label}
          className={classes.textField}
          value={value}
          onChange={handleChange(name)}
          margin="normal"
        />
      </FormControl>
      //   </form>
    );
  }
}

MuiTextField.propTypes = {
  classes: PropTypes.shape().isRequired,
};

export default withStyles(styles)(MuiTextField);
