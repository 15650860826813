import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { BlueButton, CancelButton } from '../../common/Buttons';
import validate from './validate';
import { styles, styleHelper } from './input_styles';
import {
  renderTextField,
  renderCheckbox
} from '../../../../components/form/renders';

class RegistrationForm extends React.PureComponent {
  render() {
    const { handleSubmit, lang } = this.props;
    const { username, email } = JSON.parse(localStorage.getItem('active_reg'));
    return (
      <form className="reg-form" onSubmit={handleSubmit}>
        <div className="reg-form__greeting">
          <div className="reg-form__greeting-inner">
            <FormattedMessage id="auth_reg_greeting" />
          </div>
          {/*<div>{username},</div>*/}
        </div>
        <div className="reg-form__message">
          <FormattedMessage id="auth_reg_message" />
        </div>

        <div className="reg-form__company-block">
          <div className="reg-form__company">
            <FormattedMessage id="auth_reg_company_name" />
          </div>
          <div className="reg-form__company">
            <Field
              name="name"
              component={renderTextField}
              placeholder="auth_reg_company_name_placeholder"
              lang={lang}
              styles={styles}
            />
          </div>
        </div>

        <div className="reg-form__company-block">
          <div className="reg-form__company">
            <FormattedMessage id="auth_reg_company_address" />
          </div>
          <div className="reg-form__company">
            <Field
              name="address"
              component={renderTextField}
              placeholder="auth_reg_company_address_placeholder"
              lang={lang}
              styles={styles}
            />
          </div>
        </div>

        <div className="reg-form__company-block">
          <div className="reg-form__company">
            <FormattedMessage id="auth_reg_company_number" />
          </div>
          <div className="reg-form__company">
            <Field
              name="code"
              component={renderTextField}
              placeholder="auth_reg_company_number_placeholder"
              lang={lang}
              styles={styles}
            />
          </div>
        </div>

        <div className="reg-form__company-block">
          <div className="reg-form__contact-person">
            <FormattedMessage id="auth_reg_contact_person" />
          </div>
        </div>

        <div className="reg-form__company-block">
          <div className="reg-form__company">
            <FormattedMessage id="auth_reg_first_name" />
          </div>
          <div className="reg-form__company">
            <Field
              name="first_name"
              component={renderTextField}
              placeholder="auth_reg_first_name_placeholder"
              lang={lang}
              styles={styles}
            />
          </div>
        </div>

        <div className="reg-form__company-block">
          <div className="reg-form__company">
            <FormattedMessage id="auth_reg_second_name" />
          </div>
          <div className="reg-form__company">
            <Field
              name="last_name"
              component={renderTextField}
              placeholder="auth_reg_second_name_placeholder"
              lang={lang}
              styles={styles}
            />
          </div>
        </div>

        <div className="reg-form__company-block">
          <div className="reg-form__company">
            <FormattedMessage id="auth_reg_last_name" />
          </div>
          <div className="reg-form__company">
            <Field
              name="second_last_name"
              component={renderTextField}
              placeholder="auth_reg_last_name_placeholder"
              lang={lang}
              styles={styles}
            />
          </div>
        </div>

        <div className="reg-form__company-block">
          <div className="reg-form__company">
            <FormattedMessage id="auth_reg_email" />
          </div>
          <div className="reg-form__company">
            <Field
              name="email"
              component={renderTextField}
              placeholder="auth_reg_email_placeholder"
              lang={lang}
              styles={styles}
            />
          </div>
        </div>

        <div
          className="reg-form__company-block reg-form__phone"
          style={{ marginBottom: '86px' }}
        >
          <div className="reg-form__company">
            <FormattedMessage id="auth_reg_phone" />
          </div>
          <div className="reg-form__company">
            <Field
              name="phone"
              component={renderTextField}
              placeholder="auth_reg_phone_placeholder"
              lang={lang}
              styles={styles}
            />
          </div>
        </div>

        <div className="reg-form__company-block">
          <div className="reg-form__company">
            <FormattedMessage id="auth_reg_url" />
          </div>
          <div className="reg-form__company">
            <Field
              name="url"
              component={renderTextField}
              placeholder="auth_reg_url_placeholder"
              lang={lang}
              styles={styles}
            />
          </div>
        </div>

        <div className="reg-form__company-services">
          <div className="reg-form__company reg-form__rervice-question">
            <FormattedMessage id="auth_reg_services" />
          </div>
          <div className="reg-form__services">
            <div className="reg-form__services-block">
              <div>
                <Field
                  name="s1"
                  component={renderCheckbox}
                  value="checkbox1"
                  style={styleHelper}
                />
              </div>

              <div className="reg-form__company">
                <FormattedMessage id="auth_reg_service_1" />
              </div>
            </div>
            <div className="reg-form__services-block">
              <div>
                <Field
                  name="s2"
                  component={renderCheckbox}
                  value="checkbox2"
                  style={styleHelper}
                />
              </div>

              <div className="reg-form__company">
                <FormattedMessage id="auth_reg_service_2" />
              </div>
            </div>
            <div className="reg-form__services-block">
              <div>
                <Field
                  name="s3"
                  component={renderCheckbox}
                  value="checkbox3"
                  style={styleHelper}
                />
              </div>
              <div className="reg-form__company">
                <FormattedMessage id="auth_reg_service_3" />
              </div>
            </div>

            <div className="reg-form__services-block">
              <div>
                <Field
                  name="s4"
                  component={renderCheckbox}
                  value="checkbox4"
                  style={styleHelper}
                />
              </div>
              <div className="reg-form__company">
                <FormattedMessage id="auth_reg_service_4" />
              </div>
            </div>
            <div className="reg-form__services-block">
              <div>
                <Field
                  name="s5"
                  component={renderCheckbox}
                  value="checkbox5"
                  style={styleHelper}
                />
              </div>
              <div className="reg-form__company">
                <FormattedMessage id="auth_reg_service_5" />
              </div>
            </div>
            <div
              className="reg-form__services-block"
              style={{ marginBottom: '113px' }}
            >
              <div>
                <Field
                  name="s6"
                  component={renderCheckbox}
                  value="checkbox6"
                  style={styleHelper}
                />
              </div>
              <div className="reg-form__company">
                <FormattedMessage id="auth_reg_service_6" />
              </div>
            </div>
            <div
              className="reg-form__services-block"
              style={{ marginBottom: '98px' }}
            >
              <div className="reg-form__reg-btn-wrapper">
                <BlueButton id="auth_reg_btn_submit" type="submit" />
              </div>
              <div className="reg-form__reg-btn-wrapper">
                <CancelButton id="auth_reg_btn_cancel" type="button" />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.locale.lang
});

RegistrationForm = reduxForm({
  form: 'registration',
  validate
})(RegistrationForm);

RegistrationForm = connect(mapStateToProps, {change})(RegistrationForm);

export default RegistrationForm;
