export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SECCESS = 'AUTH_LOGOUT_SECCESS';
export const AUTH_SET_CURRENT_USER_REQUEST = 'AUTH_SET_CURRENT_USER_REQUEST';
export const AUTH_SET_CURRENT_USER_SUCCESS = 'AUTH_SET_CURRENT_USER_SECCESS';
export const AUTH_NO_DATA = 'AUTH_NO_DATA';
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const SET_RECOVERED_EMAIL = "SET_RECOVERED_EMAIL";

export const AUTH_GET_ERROR = 'AUTH_GET_ERROR ';

export const GET_COMMON_REQUEST = 'GET_COMMON_REQUEST';

export const GET_LANG_REQUEST = 'GET_LANG_REQUEST';
export const GET_LANG_SUCCESS = 'GET_LANG_SUCCESS';
export const SET_LANG_REQUEST = 'SET_LANG_REQUEST';
export const SET_LANG_SUCCESS = 'SET_LANG_SUCCESS';
export const GET_LANG_ERROR = 'GET_LANG_ERROR';
export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';

export const CLOSE_AUTH_ALERT_REQUEST = 'CLOSE_AUTH_ALERT_REQUEST';
export const CLOSE_AUTH_ALERT_SUCCESS = 'CLOSE_AUTH_ALERT_SUCCESS';

export const AUTH_GO_TO = 'AUTH_GO_TO';

export const DASHBOARD_ONMOUNT = 'DASHBOARD_ONMOUNT';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';

export const PETITION_ONMOUNT = 'PETITION_ONMOUNT';
export const PETITION_ONMOUNT_SUCCESS = 'PETITION_ONMOUNT_SUCCESS';
export const PETITION_CREATE = 'PETITION_CREATE';
export const PETITION_CREATE_SUCCESS = 'PETITION_CREATE_SUCCESS';
export const PETITION_ERROR = 'PETITION_ERROR';
export const PETITION_ADD_PRODUCT = 'PETITION_ADD_PRODUCT';
export const PETITION_PUSH_PRODUCT = 'PETITION_PUSH_PRODUCT';
export const PETITION_CREATE_PROJECT = 'PETITION_CREATE_PROJECT';
export const PETITION_PUSH_PROJECT = 'PETITION_PUSH_PROJECT';
export const PETITION_GET_SERVICES = 'PETITION_GET_SERVICES';
export const PETITION_SET_SERVICES = 'PETITION_SET_SERVICES';
export const PETITION_DELETE = 'PETITION_DELETE';

export const PETITION_HANDLER_GET_BY_ID = 'PETITION_HANDLER_GET_BY_ID';
export const PETITION_HANDLER_SET_CURRENT_PETITION = 'PETITION_HANDLER_SET_CURRENT_PETITION';
export const PETITION_HANDLER_PATCH_PROJECT = 'PETITION_HANDLER_PATCH_PROJECT';
export const PETITION_HANDLER_PATCH_PROJECT_IN_STATE = 'PETITION_HANDLER_PATCH_PROJECT_IN_STATE';
export const ATTACH_FILES_TO_PROJECT = 'ATTACH_FILES_TO_PROJECT';
export const BIND_FILE_TO_PROJECT = 'BIND_FILE_TO_PROJECT';
export const PETITION_VALIDATE = 'PETITION_VALIDATE';
export const PETITION_HANDLER_ERROR = 'PETITION_HANDLER_ERROR';
export const PETITION_HANDLER_RESET = 'PETITION_HANDLER_RESET';
export const PETITION_PATCH_PRODUCT = 'PETITION_PATCH_PRODUCT';
export const PETITION_STATE_PATCH_PRODUCT = 'PETITION_STATE_PATCH_PRODUCT';
export const PETITION_HANDLER_DELETE_PROJECT = 'PETITION_HANDLER_DELETE_PROJECT';
export const PETITION_HANDLER_DELETE_PROJECT_FROM_STATE = 'PETITION_HANDLER_DELETE_PROJECT_FROM_STATE';
export const PETITION_HANDLER_PATCH_ATTACHMENT = "PETITION_HANDLER_PATCH_ATTACHMENT";
export const PETITION_HANDLER_DELETE_FILE_FROM_PROJECT = "PETITION_HANDLER_DELETE_FILE_FROM_PROJECT";
export const PETITION_CREATE_DRAFT = "PETITION_CREATE_DRAFT";
export const PETITION_CLEAR = "PETITION_CLEAR";

export const GET_DATA_BY_NAME = 'GET_DATA_BY_NAME';
export const SET_DATA_BY_NAME = 'SET_DATA_BY_NAME';
export const SET_DATA_ERROR = 'SET_DATA_ERROR';
export const CLEAR_DATA_ERROR = 'CLEAR_DATA_ERROR';

export const GET_PROJECT_COUNTERS = 'GET_PROJECT_COUNTERS';
export const SET_PROJECT_COUNTERS = 'SET_PROJECT_COUNTERS';

export const GET_WHOAMI = 'GET_WHOAMI';
export const SET_WHOAMI = 'SET_WHOAMI';

export const SOCKET_ON_MESSAGE = 'SOCKET_ON_MESSAGE';
export const SOCKET_PUSH_MESSAGE = 'SOCKET_PUSH_MESSAGE';
export const SOCKET_SEND_TOKEN = 'SOCKET_SEND_TOKEN';
export const SOCKET_SEND_MESSAGE = 'SOCKET_SEND_MESSAGE';
export const SOCKET_ERROR = 'SOCKET_ERROR';
export const SOCKET_GET_MESSAGES = 'SOCKET_GET_MESSAGES';
export const SOCKET_SET_CHAT_BY_ID = 'SOCKET_SET_CHAT_BY_ID';
export const SOCKET_NEW_MESSAGE = 'SOCKET_NEW_MESSAGE';
export const SOCKET_CLEAR_NOTIFICATION = 'SOCKET_CLEAR_NOTIFICATION';
export const SOCKET_CLEAR_ERROR = 'SOCKET_CLEAR_ERROR';
export const SOCKET_ATTACH_FILE_TO_CHAT = 'SOCKET_ATTACH_FILE_TO_CHAT';

export const PATCH_PROFILE = 'PATCH_PROFILE';
export const PATCH_CLIENT = 'PATCH_CLIENT';
export const GET_CS_PROFILE = 'GET_CS_PROFILE';
export const SET_CLIENT = 'SET_CLIENT';

export const GET_PROJECT_BY_ID = 'GET_PROJECT_BY_ID';
export const SET_PROJECT_BY_ID = 'SET_PROJECT_BY_ID';

export const SET_SERVICES = 'SET_SERVICES';

export const GET_COMMON = "GET_COMMON";
export const SET_COMMON = "SET_COMMON";