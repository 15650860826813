import { all, take, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { reset } from 'redux-form';
import localforage from 'localforage';
import { axios, prepareData, setAuth } from '../util';
import {
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SECCESS,
  AUTH_SET_CURRENT_USER_REQUEST,
  AUTH_GET_ERROR,
  CLOSE_AUTH_ALERT_REQUEST,
  CLOSE_AUTH_ALERT_SUCCESS,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_SUCCESS,
  AUTH_GO_TO,
  AUTH_NO_DATA,
  RECOVER_PASSWORD, SET_RECOVERED_EMAIL,
} from '../types';
import errors from '../../app/localization/errors';

// ///////////
// actions //
// ///////////

export const register = values => ({
  type: AUTH_REGISTER_REQUEST,
  payload: values,
});

export const login = values => ({
  type: AUTH_LOGIN_REQUEST,
  payload: values,
});

export const logout = () => ({
  type: AUTH_LOGOUT_REQUEST,
});

export const setCurrentUser = () => ({
  type: AUTH_SET_CURRENT_USER_REQUEST,
});

export const checkEmail = values => ({
  type: CHECK_EMAIL_REQUEST,
  payload: values,
});

export const closeAuthAlert = () => ({
  type: CLOSE_AUTH_ALERT_REQUEST,
});

export const authGoTo = route => ({
  type: AUTH_GO_TO,
  payload: route,
});

export const recoverPassword = values => ({
  type: RECOVER_PASSWORD,
  payload: values,
});

// ///////////
// sagas ////
// ///////////

const registerSaga = function*() {
  while (true) {
    try {
      const action = yield take(AUTH_REGISTER_REQUEST);
      const data = yield prepareData(action.payload);
      const result = yield axios.post('auth/signup/', data);
      yield put({
        type: AUTH_REGISTER_SUCCESS,
        payload: result.data,
      });
      yield setAuth(result.data);
      yield put(reset('registration'));
      // yield put(push('/reg/final-registration'));
      yield put(push('/'));
    } catch (error) {
      const lang = yield localStorage.getItem('lang');
      yield put({
        type: AUTH_GET_ERROR,
        message: errors[lang].auth_register, // return always one message "Email exists"
        error,
      });
    }
  }
};

const loginSaga = function*() {
  while (true) {
    try {
      const action = yield take(AUTH_LOGIN_REQUEST);
      const result = yield axios.post('auth/login/', action.payload);
      yield localforage.setItem('auth', result.data);
      yield setAuth(result.data);
      yield put({ type: AUTH_LOGIN_SUCCESS, payload: result.data });
      yield put(reset('login'));
      yield put(push('/'));
    } catch (error) {
      const lang = yield localStorage.getItem('lang');
      yield put({
        type: AUTH_GET_ERROR,
        message: errors[lang].auth_login,
        error,
      });
      // yield put(reset('login'));
    }
  }
};

const setCurrentUserSaga = function*() {
  while (true) {
    try {
      yield take(AUTH_SET_CURRENT_USER_REQUEST);
      const result = yield localforage.getItem('auth');
      if (result !== null) {
        yield put({ type: AUTH_LOGIN_SUCCESS, payload: result });
        // yield put(push('/'));
      } else {
        yield put({
          type: AUTH_NO_DATA,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const logoutSaga = function*() {
  while (true) {
    try {
      yield take(AUTH_LOGOUT_REQUEST);
      yield localforage.removeItem('auth');
      yield put({
        type: AUTH_LOGOUT_SECCESS,
      });
    } catch (error) {
      console.log(error);
    }
  }
};

const checkEmailSaga = function*() {
  while (true) {
    try {
      const action = yield take(CHECK_EMAIL_REQUEST);
      const { email, username, password } = action.payload;
      yield axios.post('/auth/check_email/', { email });

      yield put({
        type: CHECK_EMAIL_SUCCESS,
        payload: { email, username, password },
      });
      yield localStorage.setItem('active_reg', JSON.stringify(action.payload));
      yield put(push('/reg/registration'));
    } catch (error) {
      const lang = yield localStorage.getItem('lang');
      yield put({
        type: AUTH_GET_ERROR,
        message: errors[lang].auth_register,
        error,
      });
      // yield put(reset('sign_up'));
    }
  }
};

const closeAuthAlertSaga = function*() {
  while (true) {
    try {
      yield take(CLOSE_AUTH_ALERT_REQUEST);
      yield put({ type: CLOSE_AUTH_ALERT_SUCCESS, payload: {} });
    } catch (err) {
      console.info(err);
    }
  }
};

const authGoToSaga = function*() {
  while (true) {
    try {
      const action = yield take(AUTH_GO_TO);
      yield put(push(action.payload));
    } catch (error) {
      yield put({ type: AUTH_GET_ERROR, error });
    }
  }
};

const recoverPasswordSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(RECOVER_PASSWORD);
      const { data } = yield axios.post(
        `${process.env.REACT_APP_API_URL}auth/password_recovery_request/`,
        {
          email: payload.email,
        },
      );
      if (data[0]) {
        yield put({
          type: SET_RECOVERED_EMAIL,
          payload: {
            email: payload.email,
          },
        });
        yield put(push('/auth/password-recovered'));
      }
    } catch (e) {}
  }
};

export default function*() {
  yield all([
    registerSaga(),
    loginSaga(),
    closeAuthAlertSaga(),
    authGoToSaga(),
    checkEmailSaga(),
    logoutSaga(),
    setCurrentUserSaga(),
    recoverPasswordSaga(),
  ]);
}
