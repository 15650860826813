import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const ResumePanel = ({ classes, project, common }) => {
  if (!common) {
    return null;
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={3}>
          <div className={classes.verticalFlex}>
            <p className={classes.volunteersNumber}>
              {project.volunteers_number}
            </p>
            <p className={classes.volunteers}>
              <FormattedMessage id={'panel_volunteers'} />
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.verticalFlex}>
            <p className={classes.volunteersNumber}>
              De {project.age_start} a {project.age_end} años
            </p>
            <p className={classes.volunteers}>
              <FormattedMessage id={'panel_age_resume'} />
            </p>
          </div>
        </Grid>
        {project.gender === 'female' && (
          <Grid item xs={12} md={3}>
            <div className={classes.verticalFlex}>
              <p className={classes.volunteersNumber}>100%</p>
              <p className={classes.volunteers}>
                <FormattedMessage id={'panel_sex_female'} />
              </p>
            </div>
          </Grid>
        )}
        {project.gender === 'male' && (
          <Grid item xs={12} md={3}>
            <div className={classes.verticalFlex}>
              <p className={classes.volunteersNumber}>100%</p>
              <p className={classes.volunteers}>
                <FormattedMessage id={'panel_sex_male'} />
              </p>
            </div>
          </Grid>
        )}
        {project.gender === 'all' && project.gender_settings_value && (
          <Grid item xs={12} md={3}>
            <div className={classes.verticalFlex}>
              <p className={classes.volunteersNumber}>
                {project.gender_settings_how} {project.gender_settings_value}%
              </p>
              <p className={classes.volunteers}>
                <FormattedMessage
                  id={`panel_sex_${project.gender_settings_who}`}
                />
              </p>
            </div>
          </Grid>
        )}
        <Grid item xs={12} />
        <Grid item xs={12} md={4}>
          <p className={classes.panelHeader}>
            <FormattedMessage id={'physical_characteristics'} />
          </p>
          <div>
            <b>
              <FormattedMessage id={'panel_body_skin_type'} />
              {': '}
            </b>
            {!project.body_skin_type ||
              (project.body_skin_type.length === 0 && (
                <FormattedMessage id={'indifferent'} />
              ))}
            {Array.isArray(project.body_skin_type) &&
              project.body_skin_type.map(type => (
                <span>
                  {common.body_skin_nature.find(i => i.id === type).value}
                  {' / '}
                </span>
              ))}
          </div>
          <div>
            <b>
              <FormattedMessage id={'panel_face_skin_type'} />
              {': '}
            </b>
            {!project.face_skin_type ||
              (project.face_skin_type.length === 0 && (
                <FormattedMessage id={'indifferent'} />
              ))}
            {Array.isArray(project.face_skin_type) &&
              project.face_skin_type.map(type => (
                <span>
                  {common.skin_nature.find(i => i.id === type).value}
                  {' / '}
                </span>
              ))}
          </div>
          <div>
            <b>
              <FormattedMessage id={'panel_hair_type'} />
              {': '}
            </b>
            {!project.hair_type ||
              (project.hair_type.length === 0 && (
                <FormattedMessage id={'indifferent'} />
              ))}
            {Array.isArray(project.hair_type) &&
              project.hair_type.map(type => (
                <span>
                  {common.hair_type.find(i => i.id === type).value}
                  {' / '}
                </span>
              ))}
          </div>
          <div>
            <b>
              <FormattedMessage id={'panel_scalp_type'} />
              {': '}
            </b>
            {!project.head_skin_type ||
              (project.head_skin_type.length === 0 && (
                <FormattedMessage id={'indifferent'} />
              ))}
            {Array.isArray(project.head_skin_type) &&
              project.head_skin_type.map(type => (
                <span>
                  {common.hair_and_scalp_nature.find(i => i.id === type).value}
                  {' / '}
                </span>
              ))}
          </div>
          <div>
            <b>
              <FormattedMessage id={'panel_atopy'} />
              {': '}
            </b>
            {project.atopy_value}
            <span>{'%'}</span>
          </div>
          <div>
            {(project.sensitive_face_skin_value ||
              project.sensitive_body_skin_value) && (
              <>
                <b>
                  <FormattedMessage id={'panel_sensitivity'} />{' '}
                </b>
                {project.sensitive_face_skin_value && (
                  <>
                    <span>
                      <FormattedMessage id={'panel_sensitive_face_skin'} />
                    </span>
                    <span> {project.sensitive_face_skin_type_value} </span>
                    <span> {project.sensitive_face_skin_value}% </span>
                  </>
                )}
                {project.sensitive_body_skin_value && (
                  <>
                    <span>
                      <FormattedMessage id={'panel_sensitive_body_skin'} />
                    </span>
                    <span> {project.sensitive_body_skin_type_value} </span>
                    <span> {project.sensitive_body_skin_value}% </span>
                  </>
                )}
              </>
            )}
          </div>
          <div>
            <b>
              <FormattedMessage id={'panel_skin_phototype'} />
            </b>{' '}
            {project.skin_phototype_1 && <span>1 / </span>}
            {project.skin_phototype_2 && <span>2 / </span>}
            {project.skin_phototype_3 && <span>3 / </span>}
            {project.skin_phototype_4 && <span>4</span>}
          </div>
          <div>
            <b>
              <FormattedMessage id={'panel_eyes_type'} />
            </b>
            <span> {project.sensitive_eyes_type_value} </span>
            <span> {project.sensitive_eyes_value}% </span>
          </div>
          <div>
            <b>
              <FormattedMessage id={'panel_contact_lenses'} />
            </b>
            <span> {project.contact_lenses_type_value} </span>
            <span> {project.contact_lenses_value}% </span>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <p className={classes.panelHeader}>
            <FormattedMessage id={'panel_other_characteristics'} />
          </p>
          <div>{project.other_characteristics}</div>
        </Grid>
        <Grid item xs={12} md={4}>
          <p className={classes.panelHeader}>
            <FormattedMessage id={'panel_additional_observations'} />
          </p>
          <div>{project.additional_observations}</div>
        </Grid>
      </Grid>
    </>
  );
};

ResumePanel.propTypes = {};

const mapStateToProps = state => ({
  common: state.dataLoader.common,
});

export default withStyles(styles)(connect(mapStateToProps)(ResumePanel));
