import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core";

const styles = {
    form: {
        borderLeft: '1px solid #e0e0e0',
        margin: '2rem 0',
        paddingLeft: '2rem'
    },
    root: {
        backgroundColor: 'white',
        margin: '2rem 0',
        minHeight: '20vh'
    }
};

const ProjectEditorBlock = ({header, form, classes}) => {
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={3}>
                    {header}
                </Grid>
                <Grid className={classes.form} item xs={12} md={9}>
                    {form}
                </Grid>
            </Grid>
        </div>
    );
};

ProjectEditorBlock.propTypes = {

};

export default withStyles(styles)(ProjectEditorBlock);