import React from 'react';
import { Link } from 'react-router-dom';
import CallIcon from '@material-ui/icons/Call';
import IconButton from '@material-ui/core/IconButton';
import logoSvg from '../../../assets/images/svg/prof-admin-logo.svg';

const RegHeader = () => (
  <section className="reg-header">
    <Link className="reg-header__admin-logo" to="/">
      <img className="reg-header__logo" src={logoSvg} alt="CosmoPicture" />
    </Link>
    <div className="reg-header__call">
      <IconButton>
        <CallIcon />
      </IconButton>
    </div>
  </section>
);

export default RegHeader;
