import React, {Component} from 'react';
import './styles.scss';

class Index extends Component{

    render() {
        const p = this.props;

        let {handleClick} = this.props;

        let cl = p.active ? 'blue-btn active' : 'blue-btn';
        return(
            <button className={cl}
                    onClick={handleClick}
                    style={p.styles}
                    type={p.type}
            >
                {p.children}
            </button>
        )
    }

}

export default Index;