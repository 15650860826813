import React, {Component} from 'react';
import ReactDataGrid from 'react-data-grid';
import SearchIcon from '@material-ui/icons/Search';
import {withStyles} from "@material-ui/core";
import './styles.scss';


const styles = {
    searchIcon: {
        fill: '#555555',
        fontWeight: 100,
        fontSize: '18px',
        position: 'absolute',
        right: '5px'
    }
};

class ProjectsTable extends Component{

    render(){

        const {classes} = this.props;

        const columns = [
            { key: 'id', name: 'Nº (ID) petición' },
            { key: 'product_name', name: 'Producto' },
            { key: 'ref_formula', name: 'Ref. Formula' },
            { key: 'project_number', name: '№ Proyecto' },
            { key: 'service_type', name: 'Tipo de servicio' },
            { key: 'project_type', name: 'Tipo de proyecto' },
            { key: 'validate_date', name: 'Fecha validacion' },
            { key: 'samples_status', name: 'Estado de las muestras' },
            { key: 'form_status', name: 'Estado de cuestionario' },
            { key: 'study_status', name: 'Estado del estudio' },
            { key: 'check_studio', name: 'Ver proyecto' }

        ];

        const rows = [];

        for (let i = 0; i < 23; i++) {
            rows.push(
                {
                    id: i,
                    product_name: 'Mousse intima eroski',
                    ref_formula: 'RC13002PENTA',
                    project_number: 'CC18002',
                    service_type: 'Regulatorio',
                    project_type: 'Val. eti.',
                    validate_date: '12.03.2018',
                    samples_status: 'Recibida',
                    form_status: 'Validado',
                    study_status: 'Finalizado',
                    check_studio: 'ver peticion',
                }
            );
        }


        return(
            <div className={'projects-table projects'}>
                <h5>listado de proyectos</h5>
                <div className={'projects-table-options'}>
                    <div className={'items-per-page'}>
                        <span>Mostrar</span>
                        <input type={'number'} name={'items_per_page'} id={'items_per_page'} placeholder={'10'} />
                        <span>entradas</span>
                    </div>
                    <div className={'table-search'}>
                        <input type={'text'} name={'search_field'} placeholder={'Buscar...'} />
                        <SearchIcon className={classes.searchIcon} />
                    </div>
                </div>
                <ReactDataGrid
                    pagination={true}
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={470}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ProjectsTable);