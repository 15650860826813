import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = {
  name: {
    textTransform: 'uppercase',
    fontSize: '3rem',
  },
  service: {
    fontWeight: 'bold',
  },
  border: {
    borderRight: '1px solid black',
  },
  projectType: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    marginLeft: '1.2rem',
  },
};

const ProjectHeader = ({ name, service, projectType, classes }) => {
  return (
    <div>
      <Grid container alignItems={"flex-end"}>
        <Grid item xs={12} md={6} className={classes.border}>
          <span className={classes.name}>
            {name}
            {': '}
          </span>
          <span className={classNames(classes.name, classes.service)}>
            {service}
          </span>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.projectType}>{projectType}</div>
        </Grid>
      </Grid>
    </div>
  );
};

ProjectHeader.propTypes = {
  name: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  projectType: PropTypes.string.isRequired,
};

export default withStyles(styles)(ProjectHeader);
