import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import renderTextField from '../helpers/renderTextField';
// import renderMultiSelect from '../helpers/renderMultiSelect';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import MultipleSelect from "../components/MultipleSelect/MultipleSelect";

const styles = {
  bold: {
    fontWeight: 'bold',
  },
};

const ProductsForm = props => {
  console.log('props', props);
  const { classes, products, allProducts } = props;

  const renderMultiSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => {
    console.log('input', input);
    return (<MultipleSelect input={input} data={allProducts} selectedProducts={input.value ? input.value : products}/>);
  };

  return (
    <div>
      <div>
        <span className={classes.bold}>
          <FormattedMessage id={'petition_comments'} />
        </span>
      </div>
      <Field name="comment" component={renderTextField} />
      <div>
        <Field name="products" component={renderMultiSelect}/>
      </div>
    </div>
  );
};

ProductsForm.propTypes = {};

export default withStyles(styles)(ProductsForm);
