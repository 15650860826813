import React from 'react';
import ProjectsTemplate from '../../templates/ProjectsTemplate';
import Loader from '../../organisms/Loader';
import withData from '../../HOC/WithData';

function ProjectsPage(props) {
  const {data} = props;

  return (
      <>
        {!data.projects && <Loader/>}
        {data.projects && <ProjectsTemplate projects={data.projects}/>}
      </>
  );
}

ProjectsPage.propTypes = {};

export default withData(ProjectsPage, 'projects');
