import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PageHeader from '../../../components/PageHeader';
import Notification from '../../organisms/Notification';
import { Divider } from '@material-ui/core';

const NotificationsTemplate = ({ notifications }) => {
  return (
    <>
      <PageHeader
        title={<FormattedMessage id={'navigation_notifications'} />}
      />
      {notifications &&
        notifications.results &&
        notifications.results.map(notification => (
          <>
            <Notification key={notification.id} notification={notification} />
            <Divider />
          </>
        ))}
    </>
  );
};

NotificationsTemplate.propTypes = {};

export default NotificationsTemplate;
