import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import './styles.scss';
import { connect } from 'react-redux';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { FormattedRelative } from 'react-intl';

const ChatMessage = ({ message, whoami }) => {
  const date = new Date(message.created);
  const id = whoami ? whoami.id : -1;
  let avatar = null;
  let rootClasses = 'chat-message-root';
  let textClasses = 'chat-message-text';
  let createdClasses = 'chat-message-created';
  if (id === message.sender) {
    avatar = <Avatar>{whoami && whoami.last_name[0]}</Avatar>;
    rootClasses += ' chat-message-flex-start';
    textClasses += ' chat-message-client-text';
    createdClasses += ' chat-message-client';
  } else {
    avatar = (
      <Avatar style={{ order: 1 }}>A</Avatar>
    );
    rootClasses += ' chat-message-flex-end';
    textClasses += ' chat-message-guest-text';
    createdClasses += ' chat-message-guest';
  }
  const created = (
    <div className={createdClasses}>
      <FormattedRelative value={message.created} />
    </div>
  );
  return (
    <>
      {message.message_type === 'text' && (
        <div className={rootClasses}>
          {avatar}
          <span className={textClasses}>
            {created}
            {message.text}
          </span>
        </div>
      )}
      {message.message_type === 'attachments' && (
        <div className={rootClasses}>
          {avatar}
          <span className={textClasses}>
            {created}
            {message.attachments.map(attachment => (
              <span key={attachment.id}>
                <AttachmentIcon />
                <a href={`${process.env.REACT_APP_API_URL}${attachment.url}`}>
                  {attachment.name}
                </a>
              </span>
            ))}
          </span>
        </div>
      )}
    </>
  );
};

ChatMessage.propTypes = {};

const mapStateToProps = state => ({
  whoami: state.dataLoader.whoami,
});

export default connect(
  mapStateToProps,
  null,
)(ChatMessage);
