import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import PercentageBlock from '../../../components/PercentageBlock';
import NotificationsSlider from '../../../components/NotificationsSlider';
import './home-template.scss';
import { FormattedMessage } from 'react-intl';
import ProjectsTableHead from '../../molecules/ProjectsTableHead';
import ProjectsTableBody from '../../molecules/ProjectsTableBody';
import DataTable from '../../organisms/DataTable/data-table';
import ServicesList from '../../../components/ServicesList';
import HomeGreeting from "../../organisms/HomeGreeting";
import PageHeader from "../../../components/PageHeader";

const HomeTemplate = ({ counters, projects }) => {
  const countersSum = counters
    ? counters.vc_finding + counters.in_progress + counters.done
    : 0;
  console.log('countersSum', countersSum);
  return (
    <>
      <PageHeader title={<FormattedMessage id={'navigation_home'} />} />
      <Grid container spacing={16} alignItems="stretch" style={{width: "100%"}}>
        <Grid item xs={12} lg={3}>
            {counters && (
              <>
                <PercentageBlock
                  text={<FormattedMessage id={'home_projects_vc_finding'} />}
                  value={Math.round((counters.vc_finding / countersSum) * 100)}
                  color="#F66C7D"
                  counter={counters.vc_finding}
                />
                <PercentageBlock
                  text={<FormattedMessage id={'home_projects_in_progress'} />}
                  value={Math.round((counters.in_progress / countersSum) * 100)}
                  color="#F6DA6E"
                  counter={counters.in_progress}
                />
                <PercentageBlock
                  text={<FormattedMessage id={'home_projects_done'} />}
                  value={Math.round((counters.done / countersSum) * 100)}
                  color="#4CE1B6"
                  counter={counters.done}
                />
              </>
            )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <HomeGreeting/>
            {/*<NotificationsSlider title="dsfjldksf" desc="fgkljdfklg" />*/}
        </Grid>
        <Grid item xs={12} lg={3}>
          <div className={'home-template-grid_margin'}>
            <ServicesList />
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {projects && (
            <DataTable
              head={<ProjectsTableHead />}
              body={<ProjectsTableBody data={projects.results} />}
              count={projects.count}
              name={'projects'}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

HomeTemplate.propTypes = {};

export default HomeTemplate;
