import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cosmocare from '../../../assets/images/svg/cosmocare.svg';
import './style.scss';

const AuthLoader = () => {
  return (
    <Grid container direction="row" justify="center" alignItems="center" className={'auth-loader-block'}>
        <div>
          <img
            className={'auth-loader-img'}
            src={Cosmocare}
            alt="cosmocare logo"
          />
        </div>
          <CircularProgress />
    </Grid>
  );
};

export default AuthLoader;
