export const styles = {
  paddingLeft: '10px',
  paddingRight: '10px',
  width: '350px',
  height: '32px',
  color: '#999999',
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Roboto',
  background: '#f2f4f7'
};

export const styleHelper = {
  position: 'absolute',
  top: 50,
  width: '300px'
};

export default {};
