export default {
  en: {
    auth_is_email: 'Email field shouldn’t be empty',
    auth_email: 'Email is not valid`',
    auth_is_password: 'Password field is required',
    auth_password_length: 'Password is not valid',
    auth_is_username: 'Name field is required',
    auth_username_length: 'Name is not valid',
    auth_first_name: 'First name is not valid',
    auth_last_name: 'Last name is not valid',
    auth_socond_last_name: 'Surname is not valid',
    auth_name: 'Company name is not valid',
    auth_address: 'Address is not valid',
    auth_phone: 'Phone number is not valid',
    auth_code: 'Code is not valid',
    auth_url: 'URL is not valid',
    auth_services: 'You must choose at least one'
  },
  es: {
    auth_is_email: 'El campo de correo electrónico no debe estar vacío',
    auth_email: `El correo no es válido`,
    auth_is_password: 'Campo de contraseña es obligatorio',
    auth_password_length: 'La contraseña no es válida',
    auth_is_username: 'El campo de nombre es obligatorio',
    auth_username_length: 'El nombre no es valido',
    auth_first_name: 'El nombre no es válido',
    auth_last_name: 'El apellido no es válido',
    auth_socond_last_name: 'El apellido no es válido',
    auth_name: 'El nombre de la empresa no es válido',
    auth_address: 'La dirección no es válida',
    auth_phone: 'El número de teléfono no es válido',
    auth_code: 'El código no es válido',
    auth_url: 'La URL no es válida',
    auth_services: 'Debes elegir al menos uno'
  }
};
