import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ChatMessage from '../ChatMessage';
import ChatMessageInner from '../ChatMessageInner';

class ChatMessages extends Component {
  componentDidMount() {
    const root = document.getElementById('chat-messages-root');
    root.scrollTop = root.scrollHeight;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const root = document.getElementById('chat-messages-root');
    root.scrollTop = root.scrollHeight;
  }

  render() {
    let { messages, inner } = this.props;
    const nextMessages = messages.sort((a, b) => (a.id < b.id ? -1 : 1));
    return (
      <div className={'chat-messages-root'} id={'chat-messages-root'}>
        {nextMessages &&
          nextMessages.map(message =>
            inner ? (
              <ChatMessageInner key={message.id} message={message} />
            ) : (
              <ChatMessage key={message.id} message={message} />
            ),
          )}
      </div>
    );
  }
}

ChatMessages.propTypes = {};

export default ChatMessages;
