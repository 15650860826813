import React from 'react';
import PropTypes from 'prop-types';
import DataTable from '../../organisms/DataTable';
import PetitionsTableHead from '../../molecules/PetitionsTableHead';
import PetitionsTableBody from '../../molecules/PetitionsTableBody';
import { onCreatePetition } from '../../../redux/actions/petitionActions';
import { connect } from 'react-redux';
import PageHeader from '../../../components/PageHeader';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import './style.scss';

const PetitionsTemplate = ({ petitions, onCreatePetition }) => {
  const onNewPetition = () => {
    onCreatePetition();
  };
  return (
    <>
      <PageHeader title={'Peticiones'} />
      <div className={'new_petition_btn'}>
        <Button variant="contained" onClick={onNewPetition}>
          <FormattedMessage id={'petition_new_petition'} />
        </Button>
      </div>
      <DataTable
        head={<PetitionsTableHead />}
        body={<PetitionsTableBody data={petitions.results} />}
        count={petitions.count}
        name={'petitions'}
      />
    </>
  );
};

PetitionsTemplate.propTypes = {};

export default connect(
  null,
  { onCreatePetition },
)(PetitionsTemplate);
