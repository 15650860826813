import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles';

const ResumeSamples = ({ project, classes }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <span>
          <FormattedMessage id={'samples_available'} />{' '}
        </span>
        <b>{project.samples_date}</b>{' '}
        {project.samples_express && (
          <span className={classes.urgentStudy}>
            <FormattedMessage id={'urgent_study'} />
          </span>
        )}
      </Grid>
      <Grid item xs={12}>
        <b className={classes.commentHeader}><FormattedMessage id={"samples_comment"}/></b>
      </Grid>
      <Grid item xs={12}>
        {project.samples_comment}
      </Grid>
    </Grid>
  );
};

ResumeSamples.propTypes = {};

export default withStyles(styles)(ResumeSamples);
