import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Cosmocare from '../../../assets/images/svg/cosmocare.svg';
import IconButton from '@material-ui/core/IconButton';
import { compose } from 'redux';

import MenuIcon from '@material-ui/icons/Menu';

import { FormattedMessage } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import { connect } from 'react-redux';
import { getWhoAmI } from '../../../redux/actions/dataLoaderActions';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { logout } from '../../../redux/actions/authActions';
import classNames from 'classnames';
import LanguageSelect from '../../../pages/DashboardPage/common/SelectLang';
import Badge from '@material-ui/core/Badge';
import withWidth from '@material-ui/core/withWidth';
import { withTheme } from '@material-ui/core/styles';
import NotificationHandler from '../../molecules/NotificationHandler';
import './styles.scss';
import sidebarData from './data';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'white',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing.unit * 3,
    width: '100%',
    padding: '24px',
  },
  toolbar: theme.mixins.toolbar,
  grow: {
    flexGrow: 1,
  },
  logo: {
    height: '32px',
    [theme.breakpoints.down('lg')]: {
      height: '20px',
    },
  },
  profileName: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  close: {
    padding: theme.spacing.unit / 2,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

class AppDrawer extends Component {
  constructor(props) {
    super(props);
    const { width } = props;
    console.log('width', width);
    this.state = {
      showDrawer: width === 'xl',
      anchorEl: null,
    };
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { viewError: error };
  }

  componentDidMount() {
    const { getWhoAmI } = this.props;
    getWhoAmI();
  }

  handleProfileClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openSnackbar: false });
  };

  closeDrawerOnMobile = () => {
    const { theme } = this.props;
    const { showDrawer } = this.state;
    if (document.body.clientWidth < theme.breakpoints.values.lg && showDrawer) {
      this.setState({ showDrawer: false });
    }
  };

  render() {
    const { classes, history, whoami, logout, ws } = this.props;
    const { showDrawer, anchorEl } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar color={'default'} position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={() => this.setState({ showDrawer: !showDrawer })}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.grow}>
              <img
                className={classNames('appbar-logo', classes.logo)}
                src={Cosmocare}
                alt="cosmocare"
              />
            </div>
            <div>
              <Badge
                color="primary"
                invisible={ws.readyState !== 1}
                variant="dot"
              >
                <Chip
                  onClick={this.handleProfileClick}
                  icon={<FaceIcon />}
                  label={
                    whoami && (
                      <span className={classes.profileName}>
                        {whoami.first_name} {whoami.last_name}{' '}
                        {whoami.second_last_name}
                      </span>
                    )
                  }
                />
              </Badge>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <NavLink style={{ textDecoration: 'none' }} to={'/profile'}>
                  <MenuItem
                    onClick={() => {
                      this.handleClose();
                    }}
                  >
                    <FormattedMessage id={'menu_profile'} />
                  </MenuItem>
                </NavLink>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    logout();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
            <LanguageSelect />
          </Toolbar>
        </AppBar>
        {showDrawer && (
          <Drawer
            className={classes.drawer}
            PaperProps={{ elevation: 10 }}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            <div className={'app-drawer-list-wrapper'}>
              <List>
                {sidebarData
                  .filter(d => !d.disabled)
                  .map((d, index) => {
                    if (d.divider) {
                      return (
                        <div key={index} className={'app-drawer-list-wrapper'}>
                          <Divider />
                        </div>
                      );
                    } else {
                      return (
                        <li key={d.url} className={'app-drawer-list-item-li'}>
                          <NavLink
                            to={d.url}
                            className={'app-drawer-list-item'}
                          >
                            {d.icon}
                            <p className={'sidebar__link-title'}>
                              <FormattedMessage id={d.intl} />
                            </p>
                          </NavLink>
                        </li>
                      );
                    }
                  })}
              </List>
            </div>
          </Drawer>
        )}
        <main className={classes.content} onClick={this.closeDrawerOnMobile}>
          <div className={classes.toolbar} />
          <NotificationHandler />
          {this.props.children}
        </main>
      </div>
    );
  }
}

AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  whoami: state.dataLoader.whoami,
  ws: state.socket.ws,
});

const enhance = compose(
  withWidth(),
  withRouter,
  withTheme(),
  withStyles(styles),
  connect(
    mapStateToProps,
    { getWhoAmI, logout },
  ),
);

export default enhance(AppDrawer);
