import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import renderTextField from '../../helpers/renderTextField';
import Button from '@material-ui/core/Button';
import { patchClient } from '../../../redux/actions/dataLoaderActions';
import { connect } from 'react-redux';

class CompanyEditor extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  submitClient = data => {
    this.props.patchClient(data);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Paper elevation={2}>
        <div>
          <Tabs
            value={this.state.value}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
          >
            <Tab label={<FormattedMessage id={'profile_edit'} />} />
          </Tabs>
        </div>
        <form
          className={'profile-editor-form'}
          onSubmit={handleSubmit(this.submitClient)}
        >
          <Field
            name="name"
            id="name"
            component={renderTextField}
            type="text"
            label={<FormattedMessage id={'company_profile_name'} />}
          />
          <Field
            name="address"
            id="address"
            component={renderTextField}
            type="text"
            label={<FormattedMessage id={'company_profile_address'} />}
          />
          <Field
            name="phone"
            id="phone"
            component={renderTextField}
            type="text"
            label={<FormattedMessage id={'company_profile_phone'} />}
          />
          <Field
            disabled
            name="email"
            id="email"
            component={renderTextField}
            type="text"
            label={<FormattedMessage id={'auth_email'} />}
          />
          <div className={'profile-editor-save-btn'}>
            <Button variant="contained" type={'submit'}>
              <FormattedMessage id={'petition_save'} />
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

CompanyEditor.propTypes = {};

export default reduxForm({ form: 'company-editor' })(
  connect(
    null,
    { patchClient },
  )(CompanyEditor),
);
