import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';

const ResumeClaims = ({ claims }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <b>
          <FormattedMessage id={'claims_form'} />:{' '}
        </b>
        {claims.map((claim, index) => (
          <p key={index}>{claim}</p>
        ))}
      </Grid>
    </Grid>
  );
};

ResumeClaims.propTypes = {};

export default ResumeClaims;
