import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import { attachFilesToProject } from '../../../redux/actions/petitionHandlerActions';
import { injectIntl } from 'react-intl';
import { withProjectEditorBlock } from '../helpers';
import Header from '../components/Header';
import InformationForm from '../forms/InformationForm';

const SecurityPackService = props => {
  const {
    handleSubmit,
    intl,
    submitProject,
    project,
    attachFilesToProject,
    docs,
    products,
  } = props;

  useEffect(() => {
    const { handleSubmit } = props;
    return () => {
      handleSubmit();
    };
  }, []);

  const attachFiles = e => {
    const files = e.target.files;
    console.log('files', files);
    if (files.length > 0) {
      if (files[0].size > 104857600) {
        // TODO handle error
      } else {
        submit('SecurityPackService');
        attachFilesToProject({ file: files[0], project });
      }
    }
  };

  const commentView = withProjectEditorBlock(
    <Header head={intl.formatMessage({ id: 'petition_comment' })} />,
  )(<InformationForm />);

  return <form onSubmit={handleSubmit(submitProject)}>{commentView}</form>;
};

SecurityPackService.propTypes = {};

export default reduxForm({
  form: 'SecurityPackService',
  enableReinitialize: true,
})(
  connect(
    null,
    { attachFilesToProject, submit },
  )(injectIntl(SecurityPackService)),
);
