export default {
  en: {
    welcome_title: 'We look at the world with big eyes',
    auth_first_part: 'Welcome to',
    auth_second_part: 'CosmoCare Pro',
    auth_sub_part: 'Experts in cosmetics',
    auth_sub_part_signup: 'Create an account',
    auth_email: 'Email',
    auth_email_placeholer: 'E.g: mymail@gmail.com',
    auth_pass: 'Password',
    auth_pass_placeholder: 'Password',
    auth_remember_pass: 'Remember password',
    auth_forget_pass: 'Have you forgotten your password?',
    auth_log_in: 'Log in',
    auth_create_account: 'Create Account',
    auth_sign_up: 'Sign up',
    auth_user: 'User',
    auth_user_placeholer: 'Name',
    auth_have_account_question: 'Do you already have an account?',
    auth_have_account_sub: ' Log in',
    auth_reg_greeting: 'Hello',
    auth_reg_message:
      'To follow the registration process you must fill in the following information:',
    auth_reg_company_name: 'Name of the company (complete) *',
    auth_reg_company_name_placeholder: 'Name of the company',
    auth_reg_company_address: 'Company address*',
    auth_reg_company_address_placeholder: 'Address',
    auth_reg_company_number: 'VAT number / company number *',
    auth_reg_company_number_placeholder: 'Company number',
    auth_reg_contact_person: 'Contact person:*',
    auth_reg_first_name: 'First name',
    auth_reg_first_name_placeholder: 'Joan',
    auth_reg_second_name: 'Second name',
    auth_reg_second_name_placeholder: 'Pérez',
    auth_reg_last_name: 'Last name',
    auth_reg_last_name_placeholder: 'Diaz',
    auth_reg_email: 'Email',
    auth_reg_email_placeholder: 'joanperez@gmail.com',
    auth_reg_phone: 'Phone',
    auth_reg_phone_placeholder: '+34 689 245 222',
    auth_reg_url: 'URL (Optional)',
    auth_reg_url_placeholder: 'url',
    auth_reg_services: 'What type of service are you interested in?',
    auth_reg_service_1: 'Regulatory',
    auth_reg_service_2: 'Safety and efficacy',
    auth_reg_service_3: 'Consumer test',
    auth_reg_service_4:
      'Other services: stability test, challenge test, packaging compatibility test',
    auth_reg_service_5: 'Safety Pack',
    auth_reg_service_6: 'Queries',
    auth_reg_btn_submit: 'Submit',
    auth_reg_btn_cancel: 'Cancel',
    auth_support_title: 'Support',
    auth_support_name: 'Maite Peña',
    auth_support_message:
      'If you have any questions during the registration process click on any of the 2 buttons',
    auth_support_btn_faq: 'See FAQ',
    auth_support_btn_contact: 'Contact',
    auth_reg_fantastic: 'Fantastic',
    auth_reg_final_first_part:
      'We will contact you in less than 24 hours during business days to verify your information and sign up on our platform. You will receive an email to ',
    auth_reg_final_socond_part:
      'with the confirmation and access to the platform.',
    auth_reg_thanks: 'Thanks for trusting CosmoCare.',
    auth_req_welcome: 'A greeting!',
    auth_forget_pass_title: 'Forget password',
    auth_forget_pass_sub: 'Enter your email',
    auth_forget_pass_message:
      'We will send you an email with the access to be able to delete your password',
    petition_product_name: 'Product name',
    petition_documents: 'Documentation',
    petition_documents_descr: 'Select a project type',
    petition_study_files_descr:
      'Download the necessary documentation for the study',
    petition_report_language: 'Report language *',
    petition_products: 'Products',
    petition_observation: 'Observations',
    petition_comments: 'Comments',
    petition_save_project: 'Save',
    petition_observation_specification: 'Observations / Specifications',
    petition_resume_id: 'ID',
    petition_resume_photo: 'Photo',
    petition_product_type: 'Product type',
    petition_manufactured: 'Manufacture date ',
    petition_lot_number: 'Lot number',
    petition_projects: 'Projects',
    petition_project_type: 'Project type',
    petition_resume_comment: 'Comments on the project type',
    petition_project_download_documents: 'Download documents',
    petition_validate: 'Validate',
    petition_save: 'Save',
    petition_language: 'Language',
    petition_study_docs: 'Study documentation',
    petition_request_send: 'Request sent',
    petition_request_send_message:
      'Your request has been sent. In less than 48 h* we will review your request and notify you of our detailed proposal so that you can give us your validation.',
    petition_request_days: 'During business days',
    show_petition: 'See Petition',
    petition_number_of_petition: '№ petition',
    petition_product: 'Product',
    petition_product_ref: 'Product Ref.',
    petition_created_date: 'Creation date',
    petition_project_status: 'Project status',
    canceled: 'Canceled',
    vc_finding: 'Volunteer/consumer searching',
    validated: 'Validated',
    pending: 'Pending',
    in_progress: 'In progress',
    wait_vc_results: 'Waiting results',
    done: 'Done',
    products_product: 'Product',
    products_id: 'ID',
    products_ref: 'Product Ref.',
    products_request_date: 'Request date',
    projects_service_type: 'Service type',
    projects_type: 'Project type',
    projects_validate_date: 'Validation date',
    projects_samples_state: 'Sample status',
    projects_answers_state: 'Questionnaire status',
    projects_study_state: 'Study status',
    projects_samples_received: 'Received',
    projects_samples_not_received: 'Not received',
    projects_answers_validated: 'Validated',
    projects_answers_not_validated: 'Not validated',
    home_projects_vc_finding: 'No pending start studies',
    home_projects_in_progress: 'No studies in progress',
    home_projects_done: 'No total completed studies',
    petition_project_price: 'Price',
    petition_project_amount: 'Amount',
    petition_delete_title: 'Delete petition',
    project_delete_title: 'Delete project',
    petition_delete_desc: 'This action will delete the petition. Continue?',
    project_delete_desc: 'This action will delete the project. Continue?',
    petition_new_petition: 'New study',
    navigation_home: 'Home',
    navigation_services: 'Services',
    navigation_petitions: 'Petitions',
    navigation_projects: 'Projects',
    navigation_products: 'Products',
    navigation_invoices: 'Invoices',
    navigation_calendar: 'Calendar',
    navigation_reports: 'Reports',
    navigation_messages: 'Messages',
    navigation_notifications: 'Notifications',
    navigation_archive: 'Archive',
    navigation_company_profile: 'Company profile',
    navigation_settings: 'Settings',
    navigation_help_center: 'Help center',
    profile_edit: 'Edit profile',
    company_profile_name: 'Company name',
    company_profile_address: 'Location',
    company_profile_phone: 'Phone number',
    menu_profile: 'Profile',
    home_page_greeting: 'Welcome',
    home_page_greeting_message:
      'Quickly manage your projects and discover all the news we have for you.',
    petition_add_product: 'Add product',
    petition_add_project: 'Add project',
    petition_delete_btn: 'Delete petition',
    back_btn: 'Back',
    accept_resume_btn: 'Accept petition',
    pay_btn: 'PAY',
    rows_per_page: 'Rows per page:',
    items_in_table_of: 'of',
    agree_btn: 'Agree',
    disagree_btn: 'Disagree',
    project_id: 'Project ID',
    project_state: 'State',
    project_start_date: 'Start date',
    project_end_date: 'End date',
    project_report_submission_date: 'Report submission date',
    service_regulatory: 'Regulatory',
    service_regulatory_desc:
      'Cosmetic dossier, safety assessment, label validation...',
    service_eficacia: 'Safety and efficacy',
    service_eficacia_desc: 'Patch test, use test , hydration test ...',
    service_consumodores: 'Consumer test',
    service_consumodores_desc:
      'Support of claims under subjective evaluation of the cosmetic acceptability and effectiveness of cosmetic products',
    service_otros: 'Other services',
    service_otros_desc:
      'E.g.: stability test, challenge test, packaging compatibility test',
    service_seguridad: 'Safety Pack',
    service_seguridad_desc:
      'Subscription on a monthly basis allowing a daily follow-up',
    service_consultas: 'Consultation',
    see_more_btn: 'See more',
    show_project: 'Show project',
    petition_information: 'Petition Information',
    total_product: '{count} Product in total',
    total_products: '{count} Products in total',
    petition_project: 'PROJECT',
    petition: 'Petition',
    products_desc: 'Add products you want to test',
    projects_desc: 'Select the projects and link the products',
    new_project: 'New project',
    select_project_type: 'Select project type',
    select_service_type: 'Select service type',
    project_comment: 'Comments on the project type',
    file_size_is_to_large:
      'File size is too large. Acceptable size is less than 100MB.',
    panel: 'Panel',
    volunteers_number: '№ of volunteers*:',
    panel_sex: "Gender* (If necessary, specify % of panel)",
    panel_sex_all: "All",
    panel_sex_female_100: "Female(100 % panel)",
    panel_sex_male_100: "Male (100 % panel)",
    panel_age: "Age* (If necessary, specify % of panel)",
    panel_age_comment: "To detail more groups of age groups, you can indicate the % in the observations section of the panel.",
    physical_characteristics: "Physical characteristics *:",
    panel_body_skin_type: "Body skin type",
    panel_face_skin_type: "Type of facial skin",
    panel_hair_type: "Hair type",
    panel_scalp_type: "Scalp type",
    panel_atopy: "Atopy",
    panel_sensitivity: "Sensitivity",
    panel_sensitive_face_skin: "Sensitive of face skin",
    panel_sensitive_body_skin: "Sensitive of body skin",
    panel_sensitive_custom_skin: "Skin of",
    panel_skin_phototype: "Phototype",
    panel_eyes_type: "Sensitive of eyes",
    panel_contact_lenses: "Contact lenses (If necessary, specify %)",
    panel_other_characteristics: "Other characteristic",
    panel_additional_observations: "Additional comments from the panel",
    panel_volunteers: "Volunteers",
    panel_age_resume: "Age",
    modeofuse: "MODO DE USO",
    modeofuse_description: "Specify how to use the product.",
    modeofuse_application_area: "Application area*",
    modeofuse_frequency_of_use: "Application frequency*",
    modeofuse_amount_of_use: "Application quantity*",
    modeofuse_refinements_method: "Remarks / Specifications",
    samples_form: "SAMPLES",
    samples_date: "Indicate the expected availability of samples in CosmoCare. Remember that the product must arrive the week before starting the study. In case of urgent studies, indicate it in the box below.",
    samples_urgent: "Urgent Study",
    samples_comment: "Comments",
    claims_form: "Use claim",
    claims: "CLAIMS",
    indifferent: "Indifferent",
    panel_sensitive_scalp_skin: "Sensitive scalp",
    petition_comment: "Comments",
    form_information: "INFORMATION",
    samples_available: "Expected availability of samples in CosmoCare",
    urgent_study: "URGENT STUDY",
    form_consultation: "CONSULTATION",
    petition_project_questionnaire: "QUESTIONNAIRES",
    petition_project_planning: "PROJECT PLANNING",
    planning_start_date: "Start day",
    planning_middle_date: "Intermediate day",
    planning_end_date: "End day",
    planning_report_date: "Report day",
  },
  es: {
    welcome_title: 'Miramos el mundo con ojos grandes',
    auth_first_part: 'Bienvenido a ',
    auth_second_part: 'CosmoCare Pro',
    auth_sub_part: 'Expertos en cosmética',
    auth_sub_part_signup: 'Crear una cuenta',
    auth_email: 'Email',
    auth_email_placeholer: 'Ex: mymail@gmail.com',
    auth_pass: 'Contraseña',
    auth_pass_placeholder: 'Contraseña',
    auth_remember_pass: 'Recordar contraseña',
    auth_forget_pass: '¿Has olvidado tu contraseña?',
    auth_log_in: 'Iniciar Sesión',
    auth_create_account: 'Crear Cuenta',
    auth_sign_up: 'Registrarse',
    auth_user: 'Usuario',
    auth_user_placeholer: 'Nombre',
    auth_have_account_question: '¿Ya tienes una cuenta?',
    auth_have_account_sub: 'Iniciar Sesión',
    auth_reg_greeting: 'Hola',
    auth_reg_message:
      'Para seguir el proceso de registro debes rellenar la siguiente información:',
    auth_reg_company_name: 'Nombre de la empresa (completo)*',
    auth_reg_company_name_placeholder: 'Nombre de la empresa',
    auth_reg_company_address: 'Dirección de la empresa*',
    auth_reg_company_address_placeholder: 'La empresa',
    auth_reg_company_number: 'CIF / Nº empresa *',
    auth_reg_company_number_placeholder: 'Número de compañía',
    auth_reg_contact_person: 'Persona de contacto:*',
    auth_reg_first_name: 'Nombre',
    auth_reg_first_name_placeholder: 'Joan',
    auth_reg_second_name: 'Apellido',
    auth_reg_second_name_placeholder: 'Pérez',
    auth_reg_last_name: 'Segundo apellido',
    auth_reg_last_name_placeholder: 'Diaz',
    auth_reg_email: 'Email',
    auth_reg_email_placeholder: 'joanperez@gmail.com',
    auth_reg_phone: 'Teléfono',
    auth_reg_phone_placeholder: '+34 689 245 222',
    auth_reg_url: 'URL (Opcional)',
    auth_reg_url_placeholder: 'url',
    auth_reg_services: '¿En qué tipo de servicio estás interesado?',
    auth_reg_service_1: 'Regulatorio',
    auth_reg_service_2: 'Seguridad y eficacia',
    auth_reg_service_3: 'Test consumidores',
    auth_reg_service_4:
      'Otros servicios: test de estabilidad, challenge test, test de compatibilidad de packaging',
    auth_reg_service_5: 'Pack seguridad',
    auth_reg_service_6: 'Consultas',
    auth_reg_btn_submit: 'Enviar',
    auth_reg_btn_cancel: 'Cancelar',
    auth_support_title: 'Soporte',
    auth_support_name: 'Maite Peña',
    auth_support_message:
      'Si tienes cualquier duda durante el proceso de registro, haz click en cualquiera de los 2 botones.',
    auth_support_btn_faq: 'Ver FAQ',
    auth_support_btn_contact: 'Contactar',
    auth_reg_fantastic: '¡Fantástico',
    auth_reg_final_first_part:
      'Nos pondremos en contacto contigo en menos de 24h durante días laborales para verificar tus datos y darte de alta en nuestra plataforma. Recibirás un correo a ',
    auth_reg_final_socond_part:
      'con la confirmación y el acceso a la plataforma.',
    auth_reg_thanks: 'Gracias por confiar en CosmoCare.',
    auth_req_welcome: 'Un saludo',
    auth_forget_pass_title: 'Olvido de contraseña',
    auth_forget_pass_sub: 'Introduce tu email',
    auth_forget_pass_message:
      'Te enviaremos un email con el acceso para poder restablecer tu contraseña',
    petition_product_name: 'Denominación',
    petition_documents: 'DOCUMENTACIÓN',
    petition_documents_descr: 'Selecciona el tipo de proyecto',
    petition_study_files_descr:
      'Descarga la documentación necesaria para el estudio',
    petition_report_language: 'Idioma del informe *',
    petition_products: 'PRODUCTOS',
    petition_observation: 'OBSERVACIONES',
    petition_comments: 'Comentarios',
    petition_save_project: 'Guardar',
    petition_observation_specification: 'Observaciones / Especificaciones',
    petition_resume_id: 'ID',
    petition_resume_photo: 'Foto',
    petition_product_type: 'Tipo de Producto',
    petition_manufactured: 'Fecha de Fabricación',
    petition_lot_number: '№ de Lote',
    petition_projects: 'Proyectos',
    petition_project_type: 'TIPO DE PROYECTO',
    petition_resume_comment: 'Comentarios sobre el tipo de proyecto',
    petition_project_download_documents: 'DESCARGAR DOCUMENTOS',
    petition_validate: 'Validar',
    petition_save: 'Guardar',
    petition_language: 'Idioma',
    petition_study_docs: 'Documentación del estudio',
    petition_request_send: 'Petición enviada',
    petition_request_send_message:
      'Tu petición ha sido enviada. En menos de 48h* revisaremos tu solicitud y te notificaremos de nuestra propuesta detallada para que nos des tu confirmación.',
    petition_request_days: '*Durante días laborales',
    show_petition: 'Ver petición',
    petition_number_of_petition: '№ petición',
    petition_product: 'Producto',
    petition_product_ref: 'Ref. Producto',
    petition_created_date: 'Fecha de petición',
    petition_project_status: 'Estado de proyecto',
    canceled: 'Cancelado',
    validated: 'Validado',
    pending: 'Pendiente',
    vc_finding: 'Búsqueda de Voluntario/Consumidor',
    in_progress: 'En curso',
    wait_vc_results: 'Espera de los resultados',
    done: 'Realizado',
    products_product: 'Producto',
    products_id: 'ID',
    products_ref: 'Producto Ref.',
    products_request_date: 'Fecha de petición',
    projects_service_type: 'Tipo de servicio',
    projects_type: 'Tipo de proyecto',
    projects_validate_date: 'Fecha de validación',
    projects_samples_state: 'Estado de las muestras',
    projects_answers_state: 'Estado del cuestionario',
    projects_study_state: 'Estado del estudio',
    projects_samples_received: 'Recibido',
    projects_samples_not_received: 'No Recibido',
    projects_answers_validated: 'Validado',
    projects_answers_not_validated: 'No Validado',
    home_projects_vc_finding: 'No ESTUDIOS PENDIENTES DE INICIO',
    home_projects_in_progress: 'No ESTUDIOS EN CURSO',
    home_projects_done: 'No TOTAL DE ESTUDIOS REALIZADOS',
    petition_project_price: 'PRECIO',
    petition_project_amount: 'Cantidad',
    petition_delete_title: 'Eliminar la petición',
    project_delete_title: 'Eliminar la proyecto',
    petition_delete_desc:
      'Esta acción va a eliminar la petición. ¿Debemos continuar?',
    project_delete_desc:
      'Esta acción va a eliminar la proyecto. ¿Debemos continuar?',
    petition_new_petition: 'Nuevo Estudio',
    navigation_home: 'Inicio',
    navigation_services: 'Servicios',
    navigation_petitions: 'Peticiones',
    navigation_projects: 'Proyectos',
    navigation_products: 'Productos',
    navigation_invoices: 'Facturas',
    navigation_calendar: 'Calendario',
    navigation_reports: 'Informes',
    navigation_messages: 'Mensajes',
    navigation_notifications: 'Notificaciones',
    navigation_archive: 'Archivos',
    navigation_company_profile: 'Perfil de Empresa',
    navigation_settings: 'Ajustes',
    navigation_help_center: 'Centro de Ayuda',
    profile_edit: 'Editar perfil',
    company_profile_name: 'Nombre de Empresa',
    company_profile_address: 'Localidad',
    company_profile_phone: 'Teléfono',
    menu_profile: 'Perfil',
    home_page_greeting: 'Bienvenido',
    home_page_greeting_message:
      'Gestiona de forma ágil tus proyectos y descubre las novedades que tenemos para ti.',
    petition_add_product: 'Añadir Producto',
    petition_add_project: 'Añadir Proyecto',
    petition_delete_btn: 'Eliminar la petición',
    back_btn: 'Atrás',
    accept_resume_btn: 'Aceptar la petición',
    pay_btn: 'PAGAR',
    rows_per_page: 'Líneas por página:',
    items_in_table_of: 'de',
    agree_btn: 'Sí',
    disagree_btn: 'No',
    project_id: 'ID Proyecto',
    project_state: 'Estado',
    project_start_date: 'Fecha de inicio',
    project_end_date: 'Fecha de fin',
    project_report_submission_date: 'Fecha de envío del informe',
    service_regulatory: 'Regulatory',
    service_regulatory_desc:
      'Dosier cosmético, evaluación de seguridad, validación de etiqueta…',
    service_eficacia: 'Seguridad y Eficacia',
    service_eficacia_desc: 'Patch test, test de uso, hidratación...',
    service_consumodores: 'Test consumidores',
    service_consumodores_desc:
      'Apoyo de los claims bajo evaluación subjetiva de la aceptabilidad cosmética y la eficacia de un producto cosmético',
    service_otros: 'Otros servicios',
    service_otros_desc:
      'Ej: test de estabilidad, challenge test, test de compatibilidad de packaging',
    service_seguridad: 'Pack seguridad',
    service_seguridad_desc:
      'Suscripción mensual permitiendo un seguimiento diario.',
    service_consultas: 'Consultas',
    see_more_btn: 'Ver más',
    show_project: 'Ver proyecto',
    petition_information: 'Petición Información',
    total_product: '{count} Producto en total',
    total_products: '{count} Productos en total',
    petition_project: 'PROYECTO',
    petition: 'Petición',
    products_desc: 'Añade los productos que quieres examinar',
    projects_desc: 'Elige los proyectos y vincula los productos',
    new_project: 'Nuevo PROYECTO',
    select_project_type: 'Selecciona el tipo de proyecto',
    select_service_type: 'Selecciona el tipo de servicio',
    project_comment: 'Comentarios sobre el tipo de proyecto',
    file_size_is_to_large:
      'El tamaño del archivo es demasiado grande. El tamaño aceptable es menos de 100MB.',
    panel: 'Panel',
    volunteers_number: '№ de voluntarios*:',
    panel_sex: "Sexo * (Si es necesario, especificar % del panel)",
    panel_sex_all: "Ambos",
    panel_sex_female_100: "Femenino (100 % panel)",
    panel_sex_male_100: "Masculino (100 % panel)",
    panel_sex_female: "Femenino",
    panel_sex_male: "Masculino",
    panel_age: "Edad* (Si es necesario, especificar % del panel)",
    panel_age_comment: "Para detallar mas grupos de franjas de edad, podéis indicar los % en el apartado de observaciones del panel.",
    physical_characteristics: "Características físicas*:",
    panel_body_skin_type: "Tipo de piel del cuerpo",
    panel_face_skin_type: "Tipo de piel del rostro",
    panel_hair_type: "Tipo de cabello",
    panel_scalp_type: "Tipo de cuero cabelludo",
    panel_atopy: "Atopia",
    panel_sensitivity: "Sensibilidad",
    panel_sensitive_face_skin: "Piel del rostro sensible",
    panel_sensitive_body_skin: "Piel del cuerpo sensible",
    panel_sensitive_custom_skin: "Piel del",
    panel_skin_phototype: "Fototipo",
    panel_eyes_type: "Ojos sensibles",
    panel_contact_lenses: "Lentillas (Si es necesario, especificar %)",
    panel_other_characteristics: "Otras características",
    panel_additional_observations: "Observaciones adicionales del panel",
    panel_volunteers: "Voluntarios",
    panel_age_resume: "Edad",
    modeofuse: "MODO DE USO",
    modeofuse_description: "Especifique el modo de uso del producto.",
    modeofuse_application_area: "Área de aplicación*",
    modeofuse_frequency_of_use: "Frecuencia de aplicación*",
    modeofuse_amount_of_use: "Cantidad de aplicación*",
    modeofuse_refinements_method: "Observaciones / especificaciones",
    samples_form: "MUESTRAS",
    samples_date: "Indicar la disponibilidad prevista de las muestras en CosmoCare. Recuerde que el producto debe llegar la semana antes de empezar el estudio. En caso de estudios urgentes indicarlo en la casilla de abajo.",
    samples_urgent: "Estudio Urgente",
    samples_comment: "Comentarios",
    claims_form: "Claim de uso",
    claims: "CLAIMS",
    indifferent: "Indiferente",
    panel_sensitive_scalp_skin: "Cuero cabelludo sensible",
    petition_comment: "Comentarios",
    form_information: "INFORMACIÓN",
    samples_available: "Disponibilidad prevista de las muestras en CosmoCare",
    urgent_study: "ESTUDIO URGENTE",
    form_consultation: "CONSULTA",
    petition_project_questionnaire: "CUESTIONARIOS",
    petition_project_planning: "PROYECTO PLANING",
    planning_start_date: "Día Inicio",
    planning_middle_date: "Día Intermedia",
    planning_end_date: "Día Fin",
    planning_report_date: "Fecha Entrega Informe",
  },
};
