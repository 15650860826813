import { all } from 'redux-saga/effects';
import authSaga from './authActions';
import commonSaga from './commonActions';
import langSaga from './localeActions';
import dashboardSaga from './dashboardActions';
import petitionSaga from './petitionActions';
import petitionHandlerSaga from './petitionHandlerActions';
import dataLoaderActions from './dataLoaderActions';
import socketActions from './socketActions';
import questionnaireActions from './questionnaireActions';

export default function*() {
  yield all([
    authSaga(),
    // commonSaga(),
    langSaga(),
    dashboardSaga(),
    petitionSaga(),
    petitionHandlerSaga(),
    dataLoaderActions(),
    socketActions(),
    questionnaireActions()
  ]);
}
