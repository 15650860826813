import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import Router from './Router';
import localization from './localization/app_localization';
import withApp from './withApp';
import { setCurrentUser } from '../redux/actions/authActions';
import '../assets/scss/common-styles.scss';
import 'cosmocare-common/dist/styles/index.scss';
import { getCommon } from '../redux/actions/dataLoaderActions';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewError: null,
    };
  }

  componentWillMount() {
    const { setCurrentUser } = this.props;
    setCurrentUser();
  }

  componentDidMount() {
    const { getCommon } = this.props;
    getCommon();
  }

  static getDerivedStateFromError(error) {
    return { viewError: error };
  }

  render() {
    const { lang } = this.props;
    const { viewError } = this.state;
    if (viewError) {
      return <code>Oops! Something went wrong.</code>;
    }
    return (
      <Fade in timeout={1500}>
        <div className="app">
          <IntlProvider locale={lang} key={lang} messages={localization[lang]}>
            <Router />
          </IntlProvider>
        </div>
      </Fade>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.locale.lang,
});

export default connect(
  mapStateToProps,
  { setCurrentUser, getCommon },
)(withApp(App));
