import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import './styles.scss';
import Divider from '@material-ui/core/Divider';
import ProfileCounter from '../../molecules/ProfileCounter';
import { FormattedMessage } from 'react-intl';

const Profile = ({ whoami, counters }) => {
  return (
    <Paper>
      <div className={'profile-root'}>
        <div className={'profile-info-block'}>
          <Avatar className={'profile-avatar'}>
            {whoami.first_name && whoami.first_name[0]}
          </Avatar>
          <div>
            <b>
              {whoami.first_name && whoami.first_name.toUpperCase()}{' '}
              {whoami.last_name && whoami.last_name.toUpperCase()}{' '}
              {whoami.second_last_name && whoami.second_last_name.toUpperCase()}
            </b>
            <p>{whoami.email && whoami.email}</p>
          </div>
        </div>
        <Divider />
        <div className={'profile-counters'}>
          <ProfileCounter
            text={<FormattedMessage id={'home_projects_vc_finding'} />}
            count={counters.vc_finding}
          />
          <ProfileCounter
            text={<FormattedMessage id={'home_projects_in_progress'} />}
            count={counters.in_progress}
          />
          <ProfileCounter
            text={<FormattedMessage id={'home_projects_done'} />}
            count={counters.done}
          />
        </div>
      </div>
    </Paper>
  );
};

Profile.propTypes = {};

export default Profile;
