import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import Arrow from '@material-ui/icons/KeyboardArrowRight';

import PageHeader from '../../../components/PageHeader';
import BlueButton from '../../../components/BlueButton';
import PetitionsTable from '../PetitionsTable';
import {
  onMountPetition,
  onCreatePetition,
} from '../../../redux/actions/petitionActions';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import { getNumberFromProgress } from '../../../helpers/progress';
import { Link } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';

class PetitionsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
    };
    this.onNewPetition = this.onNewPetition.bind(this);
  }

  componentDidMount() {
    const { onMountPetition } = this.props;
    const { page } = this.state;
    onMountPetition({ page });
    // console.log('this=', this.props);
  }

  onNewPetition() {
    console.log('this=', this.props);
    const { onCreatePetition } = this.props;
    onCreatePetition();
  }

  handleChangePage = (event, page) => {
    const { onMountPetition } = this.props;
    const { rowsPerPage } = this.state;
    onMountPetition({ page, rowsPerPage });
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    const { onMountPetition } = this.props;
    this.setState({ rowsPerPage: event.target.value });
    const { page } = this.state;
    onMountPetition({ page, rowsPerPage: event.target.value });
  };

  render() {
    const { petitions, count } = this.props;
    const { page, rowsPerPage } = this.state;
    // console.log('this.props=', this.props);

    // let id = 0;
    // function createData(name, calories, fat, carbs, protein) {
    //     id += 1;
    //     return { id, name, calories, fat, carbs, protein };
    // }

    return (
        <><PageHeader title="Mis Peticiones">
          {/* <Link
            to="/petition"
            style={{ textDecoration: 'none', width: '100%' }}
          > */}
          <BlueButton
            active
            styles={{
              width: '135px',
              height: '44px',
            }}
            handleClick={this.onNewPetition}
          >
            Nuevo Estudio
          </BlueButton>
          {/* </Link> */}
        </PageHeader>
            <Paper elevation={1} style={{ margin: '20px 20px 20px 0' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id={'petition_number_of_petition'} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={'petition_project_type'} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={'petition_product'} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={'petition_product_ref'} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={'petition_created_date'} />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id={'petition_project_status'} />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {petitions.map(row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>
                        {row.projects &&
                          row.projects.length > 0 &&
                          row.projects[0].project_type.name}
                      </TableCell>
                      <TableCell>
                        {row.products &&
                          row.products.length > 0 &&
                          row.products[0].name}
                      </TableCell>
                      <TableCell>
                        {row.products &&
                          row.products.length > 0 &&
                          row.products[0].reference}
                      </TableCell>
                      <TableCell>{row.creation_date}</TableCell>
                      <TableCell>
                        {row.projects && row.projects.length > 0 && (
                          <FormattedMessage id={row.projects[0].progress} />
                        )}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={
                            getNumberFromProgress(row.progress) === 3
                              ? `/petition/${row.id}`
                              : `/petition/${row.id}/edit`
                          }
                        >
                          <Arrow />
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Paper></>
    );
  }
}

PetitionsListPage.defaultProps = {
  petitions: [],
};

PetitionsListPage.propTypes = {
  petitions: PropTypes.arrayOf(
    PropTypes.shape({
      petition_number: PropTypes.string,
      project_type: PropTypes.string,
      product_ref: PropTypes.string,
      petition_date: PropTypes.string,
      project_state: PropTypes.string,
      ver_ficha: PropTypes.bool,
    }),
  ), // .isRequired
};

const mapStateToProps = state => ({
  ...state.petitions,
  petitionState: state.petition,
  petitions: state.petition.petitions.results,
  count: state.petition.petitions.count,
});

export default connect(
  mapStateToProps,
  { onMountPetition, onCreatePetition },
)(PetitionsListPage);
