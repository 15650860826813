import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './styles.scss';
import { SnackbarProvider, withSnackbar } from 'notistack';
import {
  onSocketClearError,
  onSocketClearNotification,
} from '../../../redux/actions/socketActions';

class NotificationHandler extends Component {
  state = {
    notification: null,
  };

  handleClick = state => () => {
    this.setState({ open: true, ...state });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      onSocketClearNotification,
      notification,
      socketError,
      onSocketClearError,
      petitionHandlerError,
      dataLoaderError
    } = this.props;

    let message = '';

    if (notification && notification.chat_type) {
      const type = notification.chat_type;
      const value = notification[type];
      message = (
        <NavLink
          className={'notification-handler-link'}
          to={`/${type}/${value}`}
        >
          <span>
            {notification.text} in {type} {value}
          </span>
        </NavLink>
      );
      this.props.enqueueSnackbar(message, { variant: 'success' });
      onSocketClearNotification();
    }
    if (socketError) {
      this.props.enqueueSnackbar(socketError, { variant: 'error' });
      onSocketClearError();
    }
    if (petitionHandlerError) {
      this.props.enqueueSnackbar(petitionHandlerError, { variant: 'error' });
    }
    if (dataLoaderError) {
      this.props.enqueueSnackbar(dataLoaderError, { variant: 'error' });
    }
    return null;
  }
}

NotificationHandler.propTypes = {};

const mapStateToProps = state => ({
  notification: state.socket.notification,
  socketError: state.socket.error,
  petitionHandlerError: state.petitionHandler.error,
  dataLoaderError: state.dataLoader.error,
});

const WithSnackbar = withSnackbar(
  connect(
    mapStateToProps,
    { onSocketClearNotification, onSocketClearError },
  )(NotificationHandler),
);

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <WithSnackbar />
    </SnackbarProvider>
  );
}

export default IntegrationNotistack;

//
// export default connect(
//   mapStateToProps,
//   { onSocketClearNotification },
// )(NotificationHandler);
