import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import styles from './styles';
import { withStyles } from '@material-ui/core';

const ResumeProjectType = ({ projectType, comment, classes }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <p>
          <FormattedMessage id={'petition_project_type'} />
        </p>
        <div className={classes.grayColor}>{projectType}</div>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={12} md={4}>
        <p>
          <FormattedMessage id={'petition_resume_comment'} />
        </p>
        <p className={classes.grayColor}>{comment}</p>
      </Grid>
    </Grid>
  );
};

ResumeProjectType.propTypes = {};

export default withStyles(styles)(ResumeProjectType);
