import { all, take, put } from 'redux-saga/effects';
import { resetForms } from '../util';
import {
  SET_LANG_REQUEST,
  SET_LANG_SUCCESS,
  GET_LANG_REQUEST,
  GET_LANG_SUCCESS,
  GET_LANG_ERROR
} from '../types';

// ///////////
// actions //
// ///////////

export const getCurrentLanguage = lang => ({
  type: GET_LANG_REQUEST,
  payload: lang
});

export const setLanguage = lang => ({
  type: SET_LANG_REQUEST,
  payload: lang
});

// ///////////
// sagas ////
// ///////////

const getCurrentLanguageSaga = function*() {
  while (true) {
    try {
      const action = yield take(GET_LANG_REQUEST);
      yield put({ type: GET_LANG_SUCCESS, payload: action.payload });
      yield localStorage.setItem('lang', action.payload);
    } catch (error) {
      yield put({ type: GET_LANG_ERROR, error });
    }
  }
};

const setLanguageSaga = function*() {
  while (true) {
    try {
      const action = yield take(SET_LANG_REQUEST);
      yield put({ type: SET_LANG_SUCCESS, payload: action.payload });
      yield localStorage.setItem('lang', action.payload);
      yield resetForms();
    } catch (error) {
      yield put({ type: GET_LANG_ERROR, error });
    }
  }
};

export default function*() {
  yield all([setLanguageSaga(), getCurrentLanguageSaga()]);
}
