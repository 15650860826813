import initialState from './initialState';
import injectReducer from './injectReducer';

import { SET_LANG_SUCCESS, GET_LANG_SUCCESS, GET_LANG_ERROR } from '../types';

const handlers = {
  [SET_LANG_SUCCESS]: (state, { payload }) => ({
    ...state,
    lang: payload
  }),
  [GET_LANG_SUCCESS]: (state, { payload }) => ({
    ...state,
    lang: payload
  }),
  [GET_LANG_ERROR]: (state, { error }) => ({
    ...state,
    error
  })
};

export default injectReducer(initialState.localeReducer, handlers);
