import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles';
import { FormattedMessage } from 'react-intl';

const ResumeProducts = ({ products, classes }) => {
  return (
    <div
      className={classNames(
        classes.infoBlockMargin,
        classes.tableOverflow,
        classes.width,
      )}
    >
      <Table style={{width: '100%'}}>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id={'petition_resume_id'} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={'petition_resume_photo'} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={'petition_product_name'} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={'petition_product_type'} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={'petition_manufactured'} />
            </TableCell>
            <TableCell>
              <FormattedMessage id={'petition_lot_number'} />
            </TableCell>
            {/*<TableCell>*/}
            {/*  <FormattedMessage id={'petition_projects'} />*/}
            {/*</TableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(product => (
            <TableRow key={product.id}>
              <TableCell component="th" scope="row">
                {product.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {' '}
              </TableCell>
              <TableCell component="th" scope="row">
                {product.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {product.product_type}
              </TableCell>
              <TableCell component="th" scope="row">
                {product.production_date}
              </TableCell>
              <TableCell component="th" scope="row">
                {product.lot}
              </TableCell>
              {/*<TableCell component="th" scope="row">*/}
              {/*    {" "}*/}
              {/*</TableCell>*/}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

ResumeProducts.propTypes = {};

export default withStyles(styles)(ResumeProducts);
