import React from 'react';
import ProductsTemplate from '../../templates/ProductsTemplate';
import Loader from '../../organisms/Loader';
import withData from '../../HOC/WithData';

function ProductsPage(props) {
  const {data} = props;
  return (
      <>
        {!data.products && <Loader/>}
        {data.products && <ProductsTemplate products={data.products}/>}
      </>
  );
}

ProductsPage.propTypes = {};

export default withData(ProductsPage, 'products');
