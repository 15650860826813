import React from 'react';
import PropTypes from 'prop-types';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import './planning-date.css';

const PlanningDate = ({ date, text }) => {
  return (
    <div className={'planning-date-block'}>
      <div className={'planning-date-text'}>{text}</div>
      <div>
        <CalendarIcon className={'planning-date-icon'} />
        <span className={'planning-date-date'}>{date}</span>
      </div>
    </div>
  );
};

PlanningDate.propTypes = {};

export default PlanningDate;
