import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import renderTextField from '../../helpers/renderTextField';
import Button from '@material-ui/core/Button';
import './styles.scss';
import { Divider } from '@material-ui/core';
import { onPatchProfile } from '../../../redux/actions/dataLoaderActions';
import { connect } from 'react-redux';

class ProfileEditor extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  submitProfile = data => {
    const { onPatchProfile } = this.props;
    onPatchProfile(data);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Paper elevation={2}>
        <div>
          <Tabs
            value={this.state.value}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
          >
            <Tab label={<FormattedMessage id={'profile_edit'} />} />
          </Tabs>
        </div>
        <form
          className={'profile-editor-form'}
          onSubmit={handleSubmit(this.submitProfile)}
        >
          <Field
            name="first_name"
            id="first_name"
            component={renderTextField}
            type="text"
            label={<FormattedMessage id={'auth_reg_first_name'} />}
          />
          <Field
            name="last_name"
            id="last_name"
            component={renderTextField}
            type="text"
            label={<FormattedMessage id={'auth_reg_second_name'} />}
          />
          <Field
            name="second_last_name"
            id="second_last_name"
            component={renderTextField}
            type="text"
            label={<FormattedMessage id={'auth_reg_last_name'} />}
          />
          <Field
            disabled
            name="email"
            id="email"
            component={renderTextField}
            type="text"
            label={<FormattedMessage id={'auth_email'} />}
          />
          <div className={'profile-editor-save-btn'}>
            <Button variant="contained" type={'submit'}>
              <FormattedMessage id={'petition_save'} />
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

ProfileEditor.propTypes = {};

export default reduxForm({
  form: 'profile-editor',
  enableReinitialize: true
})(
  connect(
    null,
    { onPatchProfile },
  )(ProfileEditor),
);
