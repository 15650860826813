import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage } from 'react-intl';
import TableHead from '@material-ui/core/TableHead';

const ProductsTablePetitionHead = props => {
  return (
    <TableHead>
      <TableRow>
        <TableCell/>
        <TableCell>
          <FormattedMessage id={'products_ref'} />
        </TableCell>
        <TableCell>
          <FormattedMessage id={'petition_product_name'} />
        </TableCell>
        <TableCell>
          <FormattedMessage id={'petition_product_type'} />
        </TableCell>
        <TableCell>
          <FormattedMessage id={'petition_lot_number'} />
        </TableCell>
        <TableCell>
          <FormattedMessage id={'petition_manufactured'} />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

ProductsTablePetitionHead.propTypes = {};

export default ProductsTablePetitionHead;
