import { FormattedMessage } from 'react-intl';
import React from 'react';

export default [[
  {
    title: <FormattedMessage id="navigation_home" />,
    route: '/',
    icon: "home"
  },
  {
    title: <FormattedMessage id={'navigation_services'}/>,
    route: '/services',
    icon: "list"
  },
  {
    title: <FormattedMessage id={'navigation_petitions'}/>,
    route: '/petitions',
    icon: "layers"
  },
  {
    title: <FormattedMessage id={'navigation_projects'}/>,
    route: '/projects',
    icon: "layers"
  },
  {
    title: <FormattedMessage id={'navigation_products'}/>,
    route: '/products',
    icon: "layers"
  }
],[
  {
    title: <FormattedMessage id={'navigation_messages'}/>,
    route: '/messages',
    icon: "envelope"
  },
  {
    title: <FormattedMessage id={'navigation_notifications'}/>,
    route: '/notifications',
    icon: "alarm"
  },
  {
    title: <FormattedMessage id={'navigation_company_profile'}/>,
    route: '/company',
    icon: "briefcase"
  },
]]