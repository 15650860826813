import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { FormattedMessage } from 'react-intl';

const RenderCheckboxGroupWithNull = ({
  options,
  input,
  meta: { touched, error },
}) => {
  return (
    <>
      <Checkbox
        name={`${input.name}[-1]`}
        value={null}
        checked={!input.value || input.value.length === 0}
        onChange={event => {
          return input.onChange([]);
        }}
      />
      <label>
        <FormattedMessage id={'indifferent'} />
      </label>
      {options.map((option, index) => (
        <>
          <Checkbox
            name={`${input.name}[${index}]`}
            value={option.id}
            checked={input.value.indexOf(option.id) !== -1}
            onChange={event => {
              const newValue = [...input.value];
              if (event.target.checked) {
                newValue.push(option.id);
              } else {
                newValue.splice(newValue.indexOf(option.id), 1);
              }
              return input.onChange(newValue);
            }}
          />
          <label>{option.value}</label>
        </>
      ))}
    </>
  );
};

RenderCheckboxGroupWithNull.propTypes = {};

export default RenderCheckboxGroupWithNull;
