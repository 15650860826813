import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

const RenderCheckboxGroup = ({ options, input, meta: { touched, error } }) => {
  return (
    <>
      {options.map((option, index) => (
        <>
          <Checkbox
            name={`${input.name}[${index}]`}
            value={option.id}
            checked={input.value.indexOf(option.id) !== -1}
            onChange={event => {
              const newValue = [...input.value];
              if (event.target.checked) {
                newValue.push(option.id);
              } else {
                newValue.splice(newValue.indexOf(option.id), 1);
              }
              return input.onChange(newValue);
            }}
          />
          <label>{option.value}</label>
        </>
      ))}
    </>
  );
};

RenderCheckboxGroup.propTypes = {};

export default RenderCheckboxGroup;
