import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withAuthWrapper from '../common/withAuthWrapper';
import image from './illustration_reset_pass.png';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import './password-recovered.scss';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    flexFlow: 'column wrap',
  },
  item: {
    flexBasis: 'initial',
    // flexGrow: 1,
    display: 'inline-flex',
    margin: '2rem 0',
  },
});

class PasswordRecovered extends Component {
  render() {
    const { email, classes } = this.props;
    return (
      <div className={'password-recovered-root'}>
        <Grid
          item
          xs={12}
          className={classes.item}
          style={{
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Typography variant="headline">Restablece tu contraseña</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.item}
          style={{
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <img src={image} />
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.item}
          style={{
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Typography align="center">
            Te hemos enviado un email a <strong>{email}</strong> donde
            encontrarás el acceso para restablecer tu contraseña.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.item}
          style={{
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Button
            type="submit"
            variant="contained"
            component={Link}
            to="/auth/login"
          >
            OK
          </Button>
        </Grid>
      </div>
    );
  }
}

PasswordRecovered.propTypes = {};

const mapStateToProps = state => ({
  email: state.auth.recoveredEmail,
});

export default withAuthWrapper(
  withStyles(styles)(connect(mapStateToProps)(PasswordRecovered)),
);
