import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import '../../pages/NewPetitionPage/styles.scss';
import ProjectHeader from '../../pages/NewPetitionPage/components/ProjectHeader';
import {
  onPatchProject,
  onPetitionValidate,
} from '../../redux/actions/petitionHandlerActions';

import RegulatoryService from './../../pages/NewPetitionPage/containers/RegulatoryService';
import SafetyAndEfficacyService from '../../pages/NewPetitionPage/containers/SafetyAndEfficacyService';
import OtherServices from '../../pages/NewPetitionPage/containers/OtherServices';
import ConsultationService from '../../pages/NewPetitionPage/containers/ConsultationService';
import SecurityPackService from '../../pages/NewPetitionPage/containers/SecurityPackService';

const styles = {
  addIcon: {
    color: '#70BBFD',
  },
};

class ProjectCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.renderPanels = this.renderPanels.bind(this);
  }

  checkServiceSafetyAndEfficacy = values => {
    const nextValues = { ...values };
    if (values.gender === 'female' || values.gender === 'male') {
      nextValues.gender_settings_who = null;
      nextValues.gender_settings_how = null;
      nextValues.gender_settings_value = null;
    }
    if (!values.sensitive_face_skin_value) {
      nextValues.sensitive_face_skin_type_value = null;
      nextValues.sensitive_face_skin_value = null;
    }
    if (!values.sensitive_body_skin_value) {
      nextValues.sensitive_body_skin_type_value = null;
      nextValues.sensitive_body_skin_value = null;
    }
    if (!values.contact_lenses_value) {
      nextValues.contact_lenses_type_value = null;
      nextValues.contact_lenses_value = null;
    }
    if (values.volunteers_number) {
      nextValues.volunteers_number = parseInt(values.volunteers_number);
    }
    return nextValues;
  };

  submitProject = values => {
    const { onPatchProject } = this.props;
    onPatchProject(
      values.service === 2 || values.service === 3
        ? this.checkServiceSafetyAndEfficacy(values)
        : values,
      true
    );
  };

  renderPanels() {
    const { project, id, products } = this.props;
    const type = this.props.project.service;
    switch (type) {
      // Regulatory
      case 1:
        return (
          <RegulatoryService
            onSubmit={this.submitProject}
            initialValues={project}
            docs={project.attachments}
            project={project}
            submitProject={this.submitProject}
            products={products}
          />
        );
      // SafetyAndEfficacy
      case 2:
      // Consumers
      case 3:
        return (
          <SafetyAndEfficacyService
            onSubmit={this.submitProject}
            initialValues={project}
            docs={project.attachments}
            project={project}
            submitProject={this.submitProject}
            products={products}
          />
        );
      // Other services
      case 4:
        return (
          <OtherServices
            onSubmit={this.submitProject}
            initialValues={project}
            docs={project.attachments}
            project={project}
            submitProject={this.submitProject}
            products={products}
          />
        );
      // Consultation
      case 5:
        return (
          <ConsultationService
            onSubmit={this.submitProject}
            initialValues={project}
            docs={project.attachments}
            project={project}
            submitProject={this.submitProject}
            products={products}
          />
        );
      // Security pack
      case 6:
        return (
          <SecurityPackService
            onSubmit={this.submitProject}
            initialValues={project}
            docs={project.attachments}
            project={project}
            submitProject={this.submitProject}
            products={products}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { index, project, services } = this.props;
    const projectType = project.project_type
      ? services
          .find(s => s.id === project.service)
          .project_types.find(p => p.id === project.project_type).name
      : '';
    const serviceName = services.find(s => s.id === project.service).name;
    return (
      <section className="new-petition-section">
        <ProjectHeader
          projectType={projectType}
          name={`PROYECTO ${index + 1}`}
          service={serviceName}
        />
        {this.renderPanels()}
      </section>
    );
  }
}

const mapStateToProps = state => ({
  ...state.petitions,
  services: state.petition.services,
});

export default connect(
  mapStateToProps,
  { onPatchProject, onPetitionValidate }, // { onMountPetition },
)(withStyles(styles)(ProjectCard));
