import { all, take, put, select } from 'redux-saga/effects';
import { getAxios } from '../util';
import localforage from 'localforage';
import { push } from 'connected-react-router';

export const GET_QUESTIONNAIRE_BY_ID = 'GET_QUESTIONNAIRE_BY_ID';
export const SET_QUESTIONNAIRE_BY_ID = 'SET_QUESTIONNAIRE_BY_ID';
export const PATCH_QUESTIONNAIRE_BY_ID = 'PATCH_QUESTIONNAIRE_BY_ID';

export const getQuestionnaireById = values => ({
  type: GET_QUESTIONNAIRE_BY_ID,
  payload: values,
});

export const patchQuestionnaireById = values => ({
  type: PATCH_QUESTIONNAIRE_BY_ID,
  payload: values,
});

const getQuestionnaireByIdSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(GET_QUESTIONNAIRE_BY_ID);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.get(
        `${process.env.REACT_APP_API_URL}questionnaires/${payload.id}/`,
      );
      yield put({
        type: SET_QUESTIONNAIRE_BY_ID,
        payload: data,
      });
    } catch (e) {
      // TODO catch e
      console.log('getQuestionnaireByIdSaga', e);
    }
  }
};

const patchQuestionnaireByIdSaga = function*() {
  while (true) {
    try {
      const { payload } = yield take(PATCH_QUESTIONNAIRE_BY_ID);
      const { token } = yield localforage.getItem('auth');
      const ax = yield getAxios(token);
      const { data } = yield ax.patch(
        `${process.env.REACT_APP_API_URL}questionnaires/${payload.id}/`,
        payload.data,
      );
      yield put(push(`/projects/${data.project}`));
    } catch (e) {
      // TODO catch e
      console.log('patchQuestionnaireByIdSaga', e);
    }
  }
};

export default function*() {
  yield all([getQuestionnaireByIdSaga(), patchQuestionnaireByIdSaga()]);
}
