import initialState from './initialState';
import injectReducer from './injectReducer';
import {
  SOCKET_CLEAR_ERROR,
  SOCKET_CLEAR_NOTIFICATION,
  SOCKET_ERROR,
  SOCKET_NEW_MESSAGE,
  SOCKET_PUSH_MESSAGE,
  SOCKET_SET_CHAT_BY_ID,
} from '../types';

const handlers = {
  [SOCKET_PUSH_MESSAGE]: (state, { payload }) => {
    let nextMessages = [...state.chats[payload.id]];
    nextMessages.push(payload.message);
    return {
      ...state,
      chats: {
        ...state.chats,
        [payload.id]: nextMessages,
      },
    };
  },
  [SOCKET_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [SOCKET_SET_CHAT_BY_ID]: (state, { payload }) => ({
    ...state,
    chats: {
      ...state.chats,
      [payload.id]: payload.results,
    },
  }),
  [SOCKET_NEW_MESSAGE]: (state, { payload }) => ({
    ...state,
    notification: payload,
  }),
  [SOCKET_CLEAR_NOTIFICATION]: (state, { payload }) => ({
    ...state,
    notification: null,
  }),
  [SOCKET_CLEAR_ERROR]: (state, { payload }) => ({
    ...state,
    error: null,
  }),
};

export default injectReducer(initialState.socketReducer, handlers);
