import React, { Component } from 'react';
// import LeftMenuAppBar from "../../containers/LeftMenuAppBar";
import { servicesListData } from '../../data/Data';
import ServicesPageItem from './ServicesPageItem';
import BlueButton from '../../components/BlueButton';
import PageHeader from '../../components/PageHeader';

import './styles.scss';
import { Grid } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';

class ServicesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { intl } = this.props;
    const items = [];

    for (let i = 0; i < servicesListData.length; i += 1) {
      const item = servicesListData[i];
      items.push(
        <Grid key={`services-page-item-${i}`} item xs={12} md={4}>
          <ServicesPageItem
            key={`services-page-item-${i}`}
            servPageIcon={item.servPageIcon}
            title={item.title}
            desc={item.desc}
            btnText={
              <a
                target={'_blank'}
                style={{ textDecoration: 'none' }}
                href="https://cosmocare.eu/services"
              >
                {intl.formatMessage({ id: 'see_more_btn' })}
              </a>
            }
          />
        </Grid>,
      );
    }

    return (
      <>
        <PageHeader title="Servicios" />
        <Grid container spacing={40} style={{width: "100%"}}>
          {items}
        </Grid>
        {/*<div className="services-page-items-wrapper">{items}</div>*/}
      </>
    );
  }
}

export default injectIntl(ServicesPage);
