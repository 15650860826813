import React from 'react';
import PropTypes from 'prop-types';
import QPage from '../../organisms/QPage';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { patchQuestionnaireById } from '../../../redux/actions/questionnaireActions';
import { connect } from 'react-redux';

const QuestionnaireTemplate = ({
  questionnaire,
  project,
  patchQuestionnaireById,
}) => {
  console.log('questionnaire', questionnaire);
  return (
    <div>
      {Array.isArray(questionnaire.pages) &&
        questionnaire.pages.map(page => (
          <QPage key={`page${page.name}${page.id}`} page={page} />
        ))}
      {!questionnaire.status && (
        <Button
          onClick={() =>
            patchQuestionnaireById({
              id: questionnaire.id,
              data: { status: 'validated_by_client' },
            })
          }
        >
          <FormattedMessage id={'accept'} />
        </Button>
      )}
    </div>
  );
};

QuestionnaireTemplate.propTypes = {};

export default connect(
  null,
  { patchQuestionnaireById },
)(QuestionnaireTemplate);
