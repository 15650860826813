import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import renderTextField from '../helpers/renderTextField';
import { FormattedMessage } from 'react-intl';
import { Field, getFormValues } from 'redux-form';
import renderNumericTextField from '../helpers/renderNumericTextField';
import classNames from 'classnames';
import { connect } from 'react-redux';
import renderSelectField from '../helpers/renderSelectField';
import renderCheckbox from '../helpers/renderCheckbox';
import RenderCheckboxGroup from '../helpers/renderCheckboxGroup';
import TextField from '@material-ui/core/TextField';
import RenderCheckboxGroupWithNull from '../helpers/renderCheckboxGroupWithNull';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
  bold: {
    fontWeight: 'bold',
  },
  blockMargin: {
    marginBottom: '24px',
    marginTop: '24px',
  },
  ageComment: {
    color: '#bdbdbd',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  numericTextField: {
    width: '20%',
  },
  textMargin: {
    margin: '0 8px',
  },
};

const renderPercentCheckbox = name => (
  <Field name={name} component={renderSelectField}>
    <option value={'max'}>máx</option>
    <option value={'min'}>min</option>
    <option value={'percent'}>% preciso</option>
  </Field>
);

const renderNumericTextFieldWithPercent = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <>
    <TextField
      style={{ width: '64px' }}
      {...input}
      {...custom}
      type={'number'}
      min={0}
    />
    <b>%</b>
  </>
);

const PanelForm = ({ classes, formValues, common, change, form }) => {
  const renderGenderFilter = disabled => (
    <>
      <Field
        classes={classes}
        name="gender_settings_type"
        component={renderSelectField}
        value={formValues.gender_settings_value ? 'set' : 'none'}
      >
        <option value={'none'}>% Indiferente</option>
        <option value={'set'}>Especificar %</option>
      </Field>
      {(formValues.gender_settings_type === 'set' ||
        Number.isInteger(formValues.gender_settings_value)) && (
        <>
          {renderPercentCheckbox('gender_settings_how')}
          <Field
            className={classes.numericTextField}
            name="gender_settings_value"
            component={renderNumericTextField}
            width={'128px'}
          />
        </>
      )}
    </>
  );

  return (
    <div>
      {/*petition_comments*/}
      <div className={classes.blockMargin}>
        <div>
          <span className={classes.bold}>
            <FormattedMessage id={'volunteers_number'} />
          </span>
        </div>
        <Field name="volunteers_number" component={renderNumericTextField} />
      </div>
      {/*gender*/}
      <div className={classes.blockMargin}>
        <div>
          <span className={classes.bold}>
            <FormattedMessage id={'panel_sex'} />
          </span>
          <div>
            <label>
              <Field name="gender" component="input" type="radio" value="all" />
              <span>
                <FormattedMessage id={'panel_sex_all'} />
              </span>
            </label>
          </div>
          <div>
            <label>
              <Field
                name="gender"
                component="input"
                type="radio"
                value="female"
              />
              <span>
                <FormattedMessage id={'panel_sex_female_100'} />
              </span>
            </label>
          </div>
          <div>
            <label>
              <Field
                name="gender"
                component="input"
                type="radio"
                value="male"
              />
              <span>
                <FormattedMessage id={'panel_sex_male_100'} />
              </span>
            </label>
          </div>
          {formValues.gender === 'all' && (
            <div>
              <div className={classes.flexCenter}>
                <label>
                  <Field
                    name="gender_settings_who"
                    component="input"
                    type="radio"
                    value="female"
                  />
                  <span>
                    <FormattedMessage id={'panel_sex_female'} />
                  </span>
                </label>
                {formValues.gender_settings_who === 'female' &&
                  renderGenderFilter()}
              </div>
              <div className={classes.flexCenter}>
                <label>
                  <Field
                    name="gender_settings_who"
                    component="input"
                    type="radio"
                    value="male"
                  />
                  <span>
                    <FormattedMessage id={'panel_sex_male'} />
                  </span>
                </label>
                {formValues.gender_settings_who === 'male' &&
                  renderGenderFilter()}
              </div>
            </div>
          )}
        </div>
        {/*age*/}
        <div className={classes.blockMargin}>
          <div>
            <span className={classes.bold}>
              <FormattedMessage id={'panel_age'} />
            </span>
          </div>
          <div className={classes.flexCenter}>
            <span className={classes.textMargin}>De</span>
            <Field
              name="age_start"
              component={renderNumericTextField}
              width={'64px'}
              defaultValue={18}
            />
            <span className={classes.textMargin}>a</span>
            <Field
              width={'64px'}
              name="age_end"
              component={renderNumericTextField}
            />
            <span className={classes.textMargin}>años</span>
          </div>
          <div className={classes.ageComment}>
            <FormattedMessage id={'panel_age_comment'} />
          </div>
        </div>
        <div className={classes.blockMargin}>
          <div>
            <span className={classes.bold}>
              <FormattedMessage id={'physical_characteristics'} />
            </span>
          </div>
          <div className={classes.blockMargin}>
            <div>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_body_skin_type'} />
              </span>
            </div>
            {common.body_skin_nature && (
              <Field
                name={'body_skin_type'}
                component={RenderCheckboxGroupWithNull}
                options={common.body_skin_nature}
              />
            )}
          </div>
          <div className={classes.blockMargin}>
            <div>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_face_skin_type'} />
              </span>
            </div>
            <div>
              {common.skin_nature && (
                <Field
                  name={'face_skin_type'}
                  component={RenderCheckboxGroupWithNull}
                  options={common.skin_nature}
                />
              )}
            </div>
          </div>
          <div className={classes.blockMargin}>
            <div>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_hair_type'} />
              </span>
            </div>
            <div>
              {common.hair_type && (
                <Field
                  name={'hair_type'}
                  component={RenderCheckboxGroupWithNull}
                  options={common.hair_type}
                />
              )}
            </div>
          </div>
          <div className={classes.blockMargin}>
            <div>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_scalp_type'} />
              </span>
            </div>
            <div>
              {common.hair_and_scalp_nature && (
                <Field
                  name={'head_skin_type'}
                  component={RenderCheckboxGroupWithNull}
                  options={common.hair_and_scalp_nature}
                />
              )}
            </div>
          </div>
          {/*atopy*/}
          <div className={classes.blockMargin}>
            <label className={classes.bold}>
              <FormattedMessage id={'panel_atopy'} />
            </label>
            {renderPercentCheckbox('atopy')}
            <Field
              name="atopy_value"
              component={renderNumericTextFieldWithPercent}
              defaultValue={25}
            />
          </div>
          {/*sensitive*/}
          <div className={classes.blockMargin}>
            {/*sensitive_face_skin*/}
            <div>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_sensitivity'} />
              </span>
            </div>
            <div className={classes.flexCenter}>
              <Field
                name={'sensitive_face_skin'}
                component={renderCheckbox}
                label={<FormattedMessage id={'panel_sensitive_face_skin'} />}
              />
              {formValues.sensitive_face_skin && (
                <>
                  {renderPercentCheckbox('sensitive_face_skin_type_value')}
                  <Field
                    name="sensitive_face_skin_value"
                    component={renderNumericTextFieldWithPercent}
                    defaultValue={60}
                  />
                </>
              )}
            </div>
            {/*sensitive_body_skin*/}
            <div className={classes.flexCenter}>
              <Field
                name={'sensitive_body_skin'}
                component={renderCheckbox}
                label={<FormattedMessage id={'panel_sensitive_body_skin'} />}
              />
              {formValues.sensitive_body_skin && (
                <>
                  {renderPercentCheckbox('sensitive_body_skin_type_value')}
                  <Field
                    name="sensitive_body_skin_value"
                    component={renderNumericTextFieldWithPercent}
                    defaultValue={40}
                  />
                </>
              )}
            </div>
            {/*sensitive_scalp_skin*/}
            <div className={classes.flexCenter}>
              <Field
                name={'sensitive_scalp_skin'}
                component={renderCheckbox}
                label={<FormattedMessage id={'panel_sensitive_scalp_skin'} />}
              />
              {formValues.sensitive_scalp_skin && (
                <>
                  {renderPercentCheckbox('sensitive_scalp_skin_type_value')}
                  <Field
                    name="sensitive_scalp_skin_value"
                    component={renderNumericTextFieldWithPercent}
                    defaultValue={40}
                  />
                </>
              )}
            </div>
          </div>
          {/*skin_phototype*/}
          <div className={classes.blockMargin}>
            <div>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_skin_phototype'} />
              </span>
            </div>
            <div>
              <Field
                name={'skin_phototype_reset'}
                component={({ input, label }) => (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            !formValues.skin_phototype_1 &&
                            !formValues.skin_phototype_2 &&
                            !formValues.skin_phototype_3 &&
                            !formValues.skin_phototype_4
                          }
                          onChange={event => {
                            if (event.target.checked) {
                              change(form, 'skin_phototype_1', false);
                              change(form, 'skin_phototype_2', false);
                              change(form, 'skin_phototype_3', false);
                              change(form, 'skin_phototype_4', false);
                              input.onChange(true);
                            }
                          }}
                        />
                      }
                      label={label}
                    />
                  </div>
                )}
                label={<FormattedMessage id={'indifferent'} />}
              />
              <Field
                name={'skin_phototype_1'}
                component={renderCheckbox}
                label={'Fototipo 1'}
              />
              <Field
                name={'skin_phototype_2'}
                component={renderCheckbox}
                label={'Fototipo 2'}
              />
              <Field
                name={'skin_phototype_3'}
                component={renderCheckbox}
                label={'Fototipo 3'}
              />
              <Field
                name={'skin_phototype_4'}
                component={renderCheckbox}
                label={'Fototipo 4'}
              />
            </div>
          </div>
          {/*sensitive_eyes*/}
          <div className={classes.blockMargin}>
            <div className={classes.flexCenter}>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_eyes_type'} />
              </span>
              {renderPercentCheckbox('sensitive_eyes_type_value')}
              <Field
                name="sensitive_eyes_value"
                component={renderNumericTextFieldWithPercent}
              />
            </div>
          </div>
          {/*contact_lenses*/}
          <div className={classes.blockMargin}>
            <div className={classes.flexCenter}>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_contact_lenses'} />
              </span>
              {renderPercentCheckbox('contact_lenses_type_value')}
              <Field
                name="contact_lenses_value"
                component={renderNumericTextFieldWithPercent}
              />
            </div>
          </div>
          {/*other_characteristics*/}
          <div className={classes.blockMargin}>
            <div>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_other_characteristics'} />
              </span>
              <div>
                <Field
                  name={'other_characteristics'}
                  component={renderTextField}
                />
              </div>
            </div>
          </div>
          {/*additional_observations*/}
          <div className={classes.blockMargin}>
            <div>
              <span className={classes.bold}>
                <FormattedMessage id={'panel_additional_observations'} />
              </span>
              <div>
                <Field
                  name={'additional_observations'}
                  component={renderTextField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PanelForm.propTypes = {};

const mapStateToProps = state => ({
  formValues: getFormValues('SafetyAndEfficacyService')(state),
  common: state.dataLoader.common,
});

export default withStyles(styles)(connect(mapStateToProps)(PanelForm));
