import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';

const ResumeInformation = ({ comment }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <b>
          <FormattedMessage id={'petition_comment'} />:{' '}
        </b>
        {comment}
      </Grid>
    </Grid>
  );
};

ResumeInformation.propTypes = {

};

export default ResumeInformation;