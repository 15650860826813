import initialState from './initialState';
import injectReducer from './injectReducer';
import { SET_QUESTIONNAIRE_BY_ID } from '../actions/questionnaireActions';

const handlers = {
  [SET_QUESTIONNAIRE_BY_ID]: (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }),
};

export default injectReducer(initialState.questionnaireReducer, handlers);
