import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const Loader = () => {
  return (
    <Grid container direction="row" justify="center" alignItems="center" style={{height: '40vh', flexGrow: 1}}>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Loader;
