import validator from 'validator';
import localization from '../../../../app/localization/valid_localization';

const validate = props => {
  const { email } = props;
  const lang = localStorage.getItem('lang');
  const errors = {};
  if (!email) errors.email = localization[lang].auth_is_email;
  else if (!validator.isEmail(email))
    errors.email = localization[lang].auth_email;

  return errors;
};

export default validate;
