function getNumberFromProgress(progress) {
  switch (progress) {
    case 'draft':
      return 1;
    case 'resume':
      return 2;
    case 'wait':
      return 3;
    case 'validate':
      return 4;
    case 'payment':
      return 5;
    case 'in_progress':
      return 6;
    case 'done':
      return 7;
    default:
      return 0;
  }
}

function getProgressFromNumber(number) {
  switch (number) {
    case 1:
      return 'draft';
    case 2:
      return 'resume';
    case 3:
      return 'wait';
    case 4:
      return 'validate';
    case 5:
      return 'payment';
    case 6:
      return 'in_progress';
    case 7:
      return 'done';
    default:
      return 'none';
  }
}

export { getNumberFromProgress, getProgressFromNumber };
