import React, { Component } from 'react';
import './styles.scss';
import Index from '../BlueButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { servicesListData } from '../../data/Data';
import {FormattedMessage} from "react-intl";

class ServicesList extends Component {
  render() {
    const dataArr = servicesListData;
    let items = [];

    for (let i = 0; i < dataArr.length; i++) {
      let item = dataArr[i];
      items.push(
        <ListItem key={`list-item-${i}`}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText>{item.title}</ListItemText>
          <ListItemSecondaryAction>
            <Index
              active={true}
              styles={{
                padding: '0px 20px',
                height: '28px',
                lineHeight: '17px',
                fontWeight: '200',
                margin: '0px',
                textAlign: 'center',
              }}
            >
              <a
                style={{ textDecoration: 'none' }}
                href="https://cosmocare.eu/services"
              >
                <FormattedMessage id={'see_more_btn'}/>
              </a>
            </Index>
          </ListItemSecondaryAction>
        </ListItem>,
      );
    }

    return (
      <div className={'services-list'}>
        <h5>Servicios</h5>
        <List>{items}</List>
      </div>
    );
  }
}

export default ServicesList;
