export default {
  en: {
    auth_register: 'Email exists',
    auth_login: 'Authentication error',
    onmount: 'Get data from server failed',
    petition_create: 'Error create petition',
  },
  es: {
    auth_register: 'Existe el email',
    auth_login: 'Error de autenticación',
    onmount: 'Error al obtener datos del servidor',
    petition_create: 'Error crear petición',
  },
};
