import React, { PureComponent } from 'react';
import ReactDataGrid from 'react-data-grid';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core';

import './styles.scss';
import { Link } from 'react-router-dom';
import {getNumberFromProgress} from "../../helpers/progress";

const styles = {
  searchIcon: {
    fill: '#555555',
    fontWeight: 100,
    fontSize: '18px',
    position: 'absolute',
    right: '5px',
  },
};

class PetitionsTable extends PureComponent {
  render() {
    const { classes, items } = this.props;

    const columns = [
      { key: 'petition_number', name: 'N petición' },
      { key: 'project_type', name: 'Tipo de Proyecto' },
      { key: 'product_name', name: 'Producto' },
      {
        key: 'products',
        name: 'Products',
        formatter: ({ value, row }) => {
          let str = ``;
          if (Array.isArray(value)) {
            value.map(product => (str += `${product.name} `));
          }
          return str;
        },
      },
      { key: 'product_ref', name: 'Ref. Producto' },
      { key: 'petition_date', name: 'Fecha petición' },
      { key: 'project_state', name: 'Estado del proyecto' },
      {
        key: 'id',
        name: '',
        formatter: ({ value, row }) => {
          const { progress } = row;
          return <Link to={getNumberFromProgress(progress) === 3 ? `/petition/${value}` : `/petition/${value}/edit`}>ver petición</Link>;
        },
      },
    ];

    const rows = [];

    for (let i = 0, n = items.length; i < n; i += 1) {
      console.log(items[i]);
      rows.push({
        id: items[i].id,
        petition_number: items[i].id,
        project_type: items[i].project_type,
        product_name: items[i].product_name,
        product_ref: items[i].product_ref,
        petition_date: items[i].petition_date,
        project_state: items[i].project_state,
        products: items[i].products,
        ver_ficha: items[i].ver_ficha ? 'ver petición' : '',
        progress: items[i].progress
      });
    }

    return (
      <div className="projects-table">
        <h5>listado de peticiones</h5>
        <div className="projects-table-options">
          <div className="items-per-page">
            <span>Mostrar</span>
            <input
              type="number"
              name="items_per_page"
              id="items_per_page"
              placeholder="10"
            />
            <span>entradas</span>
          </div>
          <div className="table-search">
            <input type="text" name="search_field" placeholder="Buscar..." />
            <SearchIcon className={classes.searchIcon} />
          </div>
        </div>
        <ReactDataGrid
          pagination
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          minHeight={470}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PetitionsTable);
