import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { submit } from 'redux-form';

import PageHeader from '../../components/PageHeader';
import CardHeader from '../../components/CardHeader';
import CustomizedExpansionPanel from '../../pages/NewPetitionPage/components/CustomizedExpansionPanel';
import ProductsTable from '../../pages/NewPetitionPage/components/ProductsTable';

import '../../pages/NewPetitionPage/styles.scss';
import ProjectCard from '../organisms/ProjectCard';
import {
  onDeleteProject,
  onPatchProduct,
  onPatchProject,
  onPetitionAddProduct,
  onPetitionCreateProject,
  onPetitionHandlerReset,
  onPetitionValidate,
  petitionCreateDraft,
} from '../../redux/actions/petitionHandlerActions';
import ResumeBlockContainer from '../../containers/ResumeBlockContainer';
import {
  ResumeHeader,
  ResumeProducts,
  ResumeProjectHeader,
} from '../../components/Resume';
import ResumeProjectHandler from '../../containers/ResumeProjectHandler';
import Button from '@material-ui/core/Button';
import { FormattedMessage, FormattedPlural } from 'react-intl';

import _ from 'lodash';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Done from '@material-ui/icons/Done';
import Save from '@material-ui/icons/Save';
import CreditCard from '@material-ui/icons/CreditCard';
import Add from '@material-ui/icons/Add';

import { isPristine } from 'redux-form';
import {
  getNumberFromProgress,
  getProgressFromNumber,
} from '../../helpers/progress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { onDeletePetition } from '../../redux/actions/petitionActions';
import Chat from '../../views/organisms/Chat';
import Grid from '@material-ui/core/Grid';
import ProductsTablePetition from '../organisms/ProductsTablePetition';

const styles = {
  addIcon: {
    color: '#70BBFD',
  },
};

// TODO Petitions pagination.

class NewPetitionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countProjects: 0,
      projectSelect: -1,
      validate: false,
      currentProjects: this.props.petition.projects || [],
      nextProjects: [],
      deletePetitionOpen: false,
      nextProduct: false,
    };

    this.onClickAddProject = this.onClickAddProject.bind(this);
    this.onClickSelectProject = this.onClickSelectProject.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
    this.renderProjects = this.renderProjects.bind(this);
    this.renderProjectsTabs = this.renderProjectsTabs.bind(this);
    this.onClickNextProject = this.onClickNextProject.bind(this);
  }

  onClickAddProject() {
    let { countProjects } = this.state;
    const { onPetitionCreateProject, petition } = this.props;
    this.setState(state => ({
      countProjects: (countProjects += 1),
      currentProjects: [...state.currentProjects, {}],
    }));
    onPetitionCreateProject({ petition: petition.id });
  }

  onClickNextProject() {
    this.setState(
      state => ({
        nextProjects: [...state.nextProjects, {}],
      }),
      () => {
        const root = document.getElementsByTagName('html')[0];
        root.scrollTop = root.scrollHeight;
      },
    );
  }

  onClickSelectProject(e) {
    const {
      submit,
      petition: { projects },
    } = this.props;
    const { projectSelect } = this.state;
    if (e !== projectSelect && projectSelect !== -1) {
      this.submitProjectSw(projects[projectSelect].service);
      // submit('SafetyAndEfficacyService');
      // submit('RegulatoryService');
      // submit('OtherServices');
      this.setState({
        projectSelect: parseInt(e),
      });
    } else if (e !== projectSelect) {
      this.setState({
        projectSelect: parseInt(e),
      });
    }
  }

  deleteProject(id) {
    // console.log('id=', id);
    let { countProjects } = this.state;
    this.setState(state => ({
      countProjects: (countProjects -= 1),
      nextProjects: state.nextProjects.filter((project, index) => index !== id),
    }));
  }

  renderProjects() {
    const { countProjects } = this.state;

    if (countProjects === 0) {
      return <p />;
    }

    const projects = [];
    for (let i = 0; i < countProjects; i += 1) {
      projects.push(
        <CustomizedExpansionPanel
          key={i}
          name={`${<FormattedMessage id={'petition_project'} />} ${i + 1}`}
          onDelete={this.deleteProject.bind(null, i)}
        />,
      );
    }
    return <div>{projects}</div>;
  }

  submitProject = values => {
    console.log(values);
  };

  renderProjectsTabs() {
    const { projectSelect } = this.state;
    const { projects } = this.props.petition;
    const renderProjects = projects.map((project, index) => (
      <button
        key={index}
        data-index={index + 1}
        className={projectSelect === index ? 'green-btn' : 'green-empty-btn'}
        onClick={() => this.onClickSelectProject(index)}
      >
        {
          <>
            <FormattedMessage id={'petition_project'} />
            {index + 1}
          </>
        }
      </button>
    ));
    return <div>{renderProjects}</div>;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { projectSelect, validate } = this.state;
    const {
      petition,
      onPetitionValidate,
      isPristineRegulatoryService,
      isPristineSafetyAndEfficacyService,
      isPristineOtherServices,
      isPristineSecurityPackService,
      isPristineConsultationService,
    } = this.props;

    const callValidate = () => {
      this.setState({
        validate: false,
        nextProjects: [],
      });
      onPetitionValidate({ ...petition, progress: getProgressFromNumber(2) });
    };

    if (
      projectSelect > -1 &&
      validate &&
      (!_.isEqual(petition, prevProps.petition) ||
        (isPristineSafetyAndEfficacyService &&
          isPristineRegulatoryService &&
          isPristineOtherServices &&
          isPristineSecurityPackService &&
          isPristineConsultationService))
    ) {
      callValidate();
    }

    if (projectSelect === -1 && validate) {
      callValidate();
    }
    if (
      this.props.petition.projects.length > prevProps.petition.projects.length
    ) {
      this.setState({
        projectSelect: this.props.petition.projects.length - 1,
      });
      const root = document.getElementsByTagName('html')[0];
      root.scrollTop = 0;
    }
  }

  submitProjectSw = service => {
    const { submit } = this.props;
    switch (service) {
      case 1:
        submit('RegulatoryService');
        break;
      case 2:
      case 3:
        submit('SafetyAndEfficacyService');
        break;
      case 4:
        submit('OtherServices');
        break;
      case 5:
        submit('ConsultationService');
        break;
      case 6:
        submit('SecurityPackService');
        break;
    }
  };

  validatePetition = () => {
    const { projectSelect } = this.state;
    const {
      submit,
      petition: { projects },
    } = this.props;
    this.setState({ validate: true });
    if (projectSelect > -1) {
      this.submitProjectSw(projects[projectSelect].service);
    }
  };

  componentWillUnmount() {
    let { validate, projectSelect } = this.state;
    let {
      petitionCreateDraft,
      petition,
      petition: { projects },
      onPetitionValidate,
      onPetitionHandlerReset,
    } = this.props;
    const callValidate = () => {
      this.setState({
        validate: false,
        nextProjects: [],
      });
      onPetitionValidate({ ...petition, restrictPush: true });
    };
    if (projectSelect === -1 && !validate) {
      callValidate();
    }
    if (projectSelect > -1 && !validate) {
      this.submitProjectSw(projects[projectSelect].service);
    }
    onPetitionHandlerReset();
  }

  acceptResume = () => {
    const { petition, onPetitionValidate } = this.props;
    onPetitionValidate({ ...petition, progress: getProgressFromNumber(4) });
  };

  declineResume = () => {
    const { petition, onPetitionValidate } = this.props;
    onPetitionValidate({ ...petition, progress: getProgressFromNumber(1) });
  };

  addProductToPetition = values => {
    const { onPetitionAddProduct, petition } = this.props;
    console.log('addProductToPetition', values);
    onPetitionAddProduct({
      ...values,
      petitions: [petition.id],
    });
    this.setState({
      nextProduct: false,
    });
  };

  patchProduct = values => {
    const { onPatchProduct, petition } = this.props;
    console.log('patchProduct', values);
    onPatchProduct({
      ...values,
      petitions: [petition.id],
    });
  };

  handleClickOpen = () => {
    this.setState({ deletePetitionOpen: true });
  };

  handleClose = () => {
    this.setState({ deletePetitionOpen: false });
  };

  handleDeletePetition = values => {
    const { onDeletePetition, petition } = this.props;
    onDeletePetition({ id: petition.id });
  };

  startNextProduct = () => {
    this.setState({
      nextProduct: true,
    });
  };

  render() {
    const { projectSelect, validate, nextProjects, nextProduct } = this.state;
    const {
      classes,
      products,
      services,
      petition,
      error,
      onDeleteProject,
    } = this.props;

    const {
      products: addedProducts,
      projects: currentProjects,
      isValidated,
    } = petition;

    const project = currentProjects[this.state.projectSelect];

    const { progress } = petition;
    // const progress = 1;
    let progressView = null;
    let userButtons = null;

    const getViewForUneditable = () =>
      (progressView = (
        <>
          {/* TODO intl, count*/}
          <ResumeBlockContainer>
            <ResumeHeader
              // text={<>{petition.products.length} Productos en Total</>}
              text={
                <FormattedPlural
                  value={petition.products.length}
                  one={
                    <FormattedMessage
                      id={'total_product'}
                      values={{ count: petition.products.length }}
                    />
                  }
                  other={
                    <FormattedMessage
                      id={'total_products'}
                      values={{ count: petition.products.length }}
                    />
                  }
                />
              }
            />
            <ResumeProducts products={petition.products} />
          </ResumeBlockContainer>
          <ResumeBlockContainer>
            {petition.projects.map((project, index) => (
              <ResumeProjectHandler
                key={project.id}
                index={index + 1}
                project={project}
                header={
                  <ResumeProjectHeader
                    index={index + 1}
                    serviceName={
                      services.find(s => s.id === project.service).name
                    }
                  />
                }
              />
            ))}
          </ResumeBlockContainer>
        </>
      ));

    const numberFromProgress = getNumberFromProgress(progress);
    switch (numberFromProgress) {
      // dirty
      case 1:
        if (projectSelect === -1) {
          progressView = (
            <div>
              {petition.id && (
                <div className="petition-number">
                  <FormattedMessage id={'petition'} /> #{petition.id}
                </div>
              )}
              <span className="line" />

              <div className="petitions-products petitions-area">
                <CardHeader
                  title={<FormattedMessage id={'petition_products'} />}
                  desc={<FormattedMessage id={'products_desc'} />}
                />
                <div className="petitions-products-table">
                  {/*<ProductsTable*/}
                  {/*  items={petition.products}*/}
                  {/*  addProductToPetition={this.addProductToPetition}*/}
                  {/*  patchProduct={this.patchProduct}*/}
                  {/*/>*/}
                  <ProductsTablePetition
                    initialValues={{
                      data: nextProduct
                        ? [...petition.products, { edit: true }]
                        : petition.products,
                    }}
                    addProductToPetition={this.addProductToPetition}
                    patchProduct={this.patchProduct}
                  />
                  {!nextProduct && (
                    <span
                      className="empty-table"
                      onClick={this.startNextProduct}
                    >
                      <AddIcon color="primary" className={classes.addIcon} />
                      <FormattedMessage id={'petition_add_product'} />
                    </span>
                  )}
                </div>
              </div>
              <div className="petitions-projects petitions-area">
                <CardHeader
                  title={<FormattedMessage id={'petition_projects'} />}
                  desc={<FormattedMessage id={'projects_desc'} />}
                />
                <div className="petitions-projects-table">
                  {/*{this.renderProjects()}*/}
                  {currentProjects &&
                    currentProjects.length > 0 &&
                    currentProjects.map((project, index) => (
                      <CustomizedExpansionPanel
                        key={project.id}
                        name={
                          <>
                            <FormattedMessage id={'petition_project'} />
                            {index + 1}
                          </>
                        }
                        onDelete={() => onDeleteProject({ id: project.id })}
                        products={petition.products}
                        services={services}
                        project={project}
                        petitionId={petition.id}
                        editable={false}
                      />
                    ))}
                  {nextProjects &&
                    nextProjects.length > 0 &&
                    nextProjects.map((project, index) => (
                      <CustomizedExpansionPanel
                        key={index}
                        name={
                          <>
                            <FormattedMessage id={'new_project'} /> {index + 1}
                          </>
                        }
                        onDelete={this.deleteProject.bind(null, index)}
                        products={petition.products}
                        services={services}
                        project={project}
                        petition={petition.id}
                        editable={true}
                      />
                    ))}
                  <span
                    className="empty-table"
                    onClick={this.onClickNextProject}
                  >
                    <AddIcon color="primary" className={classes.addIcon} />
                    <FormattedMessage id={'petition_add_project'} />
                  </span>
                </div>
              </div>
              <div>
                <Button
                  variant="contained"
                  color={'secondary'}
                  onClick={this.handleClickOpen}
                >
                  <FormattedMessage id={'petition_delete_btn'} />
                </Button>
                <Dialog
                  open={this.state.deletePetitionOpen}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <FormattedMessage id={'petition_delete_title'} />
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <FormattedMessage id={'petition_delete_desc'} />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                      <FormattedMessage id={'disagree_btn'} />
                    </Button>
                    <Button
                      onClick={() => {
                        this.handleDeletePetition();
                        this.handleClose();
                      }}
                      color="primary"
                      autoFocus
                    >
                      <FormattedMessage id={'agree_btn'} />
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          );
        } else {
          progressView = (
            <ProjectCard
              index={this.state.projectSelect}
              type={this.state.projectSelect}
              project={project}
              products={petition.products}
            />
          );
        }
        userButtons = (
          <Button
            disabled={!currentProjects || currentProjects.length === 0}
            variant="contained"
            color="primary"
            onClick={this.validatePetition}
          >
            <Save /> <FormattedMessage id={'petition_save'} />
          </Button>
        );
        break;
      // clean
      case 2:
        progressView = getViewForUneditable();
        userButtons = (
          <>
            <Button
              style={{ marginRight: '20px' }}
              variant="contained"
              color="secondary"
              onClick={this.declineResume}
            >
              <ArrowBack /> <FormattedMessage id={'back_btn'} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.acceptResume}
            >
              <Done /> <FormattedMessage id={'accept_resume_btn'} />
            </Button>
          </>
        );
        break;
      case 3:
        progressView = getViewForUneditable();
        break;
      case 4:
        progressView = (
          <>
            <Grid container>
              <Grid item xs={12} lg={8} />
              <Grid item xs={12} lg={4}>
                {petition.chat && <Chat chatId={petition.chat} inner={true} />}
              </Grid>
            </Grid>
            {getViewForUneditable()}
          </>
        );
        userButtons = (
          <>
            <Button variant="contained" disabled>
              <CreditCard /> <FormattedMessage id={'pay_btn'} />
            </Button>
          </>
        );
        break;
    }

    return (
      <section style={{ margin: '16px' }}>
        <div className="new-petition-options">
          <div className="petition-info">
            <button
              data-index={-1}
              className={projectSelect === -1 ? 'green-btn' : 'green-empty-btn'}
              onClick={() => this.onClickSelectProject(-1)}
            >
              <FormattedMessage id={'petition_information'} />
            </button>
            {progress === getProgressFromNumber(1) && this.renderProjectsTabs()}
            {progress === getProgressFromNumber(1) && (
              <Add
                onClick={() => {
                  this.setState({
                    projectSelect: -1,
                  });
                  this.onClickNextProject();
                }}
                color={'secondary'}
                fontSize={'large'}
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
          <div className="petition-validate">{userButtons}</div>
        </div>
        {progressView}
        <div className="petition-validate" style={{ textAlign: 'right' }}>
          {userButtons}
        </div>
      </section>
    );
  }
}

NewPetitionPage.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product_ID: PropTypes.string,
      product_ref: PropTypes.string,
      product_name: PropTypes.string,
      product_type: PropTypes.string,
      product_number_series: PropTypes.string,
      product_date: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => ({
  ...state.petitions,
  services: state.petition.services,
  petitions: state.petition.petitions,
  error: state.petitionHandler.error,
  isPristineRegulatoryService: isPristine('RegulatoryService')(state),
  isPristineSafetyAndEfficacyService: isPristine('SafetyAndEfficacyService')(
    state,
  ),
  isPristineOtherServices: isPristine('OtherServices')(state),
  isPristineSecurityPackService: isPristine('SecurityPackService')(state),
  isPristineConsultationService: isPristine('ConsultationService')(state),
});

export default connect(
  mapStateToProps,
  {
    onPetitionAddProduct,
    onPetitionCreateProject,
    onPatchProject,
    onPetitionValidate,
    onPatchProduct,
    submit,
    onDeletePetition,
    onDeleteProject,
    petitionCreateDraft,
    onPetitionHandlerReset,
  },
)(withStyles(styles)(NewPetitionPage));
