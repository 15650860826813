import React from 'react';
import { FormattedMessage } from 'react-intl';
import Fade from '@material-ui/core/Fade';
import final_bg from '../../../assets/images/svg/final-reg.svg';
import withRegWrapper from "../common/withRegWrapper";

const FinalRegistration = () => {
  const { username, email } = JSON.parse(localStorage.getItem('active_reg'));
  return (
    <Fade in timeout={2000}>
      <section className="final-reg">
        <div className="final-reg__bg-block">
          <div className="final-reg__greeting">
            <FormattedMessage id="auth_reg_fantastic" /> {username}!
          </div>
          <div>
            <img
              className="final-reg__final-img"
              src={final_bg}
              alt="final-reg-bg"
            />
          </div>
        </div>
        <Fade in timeout={3000}>
          <div className="final-reg__final-block">
            <div className="final-reg__final-text">
              <FormattedMessage id="auth_reg_final_first_part" />
              <b> {email} </b>
              <FormattedMessage id="auth_reg_final_socond_part" />
            </div>
            <div className="final-reg__final-text-sub">
              <FormattedMessage id="auth_reg_thanks" />
            </div>
            <div className="final-reg__final-text-sub">
              <FormattedMessage id="auth_req_welcome" />
            </div>
          </div>
        </Fade>
      </section>
    </Fade>
  );
};

export default withRegWrapper(FinalRegistration);
