import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage } from 'react-intl';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core';
import { tableCell } from '../../helpers/styles';

const PetitionsTableHead = ({ classes }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'petition_number_of_petition'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'petition_project_type'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'petition_product'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'petition_product_ref'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'petition_created_date'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'petition_project_status'} />
        </TableCell>
        <TableCell className={classes.tableCell} />
      </TableRow>
    </TableHead>
  );
};

PetitionsTableHead.propTypes = {};

export default withStyles({ tableCell })(PetitionsTableHead);
