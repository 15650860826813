import React from 'react';
import PropTypes from 'prop-types';
import withData from '../../HOC/WithData';
import Loader from '../../organisms/Loader';
import NotificationsTemplate from "../../templates/NotificationsTemplate";

const NotificationsPage = props => {
  const { data } = props;
  return (
    <>
      {!data.notifications && <Loader />}
      {data.notifications && (
        <NotificationsTemplate notifications={data.notifications} />
      )}
    </>
  );
};

NotificationsPage.propTypes = {};

export default withData(NotificationsPage, 'notifications');
