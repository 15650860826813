import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  clearDataError,
  getDataByName,
} from '../../../redux/actions/dataLoaderActions';
import Error from '../../organisms/Error';
function withData(WrappedComponent, resourceName) {
  class WithData extends Component {
    constructor(props) {
      super(props);
      this.state = {
        viewError: null,
      };
    }
    componentDidMount() {
      const { getDataByName } = this.props;
      getDataByName({ name: resourceName });
    }

    componentWillUnmount() {
      const { clearDataError } = this.props;
      clearDataError();
    }

    static getDerivedStateFromError(error) {
      return { viewError: error };
    }

    render() {
      const { data, reducerError } = this.props;
      const { viewError } = this.state;

      const error = reducerError || viewError;
      return error ? (
        <Error error={error.toString()} />
      ) : (
        <WrappedComponent data={data} />
      );
    }
  }

  const mapStateToProps = state => ({
    reducerError: state.dataLoader.error,
    data: state.dataLoader.data,
  });

  return connect(
    mapStateToProps,
    { getDataByName, clearDataError },
  )(WithData);
}
export default withData;
