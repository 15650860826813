import React, { Component } from 'react';
// import CarouselSlider from './CarouselSlider';
import './styles.scss';
import { sliderData } from '../../data/Data';
import NotSlideItem from './NotSlideItem';

class NotificationsSlider extends Component {
  constructor() {
    super();
    this.sliderWrap = React.createRef();
  }

  dotsClick = index => {
    const el = this.sliderWrap;
    el.current.scrollLeft = index * 300;
  };

  arrowClick = increase => {
    const el = this.sliderWrap;
    increase ? (el.current.scrollLeft += 300) : (el.current.scrollLeft -= 300);
  };

  render() {
    const userName = 'Peter Notmann';

    const items = [];

    for (let i = 0; i < sliderData.length; i++) {
      const item = sliderData[i];
      items.push(<NotSlideItem key={`slide-item-${i}`} {...item} />);
    }

    return (
      <div className="notifications-slider">
        <div className="header-block">
          <h5>
            cosmocare<span>pro</span>
          </h5>
          {/*<span>{`Bienvenido, ${userName} !`}</span>*/}
        </div>
        <div className="scroll-btns">
          <button onClick={() => this.arrowClick(0)}> {'<'} </button>
          <button onClick={() => this.arrowClick(1)}> {'>'} </button>
        </div>
        <div className="carousel-area" ref={this.sliderWrap}>
          {items}
          {/* <div className={'slide-item'}> */}
          {/* <div className={'slide-item-wrapper'}> */}
          {/* <h5></h5> */}
          {/* <span></span> */}
          {/* <p></p> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className={'slide-item'}> */}
          {/* <div className={'slide-item-wrapper'}> */}
          {/* 222222 */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className={'slide-item'}> */}
          {/* <div className={'slide-item-wrapper'}> */}
          {/* 3333 */}
          {/* </div> */}
          {/* </div> */}
        </div>
        <div className="scroll-dots">
          <button onClick={() => this.dotsClick(0)} />
          <button onClick={() => this.dotsClick(1)} />
          <button onClick={() => this.dotsClick(2)} />
        </div>
      </div>
    );
  }
}

export default NotificationsSlider;
