import initialState from './initialState';
import injectReducer from './injectReducer';
import {
  PETITION_HANDLER_DELETE_PROJECT_FROM_STATE,
  PETITION_HANDLER_ERROR,
  PETITION_HANDLER_PATCH_ATTACHMENT,
  PETITION_HANDLER_PATCH_PROJECT_IN_STATE,
  PETITION_HANDLER_RESET,
  PETITION_HANDLER_SET_CURRENT_PETITION,
  PETITION_PUSH_PRODUCT,
  PETITION_PUSH_PROJECT,
  PETITION_STATE_PATCH_PRODUCT,
} from '../types';

const handlers = {
  [PETITION_HANDLER_SET_CURRENT_PETITION]: (state, { payload }) => ({
    ...state,
    petition: payload,
  }),
  [PETITION_PUSH_PRODUCT]: (state, { payload }) => ({
    ...state,
    petition: {
      ...state.petition,
      products: [...state.petition.products, payload],
    },
  }),
  [PETITION_PUSH_PROJECT]: (state, { payload }) => ({
    ...state,
    petition: {
      ...state.petition,
      projects: [...state.petition.projects, payload],
    },
  }),
  [PETITION_HANDLER_PATCH_PROJECT_IN_STATE]: (state, { payload }) => {
    if (!state.petition) {
      return state;
    }
    const index = state.petition.projects.findIndex(p => p.id === payload.id);
    let result = [...state.petition.projects];
    result[index] = payload;
    return {
      ...state,
      petition: {
        ...state.petition,
        projects: result,
      },
    };
  },
  [PETITION_HANDLER_PATCH_ATTACHMENT]: (state, { payload }) => {
    if (!state.petition) {
      return state;
    }
    const index = state.petition.projects.findIndex(p => p.id === payload.id);
    let result = [...state.petition.projects];
    result[index] = { ...result[index], attachments: payload.attachments };
    return {
      ...state,
      petition: {
        ...state.petition,
        projects: result,
      },
    };
  },
  [PETITION_HANDLER_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [PETITION_HANDLER_RESET]: (state, { payload }) => ({
    ...initialState.petitionHandlerReducer,
  }),
  [PETITION_STATE_PATCH_PRODUCT]: (state, { payload }) => {
    const index = state.petition.products.findIndex(p => p.id === payload.id);
    let result = [...state.petition.products];
    result[index] = payload;
    return {
      ...state,
      petition: {
        ...state.petition,
        products: result,
      },
    };
  },
  [PETITION_HANDLER_DELETE_PROJECT_FROM_STATE]: (state, { payload }) => {
    let nextProjects = state.petition.projects.filter(p => p.id !== payload.id);
    return {
      ...state,
      petition: {
        ...state.petition,
        projects: nextProjects,
      },
    };
  },
};
export default injectReducer(initialState.petitionHandlerReducer, handlers);
