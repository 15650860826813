import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuestionnaireTemplate from '../../templates/QuestionnaireTemplate';
import { connect } from 'react-redux';
import { getQuestionnaireById } from '../../../redux/actions/questionnaireActions';

class QuestionnairePage extends Component {
  componentDidMount() {
    const {
      match: { params },
      getQuestionnaireById,
    } = this.props;

    getQuestionnaireById({ id: parseInt(params.questionnaire) });
  }

  render() {
    const {
      questionnaires,
      match: { params },
    } = this.props;
    console.log('params', params);
    const project = parseInt(params.project);
    const questionnaire = parseInt(params.questionnaire);
    return (
      <div>
        {questionnaires[questionnaire] && (
          <QuestionnaireTemplate
            questionnaire={questionnaires[questionnaire]}
            project={project}
          />
        )}
      </div>
    );
  }
}

QuestionnairePage.propTypes = {};

const mapStateToProps = state => ({
  questionnaires: state.questionnaires,
});

export default connect(
  mapStateToProps,
  { getQuestionnaireById },
)(QuestionnairePage);
