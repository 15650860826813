import React, {Component} from "react";
import './styles.scss';

class CircularProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const sqSize = this.props.sqSize,
              radius = (this.props.sqSize - this.props.strokeWidth) / 2,
              viewBox = `0 0 ${sqSize} ${sqSize}`,
              dashArray = radius * Math.PI * 2,
              dashOffset = dashArray - dashArray * this.props.percentage / 100;

        return (
            <svg
                width={this.props.sqSize}
                height={this.props.sqSize}
                viewBox={viewBox}>
                <circle
                    className="circle-background"
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${this.props.strokeWidth}px`}
                    stroke={'#F2F4F7'}/>
                <circle
                    className="circle-progress"
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${this.props.strokeWidth}px`}
                    transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
                    stroke={this.props.color}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }} />
                <text
                    className="circle-text"
                    fill={'#444444'}
                    x="50%"
                    y="50%"
                    dy=".3em"
                    textAnchor="middle">
                    {this.props.counter}
                </text>
            </svg>
        );
    }
}

export default CircularProgressBar;