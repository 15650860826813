import initialState from './initialState';
import injectReducer from './injectReducer';

import { DASHBOARD_ONMOUNT, DASHBOARD_ERROR } from '../types';

const handlers = {
  [DASHBOARD_ONMOUNT]: (state, { payload }) => ({
    ...state,
    lang: payload
  }),
  [DASHBOARD_ERROR]: (state, { error, message }) => ({
    ...state,
    alert: {
      open: true,
      message,
      error
    }
  })
};

export default injectReducer(initialState.dashboardReducer, handlers);
