import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FormattedMessage } from 'react-intl';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core';
import { tableCell } from '../../helpers/styles';

const ProjectsTableHead = ({ classes }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'products_id'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'projects_service_type'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'projects_type'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'projects_validate_date'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'projects_samples_state'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'projects_answers_state'} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <FormattedMessage id={'projects_study_state'} />
        </TableCell>
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
      </TableRow>
    </TableHead>
  );
};

ProjectsTableHead.propTypes = {};

export default withStyles({ tableCell })(ProjectsTableHead);
