import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './styles';
import { Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const ResumeDocuments = ({ lang, classes, docs }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <span className={classes.grayColor}>
          <FormattedMessage id={'petition_language'} />:{' '}
        </span>
        <span className={classNames(classes.lang)}>{lang}</span>
      </Grid>
      <Grid item xs={12} md={8}>
        <span className={classNames(classes.doc)}>
          <FormattedMessage id={'petition_study_docs'} />
        </span>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {docs &&
            docs.length > 0 &&
            docs.map((doc, index) => (
              <a
                key={index}
                className={classNames(classes.doc)}
                href={`${process.env.REACT_APP_API_URL}${doc.url}`}
              >
                {doc.name}
              </a>
            ))}
        </div>
      </Grid>
    </Grid>
  );
};

ResumeDocuments.propTypes = {};

export default withStyles(styles)(ResumeDocuments);
