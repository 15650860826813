import initialState from './initialState';
import injectReducer from './injectReducer';
import { SET_FILE_UPLOADER_VALUE } from '../actions/networkActions';

const handlers = {
  [SET_FILE_UPLOADER_VALUE]: (state, { payload }) => ({
    ...state,
    project: {
      ...state.project,
      [payload.project]: { documentLoading: payload.documentLoading },
    },
    chat: {
      ...state.chat,
      [payload.chat]: { documentLoading: payload.documentLoading },
    }
  }),
};

export default injectReducer({ project: {}, chat: {} }, handlers);
