import React, {Component} from 'react';
import ReactDataGrid from 'react-data-grid';
import './styles.scss';

class ProjectsTable extends Component{

    render(){

        const columns = [
                { key: 'id', name: 'ID' },
                { key: 'product_name', name: 'Producto' },
                { key: 'ref_formula', name: 'Ref. Formula' },
                { key: 'project_number', name: '№ proyecto' },
                { key: 'service_type', name: 'Tipo de servicio' },
                { key: 'project_type', name: 'Tipo de proyecto' },
                { key: 'validation_date', name: 'Fecha Validacion' },
                { key: 'sample_status', name: 'Estado de las muestras' },
                { key: 'quest_status', name: 'Estado del cuestinario' },
                { key: 'study_status', name: 'Estado del estudio' },
                { key: 'check_studio', name: 'Estado del estudio' }

            ];

        const rows = [];

        for (let i = 0; i < 10; i++) {
            rows.push(
                {
                    id: 0,
                    product_name: 'gel douche',
                    ref_formula: '11126-2',
                    project_number: 'cc18002',
                    services_type: 'Regulatorio',
                    project_type: 'Val. eti.',
                    validation_date: '12.03.2018',
                    sample_status: 'Recibida',
                    quest_status: 'Valido',
                    study_status: 'Finalizado',
                    check_studio: 'ver estudio'
                }
            );
        }


        return(
            <div className={'projects-table'}>
                <ReactDataGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={150}
                />
            </div>
        )
    }
}

export default ProjectsTable;